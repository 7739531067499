import {db} from '../db'

export async function createJourney(journey): Promise<boolean> {
  try {
    await db.journeys.add(journey)

    return true
  } catch (error) {
    return false
  }
}

export async function updateJourney(journey): Promise<boolean> {
  try {
    await db.journeys.update(journey.id, journey)

    return true
  } catch (error) {
    return false
  }
}

export async function deleteJourney(id): Promise<boolean> {
  try {
    await db.journeys.delete(id)

    return true
  } catch (error) {
    return false
  }
}