import {useState} from 'react'
import {debounce} from 'lodash'

// Manages a searchTerm and updating it with debouncing
export function useSearchTerm() {
    const [searchTerm, setSearchTerm] = useState('')

    const updateSearchTerm = debounce((term) => {
        setSearchTerm(term);
    }, 500)

    return [searchTerm, updateSearchTerm, setSearchTerm]
}