import React, {Component} from 'react';

import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Select from 'react-select';
import {orderSelectOptions} from "../../Structures/Order";

class OrderField extends Component {
    constructor(props) {
        super(props);

        this.options = orderSelectOptions;
    }

    handleChange = value => {
        // Calls setFieldValue and manually updates values.orders
        this.props.onChange(this.props.id, value);
    };

    render() {
        return (
            <React.Fragment>
                <InputLabel className="selectLabel" htmlFor={this.props.id}>{this.props.label}</InputLabel>
                <Select
                    id={this.props.id}
                    placeholder="Select..."
                    options={this.options}
                    menuPosition={'fixed'}
                    isMulti
                    isSearchable
                    onChange={this.handleChange}
                    value={this.props.value}
                />
                <FormHelperText>
                    {this.props.description}
                </FormHelperText>
            </React.Fragment>
        );
    }
}

export default OrderField;