// Check if two Date objects have the same day
//
// @param {Date} Date object
// @param {Date} Date object
// @returns {bool} If the dates are on the same day
export function sameDay(date1, date2) {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
}

// Output nice date string
//
// @param {Date} Date object
// @returns {string}
export function dateFormat(date) {
    let options = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric"
    };

    return date.toLocaleDateString(options);
}

export function dateDistanceFormat(startDate, endDate) {
    const yearA = startDate.getFullYear()
    const yearB = endDate.getFullYear()
    const monthA = startDate.getMonth()
    const monthB = endDate.getMonth()
    const dayA = startDate.getDay()
    const dayB = endDate.getDay()

    const getDiff = (a, b) => {
        if (a > b) return a - b
        return b - a
    }

    const yearDiff = getDiff(yearA, yearB)
    const monthDiff = getDiff(monthA, monthB)
    const dayDiff = getDiff(dayA, dayB)

    const pluralizedString = (number, string) => {
        const constructedString = `${number} ${string}`
        if (number > 1) return `${constructedString}s`
        return constructedString
    }

    let formattedStrings = []

    if (yearDiff > 0) formattedStrings.push(pluralizedString(yearDiff, 'year'))
    if (monthDiff > 0) formattedStrings.push(pluralizedString(monthDiff, 'month'))
    if (dayDiff > 0) formattedStrings.push(pluralizedString(dayDiff, 'day'))

    return formattedStrings.join(', ')
}