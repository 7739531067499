import React from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Logo from '../Logo';
import TopBarSearchField from './TopBarSearchField';

interface TopBarProps {
    title: string
    className: string
    menuButton?: React.ReactNode
    searchFunction?: (value: string) => void
    disableGutters?: boolean
}

function TopBar({title, className, menuButton, searchFunction, disableGutters}: TopBarProps) {
    return (
        <AppBar className={className} position="static" color="secondary">
            <Toolbar variant="dense" disableGutters={disableGutters}>
                {menuButton}
                <Typography variant="body2" color="inherit" noWrap>
                    {title}
                </Typography>
                <Logo/>
                {searchFunction &&
                    <TopBarSearchField updateSearchTerm={searchFunction}/>
                }
            </Toolbar>
        </AppBar>
    )
}

export default TopBar;