import React, {useContext, useState, useEffect, useMemo} from 'react';
import ConfigContext from "../Contexts/Config.context";
import Tooltip from '@mui/material/Tooltip';

function Presence() {
    const [presence, setPresence] = useState(1);
    const [status, setStatus] = useState('pending');

    const readout = () => {
        switch(status) {
            case 'no_connection':
                return 'Presence offline';

            case 'pending':
                return 'Presence loading';

            case 'connected':
                return presence;

        }
    };

    // Every time the presence is changed, do blip
    useEffect(() => {
        const PresenceBlips = document.getElementById('PresenceBlips');
        PresenceBlips.insertAdjacentHTML('afterbegin', '<div class="blip"></div>');

        // After animation, remove last blip if present
        setTimeout(() => {
            PresenceBlips.removeChild(PresenceBlips.lastChild);
        }, 800); // Animation duration
    }, [presence]);

    const classes = [
        'presence',
        status
    ];

    // WebSockets ---------
    const [presenceId, setPresenceId] = useState(null);
    const [connection, setConnection] = useState(null);

    // WebSocket connection function
    const webSocketConnect = () => {
        // If called while connection exists, close existing to prevent doubles
        if (connection) {
            connection.close();
            setConnection(null);
        }

        const ws = new WebSocket('wss://noto-presence-app-xh9tq.ondigitalocean.app'); // For local dev use - ws://localhost:8080
        // const ws = new WebSocket('wss://presence.noto.ooo'); // For local dev use - ws://localhost:8080

        ws.onopen = () => {
            const data = {method: 'startbeat'};
            ws.send(JSON.stringify(data))
        };

        // Failed connection
        if (ws.readyState === 0) {
            setStatus('no_connection');
        }

        ws.onmessage = message => {
            const msg = JSON.parse(message.data);

            // ---------------------------------
            // Presence server message handling
            // ---------------------------------
            switch (msg.type) {
                case 'hb_id':
                    setPresenceId(msg.id);
                    setStatus('connected');
                    break;

                case 'hb_presence':
                    setPresence(msg.count);
                    break;

                default:
                    break;
            }
        };

        ws.onclose = () => {
            // When connection is closed clear the connection state, this will trigger polling for the connection
            setConnection(null);
            setStatus('no_connection');
        };

        // Store connection in state for detecting changes
        setConnection(ws);

        return () => {
            ws.close();
        }
    };

    useEffect(webSocketConnect, []);

    // While no connection, try again every minute
    useEffect(() => {
        const polling = setInterval(() => {
            if (navigator.onLine) {
                if (!connection) {
                    webSocketConnect();
                }
            } else {
                if (connection) {
                    setConnection(null);
                    setStatus('no_connection');
                }
            }
        }, 60000); // One minute

        return () => {
            clearInterval(polling);
        }
    }, [connection]);
    // end WebSockets ------

    return (
        <Tooltip
            title={'Presence'}
            placement="bottom"
            enterDelay={1500}
            leaveDelay={200}
            enterNextDelay={6000}
        >
            <div className={classes.join(' ')}>
                <>
                    <div className='number'>{readout()}</div>
                    <div id='PresenceBlips'></div>
                </>
            </div>
        </Tooltip>
    )
}

export default function PresenceHolder() {
    const {config} = useContext(ConfigContext);

    // Stealth mode disables this feature entirely
    if (config.stealthMode) {
        return null;
    }

    return <Presence/>
}