import React, {createContext, useState, useEffect, useContext} from 'react';
import {getCards as getCardData} from "../Structures/Card";
import {loadCollections} from "../Structures/Collection";
import {tagData} from "../Structures/Tag";
import ConfigContext from "./Config.context";
import {usePersistentStorage} from "../hooks/usePersistentStorage";

// App Context
//
// This Context is intended to provide global app data and functions to Components
// deeper in the applications without 'prop drilling'
//
// React Docs for Context: https://reactjs.org/docs/context.html, https://reactjs.org/docs/context.html#classcontexttype
const AppContext = createContext();

const AppProvider = ({children}) => {
    const {config} = useContext(ConfigContext);

    const [screen, setScreen] = useState('cards');
    const [cards, setCards] = useState([]);
    const [tags, setTags] = useState([]);
    const [collections, setCollections] = useState([]); // Collections state was hoisted up to App because it became related to tags (a tag can exist on a Collection and not on a Card)
    const [initialLoadEmpty, setInitialLoadEmpty] = useState(false); // This determines if the introduction is shown
    const [focusCard, setFocusCard] = useState(null); // The Card in focus
    const [linkedCard, setLinkedCard] = useState({
        'card': null,
        'cleared': false
    }); // The current active Card referenced by CardLink

    const {attemptPersistentStorageGrant} = usePersistentStorage();

    // Provided as prop to allow setting screen deeper in application
    const updateScreen = (s) => {
        window.scrollTo(0, 0);
        setScreen(s);
    };

    // @param card {Card || null}
    const updateFocusCard = (card) => {
        setFocusCard(card);
        // Increment card wear with each focus if enabled
        if (card && config.cardWear) {
            card.wear += 1;
            card.save();
        }
    };

    const cardByID = (id) => {
        try {
            return cards.filter(x => x.ID === id)[0];
        } catch {
            throw new Error(`Card not found by id:${id}`)
        }
    };

    const updateLinkedCard = (card, cleared = false) => {
        // Use this object approach to carry more information
        setLinkedCard({
            'card': card,
            'cleared': cleared
        });
    };

    // Load Cards into state
    //
    // @returns {Promise -> Array} Promise of Array of cards
    const loadCards = () => {
        // Get cards using imported method from Structures/Card.js
        // Get collections using imported method from Structures/Collection.js
        return Promise.all([
            getCardData(),
            loadCollections()
        ]).then((values) => {
            let loadedCards = values[0];
            let loadedCollections = values[1];

            // Update state
            setCards(loadedCards);
            setCollections(loadedCollections);

            // Load Tags
            loadTags(loadedCards, loadedCollections);

            return loadedCards;

        }).catch(function(err) {
            console.log(err);
            return [];
        });
    };

    // Load Tags into state
    //
    // @param loadedCards {Array} of Cards
    // @param loadedCollections {Array} of Collections
    //
    // @returns {Promise -> Array} Promise of Array of cards
    const loadTags = (loadedCards, loadedCollections) => {
        // Get tags using imported method from Structures/Tag.js
        return tagData(loadedCards, loadedCollections).then((loadedTags) => {
            // Update state
            setTags(loadedTags);

            return loadedTags;

        }).catch(function(err) {
            console.log(err);
            return [];
        });
    };

    useEffect(() => {
        // Load App Cards (and Tags)
        loadCards().then((loadedCards) => {
            // Set initialLoadEmpty, this only happens once
            setInitialLoadEmpty(loadedCards.length === 0);
        });
    }, []);

    return (
        <AppContext.Provider value={{
            screen,
            updateScreen,
            cards,
            tags,
            collections,
            setCollections,
            focusCard,
            linkedCard,
            initialLoadEmpty,
            loadCards,
            updateFocusCard,
            updateLinkedCard,
            cardByID,
            attemptPersistentStorageGrant
        }}>
            {children}
        </AppContext.Provider>
    );
};

export { AppProvider };
export default AppContext;