import React from 'react';
import MarkdownView from 'react-showdown';
import CardLink from '../Components/MarkdownExtensions/CardLink'
import Badge from '../Components/MarkdownExtensions/Badge'

// Markdown interpreter config
// Markdown (ShowdownJS) extensions
const extensions = [
    {
        // !!! -> warning
        type: 'lang',
        regex: /(?:\[:!!!])/g,
        replace: '<span class="shortcut-warning fas fa-exclamation-triangle"></span>'
    },
    {
        // !! -> important/prio
        type: 'lang',
        regex: /(?:\[:!!])/g,
        replace: '<span class="shortcut-important fas fa-exclamation-circle"></span>'
    },
    {
        // $$ -> cashed(done/check)
        type: 'lang',
        regex: /(?:\[:¨D¨D])/g, // Showdown JS escapes $$ to ¨D
        replace: '<span class="shortcut-cashed fas fa-check-circle"></span>'
    },
    {
        // XX -> canceled(cross)
        type: 'lang',
        regex: /(?:\[:XX])/g,
        replace: '<span class="shortcut-cross fas fa-times-circle"></span>'
    },
    {
        // @@ -> current/at
        type: 'lang',
        regex: /(?:\[:@@])/g,
        replace: '<span class="shortcut-current fas fa-bookmark"></span>'
    },
    {
        // NN -> forward/delayed/postponed
        type: 'lang',
        regex: /(?:\[:NN])/g,
        replace: '<span class="shortcut-forward fas fa-arrow-alt-circle-right"></span>'
    },
    {
        // BB -> backward
        type: 'lang',
        regex: /(?:\[:BB])/g,
        replace: '<span class="shortcut-backward fas fa-arrow-alt-circle-left"></span>'
    },
    {
        // Font Awesome
        type: 'lang',
        regex: /(?:\[:([\d\w][^\s:]*?)])/g,
        replace: '<span class="fas fa-$1"></span>'
    },
    {
        // Font Awesome - Colour
        type: 'lang',
        regex: /(?:\[:([\d\w][^\s:]*?):([#\d\w][^\s:]*?)])/g,
        replace: '<span class="fas fa-$1" style="color: $2;"></span>'
    },
    {
        // Font Awesome - Colour - Animation
        // (https://fontawesome.com/docs/web/style/animate)
        type: 'lang',
        regex: /(?:\[:([\d\w][^\s:]*?):([#\d\w][^\s:]*?):([#\d\w][^\s:]*?)])/g,
        replace: '<span class="fas fa-$1 fa-$3" style="color: $2;"></span>'
    }
];
const options = {
    strikethrough: true,
    tables: true,
    tasklists: true,
    simpleLineBreaks: true,
    openLinksInNewWindow: true,
    emoji: true
};
// Custom React Components parsed by Markdown Interpreter
const components = {
    CardLink,
    Badge
};

export default function MarkdownInterpreter({markdown}) {
    return <MarkdownView
        markdown={markdown}
        options={options}
        components={components}
        extensions={extensions}
    />
};