// Register listener to catch PWA install prompt supported by some browsers
// then store the event in window.deferredPrompt so it can be called later
// by user interaction (eg: an install button)
export function catchPwaInstall() {
    // Listen for PWA install prompt (only supported by some browsers: https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent)
    window.addEventListener('beforeinstallprompt', (event) => {
        // Stash the event so it can be triggered later.
        window.deferredPrompt = event;
    });
}

// Attempt to trigger stored PWA install event
//
// @returns {bool} Event was triggered successfully (ie: it is supported and was stored earlier)
export function triggerPwaInstall() {
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
        // The deferred prompt isn't available.
        return false;
    }
    // Show the install prompt.
    promptEvent.prompt();
    return true;
}