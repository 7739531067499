import React, { useContext } from 'react'
import Grid from '@mui/material/Grid'
import AddPromptCard from '../../AddPromptCard'
import {Deck} from "../../Deck"
import CollectionFormContext from "../../Forms/Contexts/CollectionForm.context"
import { EmptyStatePrompt } from '../../Universal/EmptyStatePrompt'
import {Folder} from '@mui/icons-material'
import { EmptyMessage } from '../../../Helpers/tools'

export function Collections ({saveCollection, collections, tags, searchTerm, collectCards, cards, open}) {
    const {popCollectionFormDialog} = useContext(CollectionFormContext)

    const newCollectionPrompt = () => {popCollectionFormDialog(saveCollection, null, tags)}

    const noCollections = collections.length === 0
    const emptyStatePrompt = noCollections && !searchTerm
    const searchEmptyState = noCollections && searchTerm

    if (emptyStatePrompt) return <EmptyStatePrompt
        icon={<Folder />}
        message="Let's make a Collection!"
        onClick={newCollectionPrompt}
    />

    if (searchEmptyState) {
        return <EmptyMessage />
    }

    return (
        <Grid container
              className="cardCollections"
              direction="row"
              justifyContent="space-evenly"
              alignItems="flex-start"
        >
            {collections.filter(collection => collection.display)
            .map(function(collection, index) {
                const collectionCards = collectCards(cards, collection)

                return (
                    <Grid key={index} item>
                        <Deck
                            cards={collectionCards}
                            onClick={() => {open(collection.ID)}}
                            label={collection.title}
                        />
                    </Grid>
                )
            })}
            <AddPromptCard function={newCollectionPrompt}/>
        </Grid>
    )
}