import React from 'react';

import Grid from '@mui/material/Grid';

import StyleIcon from '@mui/icons-material/Style';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import SecurityIcon from '@mui/icons-material/Security';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import FolderIcon from '@mui/icons-material/Folder';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SearchIcon from '@mui/icons-material/Search';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ArchiveIcon from '@mui/icons-material/Archive';
import FlipIcon from '@mui/icons-material/Flip';
import LinkIcon from '@mui/icons-material/Link';
import EmojiEmotions from '@mui/icons-material/EmojiEmotions';
import GradientIcon from '@mui/icons-material/Gradient';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';

export default function IntroductionContent() {
    return (
        <>
            <div className={"introLogo"}/>

            <p><strong>Feel</strong> your notes!</p>

            <h2>Noto?</h2>
            <p>A place to write things down, with feel.</p>
            <p>Versatile features are open to your interpretation and style of use.</p>

            <h2>Philosophy</h2>
            <p>Noto's aim is to be a writing tool that combines the efficiency of digital tools with the tactile feel and heart we appreciate in physical things.</p>

            <p>Cards are the key.</p>

            <p>More than a book or a document, cards are held, flipped, moved and arranged in a way unique to them.</p>
            <p>With Cards as the core concept, Noto gives your notes a depth of realness rare in the digital world.</p>

            <h2>What makes Noto different?</h2>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <StyleIcon style={{transform: "rotate(180deg)"}} className={"featureIcon"}/>
                    <p className={"featureTitle"}><strong>Cards</strong></p>
                    <p className={"featureContent"}>Noto's core concept is its Cards. With all the benefits you'd expect from real life ones.</p>
                </Grid>
                <Grid item xs={12} md={6}>
                    <AccessibilityNewIcon className={"featureIcon"}/>
                    <p className={"featureTitle"}><strong>Accessible</strong></p>
                    <p className={"featureContent"}>No sign up required, get straight into it; no questions asked.</p>
                </Grid>
                <Grid item xs={12} md={6}>
                    <WifiOffIcon className={"featureIcon"}/>
                    <p className={"featureTitle"}><strong>Offline</strong></p>
                    <p className={"featureContent"}>No internet connection required. Install Noto to your device and use it just like an app from your home screen or desktop (see <i>Settings</i> for info).</p>
                </Grid>
                <Grid item xs={12} md={6}>
                    <SecurityIcon className={"featureIcon"}/>
                    <p className={"featureTitle"}><strong>Secure</strong></p>
                    <p className={"featureContent"}>Anything you save is stored on your device, nothing gets sent to the internet. You can export/import your data for backup or transfer.</p>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MoneyOffIcon className={"featureIcon"}/>
                    <p className={"featureTitle"}><strong>Free</strong></p>
                    <p className={"featureContent"}>Enjoy Noto for free!</p>
                </Grid>
            </Grid>

            <h2>Features</h2>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <StyleIcon style={{transform: "rotate(180deg)"}} className={"featureIcon"}/>
                    <p className={"featureTitle"}><strong>Cards</strong></p>
                    <p className={"featureContent"}>The core of Noto, write on Cards with support for Emojis, Icons and Markdown (see <i>Settings</i> for info).</p>
                </Grid>
                <Grid item xs={12} md={6}>
                    <LocalOfferIcon className={"featureIcon"}/>
                    <p className={"featureTitle"}><strong>Tags</strong></p>
                    <p className={"featureContent"}>Add coloured tags to Cards to categorise them.</p>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FolderIcon className={"featureIcon"}/>
                    <p className={"featureTitle"}><strong>Collections</strong></p>
                    <p className={"featureContent"}>Collect your Cards into decks to separate and organise them. Filter by tags and order by date, merit or last edited.</p>
                </Grid>
                <Grid item xs={12} md={6}>
                    <DashboardIcon className={"featureIcon"}/>
                    <p className={"featureTitle"}><strong>Boards</strong></p>
                    <p className={"featureContent"}>Arrange Cards in columns to visualise, arrange and plan.</p>
                </Grid>
                <Grid item xs={12} md={6}>
                    <SearchIcon className={"featureIcon"}/>
                    <p className={"featureTitle"}><strong>Search</strong></p>
                    <p className={"featureContent"}>Find your Cards, Collections and Boards.</p>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ThumbUpIcon className={"featureIcon"}/>
                    <p className={"featureTitle"}><strong>Merit</strong></p>
                    <p className={"featureContent"}>Add merit points to Cards to distinguish them from the rest.</p>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ArchiveIcon className={"featureIcon"}/>
                    <p className={"featureTitle"}><strong>Retire</strong></p>
                    <p className={"featureContent"}>Mark Cards as 'done' or 'old' so you can tell at a glance.</p>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FlipIcon className={"featureIcon"}/>
                    <p className={"featureTitle"}><strong>Flip</strong></p>
                    <p className={"featureContent"}>Hide Cards from prying eyes or make them less distracting so you can focus on others.</p>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={"backsDemo"}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <p className={"featureTitle"}><strong>Card Backs</strong></p>
                    <p className={"featureContent"}>Your Cards are special, dress them up!</p>
                </Grid>
                <Grid item xs={12} md={6}>
                    <LinkIcon className={"featureIcon"}/>
                    <p className={"featureTitle"}><strong>Link</strong></p>
                    <p className={"featureContent"}>Reference other Cards and view them side-by-side.</p>
                </Grid>
                <Grid item xs={12} md={6}>
                    <EmojiEmotions className={"featureIcon"}/>
                    <p className={"featureTitle"}><strong>Icons</strong></p>
                    <p className={"featureContent"}>Stamp some fun on your Cards!</p>
                </Grid>
                <Grid item xs={12} md={6}>
                    <GradientIcon className={"featureIcon"}/>
                    <p className={"featureTitle"}><strong>Wear</strong></p>
                    <p className={"featureContent"}>Handling cards gradually accumulates unique patterns of wear.</p>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FileCopyIcon className={"featureIcon"}/>
                    <p className={"featureTitle"}><strong>Templates</strong></p>
                    <p className={"featureContent"}>Reuse Cards by labelling them as Templates.</p>
                </Grid>
                <Grid item xs={12} md={6}>
                    <EditAttributesIcon className={"featureIcon"}/>
                    <p className={"featureTitle"}><strong>Badges</strong></p>
                    <p className={"featureContent"}>Highlight achievements or special info on your Cards.</p>
                </Grid>
            </Grid>

            <h3>Go ahead, make your first Card!</h3>

            <p><small>(Click the logo any time to see this dialog again)</small></p>
        </>
    )
}