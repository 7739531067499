import React, {Component} from 'react';

import Dialog from '@mui/material/Dialog';
import Tilt from 'react-parallax-tilt';
import AppContext from "../Contexts/App.context.js";
import Card from "./Card";

// This specific dialog is just listening for a context change to pop
export default class CardFocusDialog extends Component {
    // Register AppContext (accessible as this.context)
    static contextType = AppContext;

    // Close dialog box
    handleClose = () => {
        this.context.updateFocusCard(null);
        this.context.updateLinkedCard(null);
    };

    render() {
        const dualViewTrue = this.context.focusCard && (this.context.linkedCard.card && !this.context.linkedCard.cleared) ? 'DualView' : null;
        const paperClasses = [
            'CardDialog',
            dualViewTrue
        ];

        return (
            <Dialog
                className='CardDialogRoot'
                open={!!this.context.focusCard}
                onClose={this.handleClose}
                classes={{
                    'paper': paperClasses.join(' ')
                }}
                disablePortal
            >
                <Tilt
                    className="Tilt FocusCard"
                    tiltMaxAngleX={10}
                    tiltMaxAngleY={10}
                    scale={1.05}
                    perspective={1000}
                    transitionSpeed={1000}
                >
                    {this.context.focusCard &&
                        <Card
                            key={this.context.focusCard.ID}
                            card={this.context.focusCard}
                            tags={this.context.tags}
                            grid={true}
                            inDialog={true}
                        />
                    }
                </Tilt>
                <div className={'LinkedCardAnimationWrapper'}>
                    {this.context.linkedCard.card &&
                        <Tilt
                            className="Tilt LinkedCard"
                            tiltMaxAngleX={10}
                            tiltMaxAngleY={10}
                            scale={1.05}
                            perspective={1000}
                            transitionSpeed={1000}
                        >
                            <Card
                                key={this.context.linkedCard.card.ID}
                                card={this.context.linkedCard.card}
                                tags={this.context.tags}
                                grid={true}
                                inDialog={true}
                            />
                        </Tilt>
                    }
                </div>
            </Dialog>
        );
    }
}