import React from 'react';

export default function Badge({icon, color, animation, content, dark}) {
    const classes = [
        'Badge',
        dark ? 'dark' : ''
    ];

    return <span className={classes.join(' ')} style={{borderColor: color}}>
        { color &&
            <span className={'BadgeHighlight'} style={{backgroundColor: color}}/>
        }
        { icon &&
            <span
            className={`BadgeIcon fas fa-${icon} fa-${animation}`}
            style={{color: color}}
            />
        }
        <span className="badgeContent">{ content }</span>
    </span>;
}