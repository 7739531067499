import React, {useContext} from 'react';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import * as Yup from "yup";
import {withFormik} from "formik/dist/index";

import {notify} from "../Notice";
import {Board} from "../../Structures/Board";
import BoardFormContext from "./Contexts/BoardForm.context";

function BoardFormDialog() {
    const {
        open,
        closeDialogue,
        board,
        onSuccess
    } = useContext(BoardFormContext);

    // Close dialog box
    const handleClose = () => {
        closeDialogue()
    };

    const title = board ? 'Edit Board' : 'New Board';

    return (
        <Dialog
            className="BoardDialog DialogOverride"
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {/*Send closeDialog prop to allow dialog state to be updated from deeper*/}
                <BoardForm
                    closeDialog={handleClose}
                    onSubmit={onSuccess}
                    board={board}
                />
            </DialogContent>
        </Dialog>
    );
}

// Formik HTML structure
const BoardFormHtml = props => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        isSubmitting,
    } = props;
    let buttonText = props.board ? 'Update' : 'Create';
    return (
        <form onSubmit={handleSubmit}>
            <FormControl className="fieldContainer" fullWidth error={errors.title && touched.title && true}>
                <InputLabel variant="standard" htmlFor="content">Label</InputLabel>
                <Input
                    id="title"
                    type="text"
                    value={values.title}
                    placeholder="Type something..."
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                />
                <FormHelperText>
                    {errors.title && touched.title ? errors.title : 'Label shown on board'}
                </FormHelperText>
            </FormControl>

            <DialogActions>
                <Button disabled={isSubmitting} onClick={props.closeDialog} color='inherit'>
                    Cancel
                </Button>
                <Button type="submit" color="primary" variant="outlined" disabled={isSubmitting || !isValid}>
                    {buttonText}
                </Button>
            </DialogActions>
        </form>
    );
};

// Use Formik on some pre-defined Formik HTML
const BoardFormHandling = withFormik({
    // Initial form values (handles field population when updating Board)
    mapPropsToValues: props => ({
        title: props.board ? props.board.title : ''
    }),
    validationSchema: Yup.object().shape({
        title: Yup.string()
            .required('Label is required!')
    }),
    handleSubmit: (values, formikBag) => {
        // Board is being updated if exists, else false
        let update = !!formikBag.props.board;

        // Supplied board or new one
        const board = formikBag.props.board || new Board(values.title);

        // Update data
        board.title = values.title;

        // Execute passed through onSuccess function
        formikBag.props.onSubmit(board).then(() => { // Maps to saveBoards()
            formikBag.resetForm();
            formikBag.setSubmitting(false);

            // Close the form using supplied function
            formikBag.props.closeDialog(); // Maps to handleClose() in NoteDialog

            notify(update ? 'Board updated' : 'Board created');

        }).catch(function (err) {
            console.log(err);
        });
    },
    displayName: 'BoardForm'
});

// Use Formik on some pre-defined Formik HTML
const BoardForm = BoardFormHandling(BoardFormHtml);

export default BoardFormDialog;