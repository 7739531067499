import React, {Component} from 'react';

import Snackbar from '@mui/material/Snackbar';
import { withSnackbar } from 'notistack';

// Prepare for notify function
// (function set in Notice class, intended to be used in wide scope)
let doNotice;

class Notice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            message: '',
            status: '',
        };
    }

    // After component mounted
    componentDidMount() {
        // Define notify as the notify function
        doNotice = this.notify;
    }

    // Show a notice
    // @param message {String} What the notice says
    notify = (message, status) => {
        this.props.enqueueSnackbar(message);
    };

    render() {
        return (
            <Snackbar
                className="notice"
                message={<span>{this.state.message}</span>}
                autoHideDuration={2500}
            />
        )
    }
}

// Notify user
//
// @param message {string} Message to show on notice
// @param status {NOT YET IMPLEMENTED}
export function notify(message, status) {
    doNotice(message, status);
}

export default withSnackbar(Notice);