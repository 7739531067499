import React, {useContext} from 'react';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import * as Yup from "yup";
import {withFormik} from "formik/dist/index";

import {notify} from "../Notice";
import {Column} from "../../Structures/Column";
import ColumnFormContext from "./Contexts/ColumnForm.context";

function ColumnFormDialog() {
    const {
        open,
        closeDialogue,
        column,
        board,
        onSuccess
    } = useContext(ColumnFormContext);

    // Close dialog box
    const handleClose = () => {
        closeDialogue()
    };

    let title = column ? 'Edit Column' : 'New Column';

    return (
        <Dialog
            className="ColumnDialog DialogOverride"
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {/*Send closeDialog prop to allow dialog state to be updated from deeper*/}
                <ColumnForm
                    closeDialog={handleClose}
                    onSubmit={onSuccess}
                    column={column}
                    board={board}
                />
            </DialogContent>
        </Dialog>
    );
}

// Formik HTML structure
const ColumnFormHtml = props => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        isSubmitting,
    } = props;
    let buttonText = props.column ? 'Update' : 'Create';
    return (
        <form onSubmit={handleSubmit}>
            <FormControl className="fieldContainer" fullWidth error={errors.title && touched.title && true}>
                <InputLabel variant="standard" htmlFor="content">Label</InputLabel>
                <Input
                    id="title"
                    type="text"
                    value={values.title}
                    placeholder="Type something..."
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                />
                <FormHelperText>
                    {errors.title && touched.title ? errors.title : 'Label shown on column'}
                </FormHelperText>
            </FormControl>

            <DialogActions>
                <Button disabled={isSubmitting} onClick={props.closeDialog} color='inherit'>
                    Cancel
                </Button>
                <Button type="submit" color="primary" variant="outlined" disabled={isSubmitting || !isValid}>
                    {buttonText}
                </Button>
            </DialogActions>
        </form>
    );
};

// Use Formik on some pre-defined Formik HTML
const ColumnFormHandling = withFormik({
    // Initial form values (handles field population when updating Column)
    mapPropsToValues: props => ({
        title: props.column ? props.column.title : ''
    }),
    validationSchema: Yup.object().shape({
        title: Yup.string()
            .required('Label is required!')
    }),
    handleSubmit: (values, formikBag) => {
        // Column is being updated if exists, else false
        let update = !!formikBag.props.column;

        // Supplied column or new one
        const column = formikBag.props.column || new Column(values.title);

        // Update data
        column.title = values.title;
        column.boardID = formikBag.props.board.ID;

        // Execute passed through onSuccess function
        formikBag.props.onSubmit(column).then(() => { // Maps to saveColumns()
            formikBag.resetForm();
            formikBag.setSubmitting(false);

            // Close the form using supplied function
            formikBag.props.closeDialog(); // Maps to handleClose() in NoteDialog

            notify(update ? 'Column updated' : 'Column created');

        }).catch(function (err) {
            console.log(err);
        });
    },
    displayName: 'ColumnForm'
});

// Use Formik on some pre-defined Formik HTML
const ColumnForm = ColumnFormHandling(ColumnFormHtml);

export default ColumnFormDialog;