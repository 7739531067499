import React, { useContext } from 'react';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {CollectionList} from "./CollectionList";
import CollectionFormContext from "../../Forms/Contexts/CollectionForm.context";

export function CollectionOverviewDrawer(props) {
    const {popCollectionFormDialog} = useContext(CollectionFormContext);

    return (
        <Drawer className="CardCollectionMenu" open={props.open} onClose={props.toggle}>
            <DragDropContext
                onDragEnd={props.onCollectionDragEnd}
            >
                <Droppable droppableId="collectionsList" isDropDisabled={false}>
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            <ListSubheader className="ListSubheader">
                                Collections
                                <IconButton onClick={props.toggle} size="large">
                                    <CloseIcon/>
                                </IconButton>
                            </ListSubheader>
                            <List
                                className={"CollectionMenuList dragging" + (snapshot.isDraggingOver ? 'True' : 'False')}
                            >
                                <CollectionList
                                    collections={props.collections}
                                    tags={props.tags}
                                    toggleCollectionDisplay={props.toggleCollectionDisplay}
                                    saveCollection={props.saveCollection}
                                    deleteCollection={props.deleteCollection}
                                />
                                {provided.placeholder}
                                <ListItem button onClick={() => {
                                    popCollectionFormDialog(props.saveCollection, null, props.tags)
                                }}>
                                    <ListItemText primary="New Collection"/>
                                    <AddIcon/>
                                </ListItem>
                            </List>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </Drawer>
    );
}

export default CollectionOverviewDrawer;