import localforage from "localforage";
import {app_version} from "../App"


// A Noto User's meta data containing auxiliary useful data
export class MetaData {
    appVersion: string;
    lastPromptedToExportAt: Date;

    constructor() {
        this.appVersion = app_version;
        this.lastPromptedToExportAt = new Date();
    }
}


export function saveMetaData(metaData: MetaData) {
    metaData.appVersion = app_version;

    return localforage.setItem('metadata', metaData).catch(function(err) {
        throw new Error(err);
    });
}


export function loadMetaData(): Promise<MetaData> {
    return localforage.getItem('metadata').then((result) => {
        if (!result) {
            return new MetaData();
        }
        return Object.assign(new MetaData(), result);

    }).catch(function(err) {
        console.log(err);
        return new MetaData();
    });
}