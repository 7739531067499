export function searchIconsArray(strings, term) {
    if (term && term !== '') {
        // Lowercase for case-insensitive comparisons
        term = term.toLowerCase();

        return strings.filter(function(string) {
            string = string.replace('-', ''); // Remove hyphens

            return string.includes(term);
        });
    }

    return strings;
}