import React, {useState} from 'react';

import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/MoreVert';

import JourneyOverviewDrawer from "../Journeys/JourneyOverviewDrawer";
import JourneyFormDialog from "../../Forms/JourneyForm";
import TopBar from '../../Universal/TopBar';

function JourneyOverviewTopBar({searchCards, journeys, rearrangeJourney}) {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    };

    return (
        <>
            <TopBar
                title='Journeys'
                className='CardOverviewTopBar'
                menuButton={(
                    <IconButton onClick={toggleMenu} size="large">
                        <MoreIcon />
                    </IconButton>
                )}
                searchFunction={searchCards}
                disableGutters
            />
            <JourneyOverviewDrawer
                open={menuOpen}
                toggle={toggleMenu}
                journeys={journeys}
                onJourneyDragEnd={rearrangeJourney}
            />
            <JourneyFormDialog/>
        </>
    )
}

export default JourneyOverviewTopBar;