import React, {useState, useContext} from 'react';

import CardOrnate from './CardOrnate';
import AppContext from '../Contexts/App.context';
import { compareDate } from '../Structures/Order';
import {EmptyMessage} from "../Helpers/tools";

function Timeline({cards}) {
    const ascendingCards = cards.slice().sort((a, b) => compareDate(a.created_at, b.created_at))
    const {focusCard, updateFocusCard} = useContext(AppContext)
    const focusCardID = focusCard?.ID ?? null

    const isEmpty = ascendingCards.length === 0

    const timelineClasses = [
        "Timeline",
        isEmpty ? 'empty' : null
    ]

    return (
        <div className="TimelineWrapper">
            <div className="TimelineSlider"/>
            <div className="StartFade"/>
            <div className="EndFade"/>
            <div className={timelineClasses.join(' ')}>
                {isEmpty && <EmptyMessage />}
                {ascendingCards.map((card, index) => {
                    let date = new Date(card.created_at);
                    let previousDate = index > 0 ? new Date(ascendingCards[index - 1].created_at) : null;
                    const isFocussed = focusCardID === card.ID

                    return (
                        <TimelineItem
                            key={index}
                            isFocussed={isFocussed}
                            previousDate={previousDate}
                            date={date}
                            onClick={() => updateFocusCard(card)}
                        >
                            <div className='TimelineCard'>
                                <CardOrnate key={card.ID} card={card} grid={true} hidden={isFocussed}/>
                            </div> 
                        </TimelineItem>
                    );
                })}
            </div>
        </div>
    )
}

function TimelineItem({previousDate, date, onClick, isFocussed, children}) {
    const showTick = previousDate ? previousDate.getDay() !== date.getDay() : true
    const [hovered, setHovered] = useState(false)

    const classes = [
        "TimelineItem",
        (hovered || isFocussed) ? 'hovered' : null
    ]

    return (
        <div className={classes.join(' ')}>
            <div
                onClick={onClick}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >{children}</div>
           {showTick &&
                <div className="Tick">
                    <span className='day'>{date.toLocaleDateString(undefined, {day: "numeric"})}</span>
                    <span className='weekday'>{date.toLocaleDateString(undefined, {weekday: "short"})}</span>
                </div>
            }
        </div>
    )
}

export default Timeline;