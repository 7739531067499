import React from 'react';

import TopBar from '../../Universal/TopBar';

function CardOverviewTopBar({searchCards}) {
    return (
        <TopBar
            title='Cards'
            className='CardOverviewTopBar'
            searchFunction={searchCards}
        />
    )
}

export default CardOverviewTopBar;