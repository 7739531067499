import {db} from '../db'

export async function updateListOrder(listOrder): Promise<boolean> {
  try {
    await db.listOrders.put(listOrder)

    return true
  } catch (error) {
    return false
  }
}