import { useState, useEffect, useContext } from 'react';
import ConfirmContext from '../Contexts/Confirm.context';

export function usePersistentStorage() {
    const [persistentStoragePermissionGranted, setPersistentStoragePermissionGranted] = useState(false);
    const {confirm} = useContext(ConfirmContext);
    
    const attemptPersistentStorageGrant = () => {
        const onSuccess = () => {
            navigator.storage.persist()
        }
        
        if (!persistentStoragePermissionGranted && navigator.storage && navigator.storage.persist) {
            const confirmTitle = 'Data Storage';
            const confirmMessage = "Noto saves your data to your device - to keep that data safe please enable persistent storage permission. Select 'Allow' if prompted.";
            
            confirm(() => {
                onSuccess();
            }, confirmMessage, confirmTitle);
        }
    }

    const checkPersistentStorageGranted = () => {
        navigator.permissions.query({name:'persistent-storage'}).then((result) => {
            setPersistentStoragePermissionGranted(result.state === 'granted')

            result.onchange = () => {
                setPersistentStoragePermissionGranted(result.state === 'granted')
            }
        });
    }

    useEffect(() => {
        checkPersistentStorageGranted()
    }, []);
    
    return {attemptPersistentStorageGrant};
}