import React, {createContext, useState} from 'react'

const ConfirmContext = createContext()

const ConfirmProvider = ({ children }) => {
    const [open, setOpen] = useState(false)
    const [title, setTitle] = useState(null)
    const [message, setMessage] = useState('')
    const [action, setAction] = useState(null)
    
    // Display Confirmation, on confirm, continue with action
    //
    // @param action {Function} The action to be confirmed
    // @param message {String} Confirmation message
    const confirm = (action, message, title = null) => {
        if (!action) {
            throw new Error('Required function not provided')
        }
        if (!(action instanceof Function)) {
            throw new Error('Parameter "action" is not a function')
        }
        if (typeof message !== "string") {
            throw new Error('Parameter "message" not provided, or not a string')
        }

        setTitle(title)
        setMessage(message)
        setAction(() => action) // Wrapped in a function because of React trying to get result of function (ref: https://dev.to/sarioglu/how-to-store-a-function-using-react-usestate-4ffh)
        setOpen(true)
    }

    const doAction = () => {
        action()
        handleClose()
    }

    const handleClose = () => {
        setOpen(false)
        setTitle(null)
        setMessage('')
        setAction(null)
    }

    return (
        <ConfirmContext.Provider value={{
            open,
            handleClose,
            title,
            message,
            doAction,
            confirm
        }}>
            {children}
        </ConfirmContext.Provider>
    )
}

export {ConfirmProvider}
export default ConfirmContext