import React, {useContext} from 'react'

import CardOverviewTopBar from './Cards/CardOverviewTopBar'
import Cards from '../Cards'
import {searchCards} from "../../Helpers/cardSearch"
import AppContext from "../../Contexts/App.context.js"
import NewCardButton from '../Universal/NewCardButton'
import { useSearchTerm } from '../../hooks/useSearchTerm'
import {Style} from '@mui/icons-material'
import { EmptyStatePrompt } from '../Universal/EmptyStatePrompt'
import NoteFormContext from '../Forms/Contexts/NoteForm.context'
import { EmptyMessage } from '../../Helpers/tools'

function CardScreen() {
    const {cards, tags, loadCards, attemptPersistentStorageGrant} = useContext(AppContext)
    const [searchTerm, updateSearchTerm] = useSearchTerm()
    const {popNoteFormDialog} = useContext(NoteFormContext)

    const onSuccess = () => {
        loadCards()
        attemptPersistentStorageGrant()
    }

    const allCards = cards.slice()
    const allTags = tags.slice()
    const searchedCards = searchCards(allCards.reverse(), searchTerm)

    const emptyStatePrompt = cards.length === 0 && !searchTerm
    const searchEmptyState = searchedCards.length === 0 && searchTerm

    const renderCards = () => {
        if (emptyStatePrompt) return <EmptyStatePrompt
            icon={<Style style={{transform: "rotate(180deg)"}}/>}
            message="Let's create a Card!"
            onClick={() => {popNoteFormDialog(() => onSuccess, null, null, tags)}}
        />

        if (searchEmptyState) return <EmptyMessage />

        return <Cards cards={searchedCards} tags={allTags}/>
    }

    return (
        <div className="Screen">
            <CardOverviewTopBar
                searchCards={updateSearchTerm}
            />
            { renderCards() }
            <NewCardButton/>
        </div>
    )
}

export default CardScreen