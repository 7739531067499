import React, {useState} from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import CardSelectField from "./CardSelectField";
import IconSelectField from "./IconSelectField";
import TemplateSelectField from "./TemplateSelectField";
import BadgeSelectField from "./BadgeSelectField";

export default function InsertField({insertCardLink, insertIcon, insertTemplate, insertBadge}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(1); // Icon

    // Insert fields
    const [cardSelectFieldOpen, setCardSelectFieldOpen] = useState(false);
    const [iconSelectFieldOpen, setIconSelectFieldOpen] = useState(false);
    const [templateSelectFieldOpen, setTemplateSelectFieldOpen] = useState(false);
    const [badgeSelectFieldOpen, setBadgeSelectFieldOpen] = useState(false);

    const options = ['Card Link', 'Icon', 'Template', 'Badge'];

    const openSelectByIndex = (index) => {
        const value = options[index];

        // Choose which insert action to do
        if (value === options[0]) { // Card Link
            setCardSelectFieldOpen(true);
        }

        if (value === options[1]) { // Icon
            setIconSelectFieldOpen(true);
        }

        if (value === options[2]) { // Template
            setTemplateSelectFieldOpen(true);
        }

        if (value === options[3]) { // Badge
            setBadgeSelectFieldOpen(true);
        }
    };

    const handleClick = () => {
        openSelectByIndex(selectedIndex);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        openSelectByIndex(index);
        handleClose();
    };

    const handleToggle = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <ButtonGroup className={'InsertField'} variant="outlined" fullWidth>
                <Button onClick={handleClick} className={'InsertOption'} color='inherit'>{options[selectedIndex]}</Button>
                <Button
                    size="small"
                    onClick={handleToggle}
                    className={'InsertDropDown'}
                    color='inherit'
                >
                    <ExpandMoreIcon />
                </Button>
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorEl} disablePortal placement={'top-end'}>
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList>
                            {options.map((option, index) => (
                                <MenuItem
                                    key={option}
                                    selected={index === selectedIndex}
                                    onClick={(event) => handleMenuItemClick(event, index)}
                                >
                                    {option}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
            <CardSelectField
                open={cardSelectFieldOpen}
                setOpen={setCardSelectFieldOpen}
                insertCardLink={insertCardLink}
            />
            <IconSelectField
                open={iconSelectFieldOpen}
                setOpen={setIconSelectFieldOpen}
                insertIcon={insertIcon}
            />
            <TemplateSelectField
                open={templateSelectFieldOpen}
                setOpen={setTemplateSelectFieldOpen}
                insertTemplate={insertTemplate}
            />
            <BadgeSelectField
                open={badgeSelectFieldOpen}
                setOpen={setBadgeSelectFieldOpen}
                insertBadge={insertBadge}
            />
        </>
    )
};