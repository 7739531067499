import React, {useState} from 'react';
import {debounce} from "lodash";
import Dialog from '@mui/material/Dialog';
import Input from '@mui/material/Input';
import {faIconRefs} from "../../Helpers/faIconRefs";
import {searchIconsArray} from "../../Helpers/search";
import { TwitterPicker } from 'react-color'
import {colorSwatches} from "../../Helpers/colorSwatches";
import {EmptyMessage} from "../../Helpers/tools";
import Select from 'react-select';
import AutoSizer from "react-virtualized-auto-sizer";
import { IconGrid } from "./IconSelectField"
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Badge from '../MarkdownExtensions/Badge';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import { useSearchTerm } from '../../hooks/useSearchTerm';

const MemoizedBadgeSelectField = React.memo(BadgeSelectField, propsAreEqual);

function propsAreEqual(prev, next) {
    return prev.open === next.open;
}

function BadgeSelectField({open, setOpen, insertBadge}) {
    const defaultColor = '#4a4a4a';
    const [searchTerm, updateSearchTerm, setSearchTerm] = useSearchTerm();
    const [color, setColor] = useState(defaultColor);
    const [animation, setAnimation] = useState(null);
    const [content, setContent] = useState('');
    const [dark, setDark] = useState(false);
    const [icon, setIcon] = useState(null);

    const updateContent = debounce((text) => {
        setContent(text);
    }, 500);

    const handleClose = () => {
        setOpen(false);
        // Reset all fields
        setSearchTerm('');
        setColor(defaultColor);
        setAnimation(null);
        setDark(false);
        setIcon(null);
        setContent('')
    };

    const handleClick = (icon) => {
        setIcon(icon);
    };

    const handleSubmit = () => {
        insertBadge(icon, color, animation ? animation.value : null, content, dark);
        handleClose();
    };

    const renderIcons = () => {
        const filteredIcons = searchIconsArray(faIconRefs, searchTerm);

        if (filteredIcons.length === 0) {
            return <EmptyMessage/>
        }

        // Output in virtualized Grid
        return (
            <AutoSizer>
                {({height, width}) => (
                    <IconGrid
                        height={height}
                        width={width}
                        icons={filteredIcons}
                        handleClick={handleClick}
                    />
                )}
            </AutoSizer>
        )
    };

    const animationOptions = [
        { value: '', label: 'None' },
        { value: 'beat', label: 'Beat' },
        { value: 'fade', label: 'Fade' },
        { value: 'beat-fade', label: 'Beat Fade' },
        { value: 'bounce', label: 'Bounce' },
        { value: 'shake', label: 'Shake' },
        { value: 'spin', label: 'Spin' },
        { value: 'spin-pulse', label: 'Spin Staggered' },
    ];

    return (
        <Dialog open={open} onClose={handleClose} className={'BadgeSelectFieldDialog'}>
            <Input
                className={"BadgeSelectContent"}
                type="text"
                placeholder="Badge text..."
                onChange={(event) => updateContent(event.target.value)}
                autoFocus
            />
            <div className={'BadgeIconSelectField'} style={{color: color}}>
                { renderIcons() }
            </div>
            <Input
                className={"IconSelectSearch"}
                type="text"
                placeholder="Search icon..."
                onChange={(event) => updateSearchTerm(event.target.value)}
            />
            <TwitterPicker
                triangle="hide"
                color={color}
                colors={colorSwatches}
                onChangeComplete={(c) => { setColor(c.hex) }}
                width="initial"
            />
            <FormControlLabel
                className={'DarkToggle'}
                control={
                    <Checkbox
                        id="dark"
                        checked={dark}
                        value="dark"
                        onChange={() => setDark(!dark)}
                        color='secondary'
                    />
                }
                label="Dark mode"
            />
            <div className="BadgePreview">
                <Badge
                    icon={icon}
                    color={color}
                    animation={animation ? animation.value : null}
                    content={content}
                    dark={dark}
                />
                <FormHelperText className="fieldDesc">Preview</FormHelperText>
            </div>
            <Select
                className="badge-animation-select"
                classNamePrefix="select"
                isSearchable={false}
                isClearable={false}
                menuPlacement={'top'}
                menuPosition={'fixed'}
                placeholder={'Select animation...'}
                value={animation}
                onChange={(anim) => setAnimation(anim)}
                name="animation"
                defaultValue={null}
                options={animationOptions}
            />
            <DialogActions>
                <Button onClick={handleClose} color='inherit'>
                    Cancel
                </Button>
                <Button color="primary" variant="outlined" onClick={handleSubmit}>
                    Insert
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default MemoizedBadgeSelectField;