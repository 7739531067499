import { Journey } from "../database/models/Journey";
import { Collection } from "./Collection";

// Filter cards by supplied filters and criteria
//
// @param cards {array} Array of Card Objects
// @param collection {object} Collection object containing and array of filters to apply
// example filter object:
// {
//     property: 'merit',
//     value: 10,
//     evaluation: function(a, b) {
//          return a > b;
//     }
// }
//

// @returns {array} Cards matching the filters
export function filterCards(cards, filterObject: Collection) {
    let filters: FilterType[] = filterObject.filters;

    // Run each card against all filters
    return cards.filter(function(card) {
        // If card belongs to Collection, skip filter
        if (card.belongs_to_collections.indexOf(filterObject.ID) !== -1) {
            return true;
        }

        // Inclusive/Exclusive filtering
        return filters.reduce(function (filtersMatched, currentFilter) {
            // Early return if filter match is determined
            // Eg: INCL -> ANY match + inclusive = return TRUE
            //     EXCL -> ANY NOT match + exclusive = return FALSE
            if ((filtersMatched && filterObject.inclusive) ||
                (!filtersMatched && !filterObject.inclusive))
            { return filterObject.inclusive } // becomes filtersMatched

            // Assign for readability
            let evaluation = evaluationStringToFunction(currentFilter.evaluation),
                property = currentFilter.property,
                value = currentFilter.value;

            return evaluation(card[property], value); // becomes filtersMatched
        }, !filterObject.inclusive); // Initial: INCL = false, EXCL = true
    });
}

// TEMPORARY: filterCards and filterJourneyCards should be unified if Collection and Journey are
    // made uniform, as they are essentially the same
export function filterJourneyCards(cards, filterObject: Journey) {
    let filters: FilterType[] = filterObject.filters;

    // Run each card against all filters
    return cards.filter(function(card) {
        // Inclusive/Exclusive filtering
        return filters.reduce(function (filtersMatched, currentFilter) {
            // Early return if filter match is determined
            // Eg: INCL -> ANY match + inclusive = return TRUE
            //     EXCL -> ANY NOT match + exclusive = return FALSE
            if ((filtersMatched && filterObject.inclusiveFilter) ||
                (!filtersMatched && !filterObject.inclusiveFilter))
            { return filterObject.inclusiveFilter } // becomes filtersMatched

            // Assign for readability
            let evaluation = evaluationStringToFunction(currentFilter.evaluation),
                property = currentFilter.property,
                value = currentFilter.value;

            return evaluation(card[property], value); // becomes filtersMatched
        }, !filterObject.inclusiveFilter); // Initial: INCL = false, EXCL = true
    });
}

export type FilterType = {
    property: string
    value: string
    evaluation: EvaluationString
    label: string
}

export class Filter {
    property: string
    value: string
    evaluation: string
    label: string

    constructor(property, value, evaluation, label) {
        this.property = property;
        this.value = value;
        this.evaluation = evaluation;
        this.label = label;
    }
}

type EvaluationString = '=' | "!=" | ">" | "<" | ">=" | "<=" | "inArray" | "!inArray"

// Interpret string and return matching function
function evaluationStringToFunction(string: EvaluationString): (a, b) => boolean {
    switch (string) {
        case '=':
            return function(a, b) {
                return a === b;
            };
        case '!=':
            return function(a, b) {
                return a !== b;
            };
        case '>':
            return function(a, b) {
                return a > b;
            };
        case '<':
            return function(a, b) {
                return a < b;
            };
        case '>=':
            return function(a, b) {
                return a >= b;
            };
        case '<=':
            return function(a, b) {
                return a <= b;
            };
        case 'inArray':
            return function (a, b) {
                return a.indexOf(b) !== -1;
            };
        case '!inArray':
            return function (a, b) {
                return a.indexOf(b) === -1;
            };
        default:
            throw new Error('Given string not supported by stringToFunction().');
    }
}