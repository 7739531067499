import React, {useContext} from 'react';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {BoardList} from "./BoardList";
import BoardFormContext from "../../Forms/Contexts/BoardForm.context";

export function BoardOverviewDrawer(props) {
    const {popBoardFormDialog} = useContext(BoardFormContext);

    return (
        <Drawer className="CardCollectionMenu" open={props.open} onClose={props.toggle}>
            <DragDropContext
                onDragEnd={props.onBoardDragEnd}
            >
                <Droppable droppableId="boardsList" isDropDisabled={false}>
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            <ListSubheader className="ListSubheader">
                                Boards
                                <IconButton onClick={props.toggle} size="large">
                                    <CloseIcon/>
                                </IconButton>
                            </ListSubheader>
                            <List
                                className={"CollectionMenuList dragging" + (snapshot.isDraggingOver ? 'True' : 'False')}
                            >
                                <BoardList
                                    boards={props.boards}
                                    toggleBoardDisplay={props.toggleBoardDisplay}
                                    saveBoard={props.saveBoard}
                                    deleteBoard={props.deleteBoard}
                                />
                                {provided.placeholder}
                                <ListItem button onClick={() => {
                                    popBoardFormDialog(props.saveBoard)
                                }}>
                                    <ListItemText primary="New Board"/>
                                    <AddIcon/>
                                </ListItem>
                            </List>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </Drawer>
    );
}

export default BoardOverviewDrawer;