import React, { useContext } from 'react'

import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import Switch from '@mui/material/Switch'

import EditIcon from '@mui/icons-material/Edit'
import DragIcon from '@mui/icons-material/DragHandle'
import DeleteIcon from '@mui/icons-material/DeleteRounded'

import {Draggable} from "react-beautiful-dnd"
import CollectionFormContext from "../../Forms/Contexts/CollectionForm.context"

export function CollectionListItem(props) {
    const collection = props.collection
    const {popCollectionFormDialog} = useContext(CollectionFormContext)

    return (
        <Draggable draggableId={collection.ID + ''} index={props.index} key={'collectionListItem' + collection.ID}>
            {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.draggableProps}>
                    <ListItem className={"CollectionMenuListItem dragging" + (snapshot.isDragging ? 'True' : 'False')}>
                        <ListItemText primary={collection.title}/>

                        <IconButton
                            aria-label="Delete"
                            onClick={() => {props.deleteCollection(collection)}}
                            size="large">
                            <DeleteIcon/>
                        </IconButton>
                        <IconButton
                            aria-label="Edit"
                            onClick={() => {
                                popCollectionFormDialog(props.saveCollection, collection, props.tags)
                            }}
                            size="large">
                            <EditIcon/>
                        </IconButton>

                        <div {...provided.dragHandleProps}>
                            <IconButton disabled size="large">
                                <DragIcon/>
                            </IconButton>
                        </div>
                        <Switch
                            color="primary"
                            checked={collection.display}
                            onChange={() => {
                                props.toggleDisplay(props.index)
                            }}
                        />
                    </ListItem>
                </div>
            )}
        </Draggable>
    )
}