export const EmptyStatePrompt = ({icon, message, onClick = undefined}) => {
    const classes = ['EmptyStateButton']
    if (onClick !== undefined) classes.push('hasAction')
    
    return (
        <div className='EmptyState'>
            <div className={classes.join(' ')} onClick={onClick}>
                {icon}
                <p>{message}</p>
            </div>
        </div>
    )
}