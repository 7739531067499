import React, {Component} from 'react';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import {Style, Folder, Dashboard, Settings, Route} from '@mui/icons-material';
import AppContext from "../Contexts/App.context.js";

class BottomNav extends Component {
    // Register AppContext (accessible as this.context)
    static contextType = AppContext;

    handleChange = (event, value) => {
        // Check screen changes before proceeding to prevent unnecessary re-render
        if (this.context.screen !== value) {
            this.context.updateScreen(value);
        }
    };

    render() {
        // Process screen value into a nav option
        //
        // - There are now multiple 'screens' within some Screens,
        // but they are represented by the ONE nav item.
        // This processing handles that.
        let value = this.context.screen;
        // Look for 'sub-screens' and convert them to their 'parent' screen for rendering
        if (value === 'collection') { value = 'collections' }
        if (value === 'journey') { value = 'journeys' }
        if (value === 'board') { value = 'boards' }

        return (
            <BottomNavigation
                value={value}
                onChange={this.handleChange}
                className="BottomNav"
            >
                <BottomNavigationAction label="Cards" value="cards" icon={<Style style={{transform: "rotate(180deg)"}} />} />
                <BottomNavigationAction label="Collections" value="collections" icon={<Folder />} />
                <BottomNavigationAction label="Journeys" value="journeys" icon={<Route style={{transform: "rotate(90deg) scaleX(-1)"}}/>} />
                <BottomNavigationAction label="Boards" value="boards" icon={<Dashboard />} />
                <BottomNavigationAction label="Settings" value="settings" icon={<Settings />} />
            </BottomNavigation>
        );
    }
}

export default BottomNav;