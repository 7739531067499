import React, {Component} from 'react';

class FallbackScreen extends Component {
    render() {
        return (
            <div className="Screen">
                Oh goodness! Something isn't right... Sorry about this. How embarrassing!
            </div>
        );
    }
}

export default FallbackScreen;