import localforage from "localforage"
import {notify} from "../Components/Notice"
import {importDB} from "dexie-export-import"

const successfulImportString = "Data import successful"
const failedImportString = "Data import failed"

// Import data from input file to app
//
// @param event {input event}
//
// @returns {Promise}
export function importAppData(file) {
    return new Promise((resolve) => {
        // TODO: Should check app_version
        // TODO: Preview/Warning screen comparing what you have VS what you import - counts of cards etcetera - then confirm import

        const fileExtensions = file?.name.split('.')
        // Is expecting xxx.json or xxx.noto.json
        const dotJson = fileExtensions.includes('json')
        const notoDotJson = (fileExtensions.includes('noto') && dotJson) ?? dotJson

        if (!dotJson && !notoDotJson) {
            notify(`${failedImportString} - File type not supported`)
            return resolve(false)
        }

        let fileReader = new FileReader()
        fileReader.onloadend = () => {
            try {
                // JSON Parse, extract localforage, dexie, import separately
                if (notoDotJson) {
                    let completeDatabase = JSON.parse(fileReader.result)
                    if (!isImportValid(completeDatabase)) throw new SyntaxError()
        
                    const localforageImportBlob = new Blob([JSON.stringify(completeDatabase.localforage)], {type: "application/json"})
                    const dexieImportBlob = new Blob([JSON.stringify(completeDatabase.dexie)], {type: "application/json"})

                    let promises = []
                    promises.push(importDB(localforageImportBlob, {overwriteValues: true}))
                    promises.push(importDB(dexieImportBlob, {overwriteValues: true}))

                    return Promise.all(promises)
                    .then(() => {
                        notify(successfulImportString)
                        return resolve(true)
                    })
                } else if (dotJson) {
                    let importData = JSON.parse(fileReader.result)

                    // Check file validity (JSON)
                    if (importData && typeof importData === "object") {
                        // Import the data from the file
                        return handleImport(importData).then((success) => {
                            if (!success) {
                                notify(failedImportString)
                                return resolve(false)
                            }

                            notify(successfulImportString)
                            return resolve(true)
                        })
                    }
                }
            } catch (error) {
                if (error.name === "SyntaxError") {
                    notify(`${failedImportString} - Structure not supported`)    
                } else {
                    notify(failedImportString)
                }

                return resolve(false)
            }
        }

        fileReader.readAsText(file)
    })
}

const isImportValid = (json) => {
    return json.hasOwnProperty('localforage') && json.hasOwnProperty('dexie')
}

// Import JSON data to web storage
//
// @param data {JSON Object}
//
// @returns {Promise -> bool} Was import successful?
function handleImport(data) {
    // Prepare to collect promises
    let promises = []

    Object.keys(data).forEach(function(key) {
        // Set key to value and store promise
        promises.push(localforage.setItem(key, data[key])
            .then(function() {
                return true
            })
            .catch(function() {
                return false
            }))
    })



    // Check all promises resolved
    return Promise.all(promises).then(function(values) {
        // Did all promises succeed?
        return !values.includes(false)
    })
}