import React from 'react'
import CardBack from "./CardBack"
import Tooltip from '@mui/material/Tooltip'
import {cardValueLabel} from "./CardValue"
import {cardBackBorderColors} from "../Helpers/cardBack"

export function Deck({cards, label, onClick}) {
    const deckMax = 20;
    const subset = cards.slice(0, deckMax);

    // Calculate accumulated merit of all collection's cards
    let totalMerit = cards.reduce(function(totalMerit, card) {
        return totalMerit + card.merit;
    }, 0);

    const renderCards = () => {
        if (cards.length > 0) {
            // Reverse ensures HTML/CSS render order has Card #0 on top
            return subset.reverse().map(card =>
                <CardBack
                    key={card.ID}
                    back={card.back}
                />
            );
        }
    };

    const classes = [
        'Deck',
        subset.length > 10 ? 'veryMany' : null,
        subset.length > 5 ? 'many' : null,
        subset.length === 0 ? 'empty' : null,
        cardValueLabel(totalMerit)
    ];

    // Number of Cards stacked influences position of label
    const offsetIncrement = subset.length > 5 ? (subset.length > 10 ? 0.5 : 1) : 2;
    // Subtract one for bottom card which has offset 0
    const labelOffset = (subset.length - 1) * offsetIncrement;

    return (
        <div className={classes.join(' ')} onClick={onClick}>
            { renderCards() }
            <div
                className={'label'}
                style={{
                    right: labelOffset,
                    top: 'calc(50% - ' + labelOffset*2 + 'px)'
                }}
            >
                {totalMerit > 0 &&
                    <Tooltip title="Merit" placement="top" enterDelay={500} leaveDelay={200}>
                        <div className="meritBanner">{totalMerit}</div>
                    </Tooltip>
                }
                {label}
                <div
                    className={'tab'}
                    style={{
                        width: labelOffset
                    }}
                />
            </div>
        </div>
    )
}

function cardSideStackGradient(colors: string[]) {
    let gradient = 'linear-gradient(180deg, '
    const cardLayers = colors.map((color, index) => color + ` ${index}px ${index + 1}px`).join(',')
    gradient += cardLayers
    gradient += ')'
    return gradient
}

export function VolumeDeck({cards}) {
    let volume = cards.length
    const classes = [
        'VolumeDeck',
        volume === 0 ? 'empty' : null
    ]
    const topCardClasses = [
        'TopCard'
    ]

    let borderColorsGradient = ''

    // Account for topCard height
    if (volume > 0) {
        volume = --volume

        let topCardBack = cards[0].back
        topCardClasses.push(topCardBack)
        const leftBorderColors = cards.map(card => cardBackBorderColors(card.back ?? 'default').left)
        borderColorsGradient = cardSideStackGradient(leftBorderColors)
    }

    return (
        <div className={classes.join(' ')}>
            <div className='Stack' style={{height: `${volume}px`, background: borderColorsGradient}}>
                <div className={topCardClasses.join(' ')}/>
            </div>
        </div>
    )
}