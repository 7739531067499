import React from 'react';
import MDCCard from '@mui/material/Card';

// TODO: Could potentially implement this CardBack in Card and CardOrnate
export default function CardBack({back}) {
    const classes = [
        'Card',
        'back',
        back ? 'cardBack_' + back : null,
        'CardBack'
    ];

    return <MDCCard className={classes.join(' ')}/>
}