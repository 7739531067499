import localforage from "localforage";

import {loadColumns, deleteColumns} from "./Column";

// <editor-fold desc="Board">

// A Board is for tactile arrangement of Cards and Columns (of Cards)
//
// @param title {string}
export class Board {
    constructor(title = null) {
        this.ID = null;
        this.display = true;

        this.title = title;
        // IDs of Columns that belong to this Board
        // Used for ordering/rearranging columns
        this.columns = [];
    }
}

//<editor-fold desc="Board Management Helpers">

// Handle auto incrementing IDs
//
// @return {Promise -> Int} Evaluates to incremented ID
function generateID() {
    return localforage.getItem('boardIdIncrementer').then(function (ID) {
        // Increment cardIdIncrementer and return
        return localforage.setItem('boardIdIncrementer', ID + 1)
            .then((result) => {return result});

    }).catch(function(error) {
        console.log(error);
    });
}
//</editor-fold>

//<editor-fold desc="Boards">

// Recursively generate and set IDs where not set
//
// @param boards {Array}
//
// @returns Promise -> Array
function generateIDs(boards, i = 0) {
    // Return on last collection
    if (i === boards.length) { return Promise.resolve(boards); }

    // If no ID
    if (boards[i].ID === null) {
        // generate ID
        return generateID().then((id) => {
            boards[i].ID = id;
            // Move on to next iteration
            return generateIDs(boards, i + 1);
        });
    }
    // Move on to next iteration
    return generateIDs(boards, i + 1);
}

// Save Boards to storage with name
//
// @param boards {array} Array of Board class objects to save
//
// @returns Promise
export function saveBoards(boards) {
    if (!Array.isArray(boards)) {
        throw new Error('Supplied parameter "boards" is not an array.');
    }

    return generateIDs(boards).then((boards) => {
        // Save
        return localforage.setItem('boards', boards).catch(function(err) {
            throw new Error(err);
        });
    });
}

// Get Boards from storage by name
//
// @returns Promise -> Array
export function loadBoards() {
    return localforage.getItem('boards').then((result) => {
        // Make sure an array is returned
        if (Array.isArray(result)) {
            return result.map(function(board) {
                return Object.assign(new Board(), board)
            });
        }
        return [];
    }).catch(function(err) {
        console.log(err);
        return [];
    });
}

// Delete
//
// @param id {int} ID of the board to delete
//
// @returns {Promise} Promise to delete the board, returns boards
export function deleteBoard(id) {
    // Get existing boards
    return loadBoards().then(boards => {
        // Find by board by ID
        let targetBoard = boards.find((board) => {
            return board.ID === id;
        });

        // Target index of Board to delete
        let targetBoardIndex = boards.indexOf(targetBoard);

        // If index found
        if (targetBoardIndex > -1) {
            // Remove board
            boards.splice(targetBoardIndex, 1);

            // Get all columns
            return loadColumns()
                // Delete columns belonging to Board to be deleted
                .then((columns) => {
                    // Get columns of this Board
                    const columnsToDelete = columns.filter(function (column) {
                        return column.boardID === targetBoard.ID;

                    // Map columns to their IDs
                    }).map((column) => { return column.ID; });

                    return deleteColumns(columnsToDelete);
                })
                .then(() => {
                    // Set boards data (now with one less board) to storage
                    return saveBoards(boards);
                });
        }

        throw new Error('Couldn\'t find Board by ID to delete.');
    });
}
//</editor-fold>
//</editor-fold>