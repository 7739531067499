import React from 'react';

import Grid from '@mui/material/Grid';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export default function PwaInstallContent() {
    return (
        <>
            <h2>Manual Install</h2>
            <p>This browser doesn't currently support automatic installation, but you may be able to install manually.</p>
            <p>In your browser options, look for <i>Install</i> or <i>Add to Home Screen</i>.</p>
            <h3>Common manual install steps:</h3>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <p className={"featureTitle"}><strong>iOS Safari</strong></p>
                    <p><ExitToAppIcon className={'textAlignIcon'} style={{transform: "rotate(270deg)"}}/> <i>Sharing</i> <NavigateNextIcon className={'textAlignIcon'}/> <AddBoxIcon className={'textAlignIcon'}/> <i>Add to Home Screen</i></p>
                </Grid>
                <Grid item xs={12} md={6}>
                    <p className={"featureTitle"}><strong>Android Firefox</strong></p>
                    <p><MoreVertIcon className={'textAlignIcon'}/><i>Options</i> <NavigateNextIcon className={'textAlignIcon'}/> <AddToHomeScreenIcon className={'textAlignIcon'}/> <i>Install</i></p>
                </Grid>
            </Grid>

            <p><strong>Unsupported Browsers</strong></p>
            <p><small><i>MacOS Safari</i>, <i>MacOS Firefox</i> and <i>Windows Firefox</i> do not currently have support for installation.</small></p>
            <p><small>If you'd like to install Noto but still use your favourite browser, you can install Noto using a browser with full installation support like Google Chrome and then return to your usual browser.</small></p>
        </>
    )
}