import React, {useState} from 'react';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';

interface TopBarSearchFieldProps {
    updateSearchTerm: (value: string) => void
}

function TopBarSearchField({updateSearchTerm}: TopBarSearchFieldProps) {
    const [searchFieldValue, setSearchFieldValue] = useState('')

    const clearSearchField = () => {
        updateSearchTerm('')
        setSearchFieldValue('')
    }

    const handleChange = (event) => {
        const value = event.target.value
        updateSearchTerm(value)
        setSearchFieldValue(value)
    }

    return (
        <Input
            id="globalSearch"
            className="globalSearchField"
            type="text"
            placeholder="Search..."
            onChange={handleChange}
            endAdornment={
                !!searchFieldValue &&
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={clearSearchField}
                            size="small"
                        >
                            <CloseIcon/>
                        </IconButton>
                    </InputAdornment>
            }
            value={searchFieldValue}
        />
    )
}

export default TopBarSearchField;