import { createContext, useState } from 'react';
import {Board as BoardClass} from "../../../Structures/Board";

const ColumnFormContext = createContext();

const ColumnFormProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [column, setColumn] = useState(null);
    const [board, setBoard] = useState(null);
    const [onSuccess, setOnSuccess] = useState(undefined);

    const popColumnFormDialog = (onSuccessFn = function() {}, column = null, board = null) => {

        if (!(onSuccessFn instanceof Function)) {
            throw new Error('Parameter "onSuccessFn" is not a function');
        }

        if (column !== null && !(typeof column === 'object')) {
            throw new Error('Parameter "column" is not an Object OR null');
        }

        if (board !== null && !(board instanceof BoardClass)) {
            throw new Error('Parameter "board" is not an instance of Board Class OR null');
        }

        setColumn(column);
        setBoard(board);
        setOnSuccess(onSuccessFn);
        setOpen(true);
    };

    const closeDialogue = () => {
        setOpen(false);
        setColumn(null);
        setBoard(null);
        setOnSuccess(undefined);
    };

    return (
        <ColumnFormContext.Provider value={{
            open,
            column,
            board,
            closeDialogue,
            onSuccess,
            popColumnFormDialog
        }}>
            {children}
        </ColumnFormContext.Provider>
    );
};

export {ColumnFormProvider}
export default ColumnFormContext