import React, {useContext} from 'react';
import Tooltip from '@mui/material/Tooltip';
import InfoDialogContext from '../Contexts/InfoDialog.context';
import IntroductionContent from '../Components/Content/IntroductionContent';

function Logo() {
    const {infoDialog} = useContext(InfoDialogContext);

    return (
        <Tooltip title="About Noto" placement="bottom" enterDelay={500} leaveDelay={200}>
            <div className={'logo'} onClick={() => infoDialog(<IntroductionContent/>, "Let's go!")}/>
        </Tooltip>
    )
}

export default Logo;