import React, {useState, useMemo} from 'react';
import Timeline from '../../Timeline';
import { VolumeDeck } from '../../Deck';
import {Route} from '@mui/icons-material';
import { EmptyStatePrompt } from '../../Universal/EmptyStatePrompt';
import { EmptyMessage } from '../../../Helpers/tools';

const months = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
    9: [],
    10: [],
    11: []
}

function groupCardsByYearAndMonth(cards) {
    const years = {}

    cards.forEach(card => {
        const date = new Date(card.created_at)
        const year = date.getFullYear()
        const month = date.getMonth()

        if (!years[year]) {
            // Add year if not existing, with month structure
            years[year] = structuredClone(months)
        }
        years[year][month].push(card)
    })

    return years
}

function Journey({cards, searchTerm}) {
    const noCards = cards.length === 0
    const emptyStatePrompt = noCards && !searchTerm
    const searchEmptyState = noCards && searchTerm
    
    if (emptyStatePrompt) return <EmptyStatePrompt
        icon={<Route style={{transform: "rotate(90deg) scaleX(-1)"}}/>}
        message="Journey has yet to begin..."
    />

    if (searchEmptyState) return <EmptyMessage />

    const cardsByYear = useMemo(() => groupCardsByYearAndMonth(cards), [cards])
    const sequentialFirstYear = Object.keys(cardsByYear)[0]

    const [currentMonth, setCurrentMonth] = useState('0')
    const [currentYear, setCurrentYear] = useState(sequentialFirstYear)

    // Default to sequentialFirstYear to
    // handle case - Search term removes a year from cardsByYear, currentYear points to nothing
    const cardsForActiveYear = cardsByYear[currentYear] ?? cardsByYear[sequentialFirstYear]
    const activeYear = cardsByYear[currentYear] ? currentYear : sequentialFirstYear

    return (
        <div className='Journey'>
            <Timeline cards={cardsForActiveYear[currentMonth]} />
            <JourneyMonths
                currentMonth={currentMonth}
                setCurrentMonth={setCurrentMonth}
                cards={cardsForActiveYear}
            />
            <JourneyYears
                years={Object.keys(cardsByYear)}
                currentYear={activeYear}
                setCurrentYear={setCurrentYear}
                currentMonth={currentMonth}
                cards={cardsByYear}
            />
        </div>
    )
}

function JourneyMonths({currentMonth, setCurrentMonth, cards}) {
    return (
        <div className='JourneyMonths'>
            <div className='Timeline'/>
            <ul>
                {Object.keys(months).map((month) => {
                    const date = new Date()
                    date.setMonth(month)
                    const dateString = date.toLocaleString('default', { month: 'short' })
                    return <li key={month} className={currentMonth === month ? 'active' : ''} onClick={() => setCurrentMonth(month)}>
                        <VolumeDeck cards={cards ? cards[month] : []}/>
                        <div className='Tick'>
                            <span>{dateString}</span>
                        </div>
                    </li>
                })}
            </ul>
        </div>
    )
}

function JourneyYears({years, currentYear, setCurrentYear, cards}) {
    const ascendingYears = years.toSorted((a, b) => a - b)

    return (
        <div className='JourneyYears'>
            <div className='Timeline'/>
            <ul>
                {ascendingYears.map(year => {
                    const yearCards = []
                    if (cards) {
                        Object.keys(cards[year]).forEach(month => yearCards.push(...cards[year][month]))
                    }

                    return <li key={year} className={currentYear === year ? 'active' : ''} onClick={() => setCurrentYear(year)}>
                        <VolumeDeck cards={yearCards}/>
                        <div className='Tick'>
                            <span>{year}</span>
                        </div>
                    </li>
                })}
            </ul>
        </div>
    )
}

export default Journey;