import React, {Component} from 'react';

import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { TwitterPicker } from 'react-color'
import {saveTags, Tag} from "../../Structures/Tag";
import {colorSwatches} from "../../Helpers/colorSwatches";


const defaultColor = '#A9A9A9';

// Exportable function for re-usable tag structure for select fields
export const tagStructure = (tag) => {
    return {
        value: tag.tag ? tag.tag : tag.value, // handle Tag or Filter
        label: tag.tag ? tag.tag : tag.label, // handle Tag or Filter
        property: tag.property ? tag.property : 'tags',
        evaluation: tag.evaluation ? tag.evaluation : 'inArray',
        color: tag.color ? tag.color : defaultColor
    };
};

// Exportable function for updating or adding Tags to an array of Tags
//
// @param tags {Array} Array of existing Tags
// @param tag {Object} Tag to add
//
// @returns {Array} Updated array of Tags
export const updateTagsArray = (tags, tag) => {
    // Make immutable
    tags = tags.slice();

    // If tag not already present
    const index = tags.findIndex((t) => {
        return t.value === tag.value;
    });

    // If tag exists
    if (index > -1) {
        // Update tag
        tags.splice(index, 1, tag);
    } else { // Tag doesn't exist
        // Add tag
        tags.push(tag);
    }

    return tags;
};

// Update Tags
// Saves all tag options so that Card and Options that are updated will be saved
//
// @param tags {Array} Array of tag data to be updated
//
// @returns {Void}
export const updateTags = (updatedTagsData) => {
    let tags = updatedTagsData ? updatedTagsData.map(function(tag) {
        return new Tag(tag.value, tag.color);
    }) : [];

    saveTags(tags).then(() => {
        // Nothing
    }).catch(function (err) {
        console.log(err);
    });
};

class TagField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: this.props.tagsData,
            colorPickerOpen: false,
            valueColorPickerOpen: false,
            pendingColor: null
        };
    }

    handleChange = (value, action) => {
        // action parameter details here: https://react-select.com/advanced#action-meta

        // New tag created in select
        if (action.action === 'create-option') {
            let setColor = this.state.pendingColor || defaultColor;
            let newTag = value[value.length - 1];
            // Add option to updatedTagsData
            this.props.updateTagsData({value: newTag.value, label: newTag.value, color: setColor});
            // Add color to tag before it's set with prop onChange
            newTag.color = setColor;
            // Reset pendingColor
            this.setState({pendingColor: null});
        }

        // Calls setFieldValue and manually updates values.tags
        this.props.onChange(this.props.id, value);
    };

    toggleColorPicker = (colorPickerStateKey, option = false) => {
        // If same value selected twice, user is trying to close the color picker. Set false
        if (this.state[colorPickerStateKey].value === option.value) {option = false}

        // colorPickerOpen, valueColorPickerOpen
        this.setState({[colorPickerStateKey]: option});
    };

    // Handle changing color for options and values
    //
    // @param color{Object} Provided by Color Picker input field
    // @param stream{string} Determines if handling options or values
    handleColorChange = (color, stream = 'option') => {
        color = color.hex;

        // Bool to easily determine whether updating an option or value
        const valStream = stream === 'value';

        // Get options OR values information
        let items = valStream ? this.props.value.slice() : this.state.options.slice();
        const colorPickerOpen = valStream ? this.state.valueColorPickerOpen.value : this.state.colorPickerOpen.value;

        // Get index of item being edited
        const itemIndex = items.findIndex((itm) => {
            return itm.value === colorPickerOpen;
        });

        // If tag/option exists
        if (itemIndex > -1) {
            // Change color
            items[itemIndex].color = color;

            if (!valStream) {
                // Update
                this.setState({options: items});
            }

            // Add option to updatedTagsData
            this.props.updateTagsData(items[itemIndex]);

        } else { // New tag
            // Store pending new tag's color in state
            this.setState({pendingColor: color});
            // Render the color on the new tag box
        }

        // Close color picker
        this.toggleColorPicker(valStream ? 'valueColorPickerOpen' : 'colorPickerOpen');
    };

    render() {
        const MultiValueLabel = props => { return (
            <div
                onClick={() => this.toggleColorPicker('valueColorPickerOpen', props.data)}
                onMouseDown={(e) => e.stopPropagation()} // onMouseDown prevents clicking a value opening the select dropdown
                onTouchEnd={(e) => e.stopPropagation()} // onTouch prevents touching a value opening the select dropdown
            >
                <components.MultiValueLabel {...props} />
            </div>
        );};

        const Option = (props) => {
            const colorPicker = this.state.colorPickerOpen.value;
            const { innerProps, innerRef } = props;
            const color = (props.data.__isNew__ ? this.state.pendingColor : props.data.color) || defaultColor;

            return (
                <div className={'tagOption'} key={props.value} ref={innerRef}>
                    <components.Option {...props} {...innerProps}/>
                    <div
                        className={'tagOptionColor'}
                        style={{backgroundColor: color}}
                        onClick={() => {this.toggleColorPicker('colorPickerOpen', props.data)}}
                    ></div>
                    { colorPicker === props.data.value &&
                        <TwitterPicker
                            triangle="hide"
                            color={color}
                            colors={colorSwatches}
                            onChangeComplete={(color) => { this.handleColorChange(color) }}
                            width="initial"
                        />}
                </div>
            );
        };

        return (
            <React.Fragment>
                <InputLabel className="selectLabel" htmlFor={this.props.id}>{this.props.label}</InputLabel>
                <CreatableSelect
                    id={this.props.id}
                    placeholder="Select..."
                    options={this.state.options}
                    menuPosition={'fixed'}
                    isMulti
                    isSearchable
                    // This makes sense for desktop and mobile, prevents close on selection
                    closeMenuOnSelect={false}
                    // This prevents 'jerkyness' on touch devices,
                    // where selecting an option would un-focus and close the menu and
                    // also the on-screen-keyboard (which changes the whole screen and is very disconcerting)
                    blurInputOnSelect={false}
                    onChange={this.handleChange}
                    value={this.props.value}
                    components={{MultiValueLabel, Option}}
                    styles={{
                        multiValue: (base, {data}) => ({
                            ...base,
                            backgroundColor: data.color,
                        }),
                        multiValueLabel: base => ({
                            ...base,
                            color: '#fff',
                            textShadow: '0 0 1px #000'
                        }),
                        valueContainer: base => ({
                            ...base,
                            overflow: 'scroll',
                            maxHeight: 60,
                        }),
                        menuPortal: base => ({
                            ...base,
                            zIndex: 9999,
                        }),
                    }}
                />
                { this.state.valueColorPickerOpen &&
                    <TwitterPicker
                        triangle="hide"
                        color={this.state.valueColorPickerOpen.color}
                        colors={colorSwatches}
                        onChangeComplete={(color) => { this.handleColorChange(color, 'value') }}
                        width="initial"
                    />
                }
                <FormHelperText className="fieldDesc">
                    {this.props.description}
                </FormHelperText>
            </React.Fragment>
        );
    }
}

export default TagField;