import React, { useContext } from 'react';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import * as Yup from "yup";
import {withFormik} from "formik/dist/index";

import {notify} from "../Notice";
import FilterField from "../../Components/Forms/FilterField";
import OrderField from "../../Components/Forms/OrderField";
import {CollectionClass, newFilterData, newOrderData} from "../../Structures/Collection";
import {orderSelectOptions} from "../../Structures/Order";
import TagField, {updateTags, tagStructure, updateTagsArray} from "./TagField";
import {tagDataForFilter} from "../../Structures/Tag";
import CollectionFormContext from "./Contexts/CollectionForm.context";

function CollectionFormDialog() {
    const {
        open,
        closeDialogue,
        collection,
        tagsData,
        onSuccess
    } = useContext(CollectionFormContext);

    // Close dialog box
    const handleClose = () => {
        closeDialogue()
    };

    const title = collection ? 'Edit Collection' : 'New Collection';

    return (
        <Dialog
            className="CollectionDialog DialogOverride"
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {/*Send closeDialog prop to allow dialog state to be updated from deeper*/}
                <CollectionForm
                    closeDialog={handleClose}
                    onSubmit={onSuccess}
                    collection={collection}
                    tagsData={tagsData} // TODO: Fix this???
                />
            </DialogContent>
        </Dialog>
    );
}

// Formik HTML structure
const CollectionFormHtml = props => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isValid,
        isSubmitting,
    } = props;

    let buttonText = props.collection ? 'Update' : 'Create';

    let tagsData = props.tagsData.map(tag => tagStructure(tag));

    // Update or add Tags to be updated/saved
    let updateTagsData = (tag) => {
        setFieldValue('updatedTagsData', updateTagsArray(values.updatedTagsData, tag));
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormControl className="fieldContainer" fullWidth error={errors.title && touched.title && true}>
                <InputLabel variant="standard" htmlFor="content">Label</InputLabel>
                <Input
                    id="title"
                    type="text"
                    value={values.title}
                    placeholder="Type something..."
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                />
                <FormHelperText>
                    {errors.title && touched.title ? errors.title : 'Label shown on collection'}
                </FormHelperText>
            </FormControl>

            <FormControl className="fieldContainer" fullWidth>
                <TagField
                    id="tagFilterOptions"
                    label="Tags"
                    description="Select or add tags to filter by"
                    onChange={setFieldValue}
                    tagsData={tagsData}
                    updateTagsData={updateTagsData}
                    value={values.tagFilterOptions}
                />
            </FormControl>
            <FormControl className="fieldContainer" fullWidth>
                <FilterField
                    id="filterOptions"
                    label="Filters"
                    description="Additional filtering options"
                    onChange={setFieldValue}
                    value={values.filterOptions}
                />
            </FormControl>
            <FormControl className="fieldContainer" fullWidth>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="inclusive"
                            checked={values.inclusive}
                            value="inclusive"
                            onChange={handleChange}
                            color='secondary'
                        />
                    }
                    label={values.inclusive ?
                        "Inclusive filtering (has ANY option)" :
                        "Exclusive filtering (must have ALL options)"}
                />
            </FormControl>

            <FormControl className="fieldContainer" fullWidth>
                <OrderField
                    id="orders"
                    label="Order"
                    description="Select how to order the collection"
                    onChange={setFieldValue}
                    value={values.orders}
                />
            </FormControl>

            <DialogActions>
                <Button disabled={isSubmitting} onClick={props.closeDialog} color='inherit'>
                    Cancel
                </Button>
                <Button type="submit" color="primary" variant="outlined" disabled={isSubmitting || !isValid}>
                    {buttonText}
                </Button>
            </DialogActions>
        </form>
    );
};

// Use Formik on some pre-defined Formik HTML
const CollectionFormHandling = withFormik({
    // Initial form values (handles field population when updating Collection)
    mapPropsToValues: props => ({
        title: props.collection ? props.collection.title : '',

        tagFilterOptions: props.collection ? tagDataForFilter(props.tagsData, props.collection.filters)
            .filter(filter => filter.property === 'tags') // Limit to tag related filters
            .map(filter => tagStructure(filter)) : [],

        filterOptions: props.collection ? props.collection.filters
            .filter(filter => filter.property !== 'tags') // Limit to filters that aren't tag related
            .map(filter => tagStructure(filter)) : [],

        updatedTagsData: [],
        inclusive: props.collection ? props.collection.inclusive : true,
        orders: props.collection ? props.collection.orders
            .reduce(function(selectOptions, order) {
                let value = [order.property, order.ascending, order.comparison];

                // Find orderSelectOption that matches collection order values
                let label = orderSelectOptions.find(function(orderSelectOption) {
                    // Match values with array
                    return (orderSelectOption.value[0] === value[0] &&
                        orderSelectOption.value[1] === value[1] &&
                        orderSelectOption.value[2] === value[2]);
                });

                // Return matches and structure them for select field
                if (label) {
                    selectOptions.push({value: value, label:label.label})
                }
                return selectOptions;
            }, []) : [],
    }),
    validationSchema: Yup.object().shape({
        title: Yup.string()
            .required('Label is required!')
    }),
    handleSubmit: (values, formikBag) => {
        // Collection is being updated if exists, else false
        let update = !!formikBag.props.collection;

        // Convert nulls to empty arrays (prevents error during mapping)
        if (values.tagFilterOptions === null) { values.tagFilterOptions = []; }
        if (values.filterOptions === null) { values.filterOptions = []; }

        // Map selected tags to new filters
        let tagFilters = values.tagFilterOptions.map(function(tag) {
            // Add missing fields to new tags
            // - will add property, evaluation
            tag = tagStructure(tag);

            return newFilterData(tag.property, tag.value, tag.evaluation, tag.label)
        });

        // Map selected filter options to new filters
        let filters = values.filterOptions.map(function(opt) {
            return newFilterData(opt.property, opt.value, opt.evaluation, opt.label)
        });

        // Merge filter options into one array (to be processed as Filters later on)
        // - Originally, all filters were managed together, for better UI and consistency, tags and other filters were separated
        // Note: Confirming values are arrays isn't required because we ensure this a few lines up
        filters = tagFilters.concat(filters);

        // Supplied collection or new one
        const collection = formikBag.props.collection || new CollectionClass(
            values.title,
            filters
        );

        // Save updates to Tags
        updateTags(values.updatedTagsData);

        // Update data
        collection.title = values.title;
        collection.filters = filters;
        collection.inclusive = values.inclusive;
        collection.orders = values.orders.map(function(order) {
            return newOrderData(order.value[0], order.value[1], order.value[2])
        });

        // Execute passed through onSuccess function
        formikBag.props.onSubmit(collection).then(() => { // Maps to saveCollections()
            formikBag.resetForm();
            formikBag.setSubmitting(false);

            // Close the form using supplied function
            formikBag.props.closeDialog(); // Maps to handleClose() in NoteDialog

            notify(update ? 'Collection updated' : 'Collection created');

        }).catch(function (err) {
            console.log(err);
        });
    },
    displayName: 'CollectionForm'
});

// Use Formik on some pre-defined Formik HTML
const CollectionForm = CollectionFormHandling(CollectionFormHtml);

export default CollectionFormDialog;