export function cardWearClass(wear_amount) {
    const tier_gap = 20;
    const tier = Math.floor(wear_amount / tier_gap);

    // Max level
    if (wear_amount >= 15 * tier_gap) {
        return `condition_w15`;
    }

    // Calculate tier
    if (wear_amount >= tier_gap) {
        return `condition_w${tier}`;
    }

    return `condition_new`;
}

export function cardWearVariantClass(variant) {
    return `wear_variant_${variant}`;
}