import React, {useContext} from 'react';

import CardScreen from './CardScreen';
import CollectionScreen from './CollectionScreen';
import JourneyScreen from './JourneyScreen';
import BoardScreen from './BoardScreen';
import SettingsScreen from './SettingsScreen';
import FallbackScreen from './FallbackScreen';
import AppContext from "../../Contexts/App.context";

const Screen = () => {
    const {screen} = useContext(AppContext);

    switch (screen) {
        case 'cards':
            return (
                <CardScreen/>
            );

        // Journey overview
        // An open Journey
        case 'journeys':
        case 'journey':
            return (
                <JourneyScreen/>
            );
        
        // Collections overview
        // An open Collection
        case 'collections':
        case 'collection':
            return (
                <CollectionScreen/>
            );

        // Boards overview
        // An open Board
        case 'boards':
        case 'board':
            return (
                <BoardScreen/>
            );

        case 'settings':
            return (
                <SettingsScreen/>
            );

        default:
            return (
                <FallbackScreen/>
            );
    }
};

export default Screen;