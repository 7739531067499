import React, { useContext } from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';

import EditIcon from '@mui/icons-material/Edit';
import DragIcon from '@mui/icons-material/DragHandle';
import DeleteIcon from '@mui/icons-material/DeleteRounded';

import {Draggable} from "react-beautiful-dnd";
import JourneyFormContext from "../../Forms/Contexts/JourneyForm.context";
import JourneyContext from '../../../Contexts/Journey.context';
import AppContext from '../../../Contexts/App.context';

// Abstracted as recommended by (https://github.com/atlassian/react-beautiful-dnd#recommended-droppable-performance-optimisation)
// for performance optimization
export function JourneyList(props) {
    return (
        props.journeys.map((journey, index) => (
            <JourneyListItem
                key={index}
                journey={journey}
                index={index}
            />
        ))
    )
}

function JourneyListItem({index, journey}) {
    const {popJourneyFormDialog} = useContext(JourneyFormContext)
    const {handleUpdateJourney, handleDeleteJourney, handleToggleJourneyDisplay} = useContext(JourneyContext)
    const {tags} = useContext(AppContext)

    return (
        <Draggable draggableId={journey.id + ''} index={index} key={'collectionListItem' + journey.id}>
            {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.draggableProps}>
                    <ListItem className={"CollectionMenuListItem dragging" + (snapshot.isDragging ? 'True' : 'False')}>
                        <ListItemText primary={journey.title}/>

                        <IconButton
                            aria-label="Delete"
                            onClick={() => handleDeleteJourney(journey)}
                            size="large">
                            <DeleteIcon/>
                        </IconButton>
                        <IconButton
                            aria-label="Edit"
                            onClick={() => {
                                popJourneyFormDialog(() => handleUpdateJourney, journey, tags)
                            }}
                            size="large">
                            <EditIcon/>
                        </IconButton>

                        <div {...provided.dragHandleProps}>
                            <IconButton disabled size="large">
                                <DragIcon/>
                            </IconButton>
                        </div>
                        <Switch
                            color="primary"
                            checked={journey.display}
                            onChange={() => handleToggleJourneyDisplay(journey)}
                        />
                    </ListItem>
                </div>
            )}
        </Draggable>
    )
}