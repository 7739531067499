export const colorSwatches = [
    '#ff8600',
    '#edeef4',
    '#9fa7f3',
    '#6272fd',

    '#880009',
    '#ff0037',
    '#f26249',
    '#FAAFA5',

    '#f26700', // change
    '#ff9b5a',

    '#ffd58e',
    '#ffe714',
    '#f5fb41',

    '#aeda01',
    '#00d876',
    '#9FE481',
    '#a4bea0',
    '#8bad9d',

    '#52b3b6',
    '#67D0DD',
    '#4cb2ff',
    '#508ec0',
    '#0057ed',
    '#0a1058',

    '#402060',
    '#6e48cf',
    '#9857ff',
    '#A885EE',
    '#DC95DD',

    '#dfd7bb',
    '#d89065',
    '#c36c5d',
    '#3d3d3d',
    '#222222',
    '#10172d',


];