import { createContext, useState } from 'react';

const JourneyFormContext = createContext();

const JourneyFormProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [journey, setJourney] = useState(null);
    const [tagsData, setTagsData] = useState([]);
    const [onSuccess, setOnSuccess] = useState(undefined);

    const popJourneyFormDialog = (onSuccessFn = function() {}, journey = null, tags = []) => {
        if (!(onSuccessFn instanceof Function)) {
            throw new Error('Parameter "onSuccessFn" is not a function');
        }

        if (journey !== null && !(typeof journey === 'object')) {
            throw new Error('Parameter "journey" is not an Object OR null');
        }

        setJourney(journey);
        setTagsData(tags);
        setOnSuccess(onSuccessFn);
        setOpen(true);
    };

    const closeDialogue = () => {
        setOpen(false);
        setJourney(null);
        setTagsData([]);
        setOnSuccess(undefined);
    };

    return (
        <JourneyFormContext.Provider value={{
            open,
            journey,
            tagsData,
            closeDialogue,
            onSuccess,
            popJourneyFormDialog
        }}>
            {children}
        </JourneyFormContext.Provider>
    );
};

export {JourneyFormProvider}
export default JourneyFormContext