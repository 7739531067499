import React from 'react';

import IconButton from '@mui/material/IconButton';
import BackIcon from '@mui/icons-material/ArrowBack';
import ColumnFormDialog from "../../Forms/ColumnForm";
import TopBar from '../../Universal/TopBar';

function BoardTopBar({board, close}) {
    return (
        <>
            <TopBar
                title={board.title}
                className='BoardTopBar'
                menuButton={(
                    <IconButton onClick={close} size="large">
                        <BackIcon />
                    </IconButton>
                )}
                disableGutters
            />
            <ColumnFormDialog/>
        </>
    );
}

export default BoardTopBar;