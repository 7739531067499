import React, {useEffect, useContext} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material/styles';

// Components
import BottomNav from './Components/BottomNavigation';
import Notice from './Components/Notice';
import ConfirmDialog from './Components/ConfirmDialog';
import {ConfirmProvider} from './Contexts/Confirm.context';
import Screen from './Components/Screens/Screen';
import { SnackbarProvider } from 'notistack';
import IntroductionContent from './Components/Content/IntroductionContent';
import InfoDialog from './Components/InfoDialog';
import CardFocusDialog from './Components/CardFocusDialog';
import NoteFormDialog from './Components/Forms/NoteForm';
import {catchPwaInstall} from "./Helpers/pwaInstall";
import AppContext, {AppProvider} from "./Contexts/App.context";
import ConfigContext, {ConfigProvider} from "./Contexts/Config.context";
import {MetaDataProvider} from "./Contexts/MetaData.context";
import {NoteFormProvider} from "./Components/Forms/Contexts/NoteForm.context";
import InfoDialogContext, {InfoDialogProvider} from "./Contexts/InfoDialog.context";
import Presence from './Components/Presence';
import { JourneyProvider } from './Contexts/Journey.context';

export const app_version = '1.33.0';

const theme = createTheme(adaptV4Theme({
    palette: {
        primary: {
            light: '#ff9e33',
            main: '#ff8600',
            dark: '#b25d00',
            contrastText: '#fff',
        },
        secondary: {
            light: '#6e6e6e',
            main: '#4a4a4a',
            dark: '#333333',
            contrastText: '#fff',
        },
    },
}));

const App = () => {
    return (
        <>
            <ConfigProvider>
                <MetaDataProvider>
                    <ConfirmProvider>
                        <InfoDialogProvider>
                            <AppProvider>
                                <JourneyProvider>
                                    <Core/>
                                </JourneyProvider>
                            </AppProvider>
                        </InfoDialogProvider>
                    </ConfirmProvider>
                </MetaDataProvider>
            </ConfigProvider>
        </>
    );
};

const Core = () => {
    const {initialLoadEmpty} = useContext(AppContext);
    const {config} = useContext(ConfigContext);
    const {infoDialog} = useContext(InfoDialogContext);

    const updateTheme = () => {
        const smoothTheme = 'noto-theme-smooth';

        // Set theme to body
        if (config.smoothOperator && !document.body.classList.contains(smoothTheme)) {
            document.body.classList.add(smoothTheme);
        } else {
            document.body.classList.remove(smoothTheme);
        }
    };

    useEffect(() => {
        catchPwaInstall();
    }, []);

    useEffect(() => {
        updateTheme();
    }, [config.smoothOperator]);

    useEffect(() => {
        if (initialLoadEmpty) {
            infoDialog(<IntroductionContent/>, "Let's go!");
        }
    }, [initialLoadEmpty]);

    return (
        <React.Fragment>
            <CssBaseline/>
            <NoteFormProvider>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <SnackbarProvider
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            maxSnack={5}
                        >
                            <div className="App">
                                <Notice/>
                                <ConfirmDialog/>
                                <InfoDialog/>
                                <CardFocusDialog/>
                                <NoteFormDialog/>
                                <Presence/>
                                <Screen/>
                                <BottomNav/>
                            </div>
                        </SnackbarProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </NoteFormProvider>
        </React.Fragment>
    );
};

export default App;
