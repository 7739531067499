export type CardBack = 'choco'|
'festive-melon'|
'retro-stripe'|
'rainy_cafe'|
'sunset'|
'default'|
'tome'|
'vhs'|
'chains'|
'autumn'|
'cargo'|
'terminal'|
'sakura'

type CardBackBorderColors = {
    left: string
}

// Mapping for getting edge colors of card backs
const CardBackBorderColorsMap = {
    'choco': {left: '#2A160B'},
    'festive-melon': {left: '#0B584A'},
    'retro-stripe': {left: '#554493'},
    'rainy_cafe': {left: '#5F6279'},
    'sunset': {left: '#2C0056'},
    'default': {left: '#393939'},
    'tome': {left: '#0D2532'},
    'vhs': {left: '#E7E7E7'},
    'chains': {left: '#757575'},
    'autumn': {left: '#45322D'},
    'cargo': {left: '#FC4083'},
    'terminal': {left: '#272636'},
    'sakura': {left: '#B0E9EB'},
}

export function cardBackBorderColors(cardBack: CardBack): CardBackBorderColors {
    return CardBackBorderColorsMap[cardBack]
}