import React, {useMemo, useState, useCallback, useContext, useEffect} from 'react';

import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import * as Yup from "yup";
import {withFormik} from "formik/dist/index";

import {notify} from "../Notice";
import {Card as CardClass} from "../../Structures/Card";
import TagField, {tagStructure, updateTagsArray, updateTags} from "../../Components/Forms/TagField";
import {tagDataForCard} from "../../Structures/Tag";
import CardBackSelector from "../CardBackSelector";
import InsertField from "./InsertField";
import AppContext from "../../Contexts/App.context.js";
import NoteFormContext from "./Contexts/NoteForm.context";
import ConfirmContext from "../../Contexts/Confirm.context";

// <editor-fold desc="Note Form with Formik">
// Dialog containing NoteForm
function NoteFormDialog() {
    const {
        open,
        card,
        collection,
        tagsData,
        onSuccess,
        unsavedChanges,
        cardBack,
        closeDialogue,
        setUnsavedChanges,
        setCardBack
    } = useContext(NoteFormContext);

    const {confirm} = useContext(ConfirmContext);

    const [doClose, setDoClose] = useState(false);

    // Listen for close 'cue' to then do close with access to context state
    useEffect(() => {
        if (doClose) {
            setDoClose(false);
            handleClose();
        }
    }, [doClose]);

    const onClose = () => setDoClose(true);

    // Close dialog box
    const handleClose = () => {
        // Check for unsaved changes before closing
        if (unsavedChanges) {
            confirm(() => {
                closeDialogue()
            }, 'You have unsaved changes. Are you sure you want to close without saving?', 'Unsaved Changes');

        } else {
            closeDialogue()
        }
    };

    let title = card ? 'Edit Card' : 'New Card';
    const classes = [
        'NoteDialog',
        !card ? 'newCard' : null
    ];

    const initialCardBack = card && card.back ? card.back : 'default';

    // Dialogue extends Modal, so we can use Modal props (https://v4.mui.com/api/modal/)
    return (
        <>
            <Dialog
                className="NoteDialogRoot DialogOverride"
                open={open}
                onClose={onClose}
                classes={{
                    'paper': classes.join(' '),
                    'container': 'NoteFormContainer'
                }}
                disablePortal
                BackdropProps={{
                    'children': open &&
                    <CardBackSelector
                        initialCardBack={initialCardBack}
                        autoDraw={!card}
                        setCardBack={setCardBack}
                        setUnsavedChanges={setUnsavedChanges}
                    />
                }}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    {/*Send closeDialog prop to allow dialog state to be updated from the deeper*/}
                    <NoteForm
                        closeDialog={onClose}
                        onSubmit={onSuccess}
                        card={card}
                        collection={collection}
                        tagsData={tagsData}
                        setUnsavedChanges={setUnsavedChanges}
                        cardBack={cardBack}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}

// Formik HTML structure
const NoteFormHtml = props => {
    const context = useContext(AppContext);

    // Simple MDE cursor position info
    const [cursorInfo, setCursorInfo] = useState(null);
    const getCursorInfoCallback = useCallback((position) => {
        setCursorInfo(position);
    }, []);

    // Simple MDE CodeMirror instance registry
    const [codemirrorInstance, setCodemirrorInstance] = useState(null);
    const getCmInstanceCallback = useCallback((editor) => {
        setCodemirrorInstance(editor);
    }, []);

    // Props
    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        isValid,
        isSubmitting,
        cardBack
    } = props;

    const isFormTouched = () => {
        return Object.keys(touched).length !== 0
    }

    let buttonText = props.card ? 'Update' : 'Create';

    let tagsData = props.tagsData.map(tag => tagStructure(tag));

    let unsavedChanges = (bool) => {
        // Update state of parent when changes are unsaved
        props.setUnsavedChanges(isValid || bool);
    };

    // Special handling for simpleMDE field which returns only a value and no key
    let simpleMDEHandleChange = (value) => {
        setFieldValue('content', value, true);
        setFieldTouched('content');

        unsavedChanges();
    };

    // Custom wrapper for setFieldValue to allow additional functions to be performed on update
    let onChange = (id, value) => {
        // Default behavior of onChange
        setFieldValue(id, value);
        setFieldTouched(id, true, true);

        unsavedChanges();
    };

    // Update or add Tags to be updated/saved
    let updateTagsData = (tag) => {
        setFieldValue('updatedTagsData', updateTagsArray(values.updatedTagsData, tag));
        setFieldTouched('updatedTagsData');

        unsavedChanges(true);
    };

    // Set cardBack from CardBackSelector when provided and different
    if (cardBack && cardBack !== values.cardBack) {
        setFieldValue('cardBack', cardBack);
        setFieldTouched('cardBack', true, true);
    }

    const SimpleMdeOptions = useMemo(() => {
        return {
            forceSync: true,
            spellChecker: false,
            toolbar: false,
            toolbarTips: false,
            status: false,
            placeholder: "Type something...",
            autoDownloadFontAwesome: false,
            minHeight: "400px",
            maxHeight: "400px",
            autofocus: true,
            shortcuts: {
                // https://github.com/sparksuite/simplemde-markdown-editor#keyboard-shortcuts
                toggleBlockquote: null,
                cleanBlock: null,
                toggleHeadingSmaller: null,
                togglePreview: null,
                toggleHeadingBigger: null,
                toggleSideBySide: null,
                toggleFullScreen: null
            }
        };
    }, []);

    // Insert functions
    const insertCardLink = (card_id) => {
        codemirrorInstance.setSelection(cursorInfo);
        codemirrorInstance.replaceSelection('<CardLink card_id="'+ card_id +'" content="sample_text"/>');

        // I don't know why, but something steals the focus from codeMirrorInstance
        // after setting it here
        // (this can be proven by adding
        // `onBlur={() => {console.log('Oh no, my focus has been stolen')}}` to the SimpleMDE component)
        // Using a timeout seems to be a workaround, so, I'll take it .___.
        setTimeout(() => {
            codemirrorInstance.focus();
            setFieldTouched('content', true, true);
        }, 1);

        notify('Card Link inserted');
    };

    const insertIcon = (icon, color, animation = null) => {
        codemirrorInstance.setSelection(cursorInfo);

        // Create 'short tag'
        let tag = `[:${icon}:${color}]`;
        if (animation) {
            tag = `[:${icon}:${color}:${animation}]`;
        }

        codemirrorInstance.replaceSelection(tag);

        // I don't know why, but something steals the focus from codeMirrorInstance
        // after setting it here
        // (this can be proven by adding
        // `onBlur={() => {console.log('Oh no, my focus has been stolen')}}` to the SimpleMDE component)
        // Using a timeout seems to be a workaround, so, I'll take it .___.
        setTimeout(() => {
            codemirrorInstance.focus();
            setFieldTouched('content', true, true);
        }, 1);

        notify('Icon inserted');
    };

    const insertTemplate = (card_id) => {
        codemirrorInstance.setSelection(cursorInfo);

        try {
            const card = context.cardByID(card_id);
            codemirrorInstance.replaceSelection(card.content);
            notify('Template inserted');
        } catch(e) {
            notify('Oops! Something went wrong inserting template');
        }

        // I don't know why, but something steals the focus from codeMirrorInstance
        // after setting it here
        // (this can be proven by adding
        // `onBlur={() => {console.log('Oh no, my focus has been stolen')}}` to the SimpleMDE component)
        // Using a timeout seems to be a workaround, so, I'll take it .___.
        setTimeout(() => {
            codemirrorInstance.focus();
            setFieldTouched('content', true, true);
        }, 1);
    };

    const insertBadge = (icon, color, animation = null, content = 'sample text', dark = false) => {
        codemirrorInstance.setSelection(cursorInfo);
        codemirrorInstance.replaceSelection(`<Badge icon="${icon}" color="${color}" animation="${animation}" content="${content}" ${dark ? 'dark="true"' : ''}/>`);

        // I don't know why, but something steals the focus from codeMirrorInstance
        // after setting it here
        // (this can be proven by adding
        // `onBlur={() => {console.log('Oh no, my focus has been stolen')}}` to the SimpleMDE component)
        // Using a timeout seems to be a workaround, so, I'll take it .___.
        setTimeout(() => {
            codemirrorInstance.focus();
            setFieldTouched('content', true, true);
        }, 1);

        notify('Badge inserted');
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormControl fullWidth error={errors.content && touched.content && true}>
                <SimpleMDE
                    id="content"
                    value={values.content}
                    onChange={simpleMDEHandleChange}
                    options={SimpleMdeOptions}
                    getCodemirrorInstance={getCmInstanceCallback}
                    getLineAndCursor={getCursorInfoCallback}
                    autoFocus
                />
                <FormHelperText className="fieldDesc">
                    {errors.content && touched.content ? errors.content : null}
                </FormHelperText>
            </FormControl>

            <InsertField
                insertCardLink={insertCardLink}
                insertIcon={insertIcon}
                insertTemplate={insertTemplate}
                insertBadge={insertBadge}
            />
            <FormHelperText className="fieldDesc">Insert</FormHelperText>

            <TagField
                id="tags"
                description="Tags"
                onChange={onChange}
                tagsData={tagsData}
                updateTagsData={updateTagsData}
                value={values.tags}
            />

            { props.collection &&
                <FormControlLabel
                    control={
                        <Checkbox
                            id="belongsToCollection"
                            checked={values.belongsToCollection}
                            value="belongsToCollection"
                            onChange={(event) => onChange('belongsToCollection', event.target.checked)}
                            color='secondary'
                        />
                    }
                    label={values.belongsToCollection ?
                        "Belongs to this collection" :
                        "Does NOT belong to this collection"}
                />
            }

            <FormControlLabel
                control={
                    <Checkbox
                        id="isTemplate"
                        checked={values.isTemplate}
                        value="isTemplate"
                        onChange={(event) => onChange('isTemplate', event.target.checked)}
                        color='secondary'
                    />
                }
                label="Add to templates"
            />

            <DialogActions>
                <Button disabled={isSubmitting} onClick={props.closeDialog} color='inherit'>
                    Cancel
                </Button>
                <Button type="submit" color="primary" variant="outlined" disabled={isSubmitting || !isValid || !isFormTouched()}>
                    {buttonText}
                </Button>
            </DialogActions>
        </form>
    );
};

// Use Formik on some pre-defined Formik HTML
const NoteFormHandling = withFormik({
    // Initial form values (handles field population when updating Card)
    mapPropsToValues: props => ({
        content: props.card ? props.card.content : '',
        tags: props.card ? tagDataForCard(props.tagsData, props.card.tags).map(tag => tagStructure(tag)) : [],
        updatedTagsData: [],
        belongsToCollection: belongsToCollection(props.collection, props.card),
        isTemplate: props.card ? !!props.card.is_template : false,
        cardBack: props.card && props.card.back ? props.card.back : 'default',
    }),

    validationSchema: Yup.object().shape({
        content: Yup.string()
            .required('Content is required!')
    }),

    handleSubmit: (values, formikBag) => {
        // Card is being updated if exists, else false
        let update = !!formikBag.props.card;

        // Supplied card or new one
        const card = formikBag.props.card || new CardClass();

        // Update data
        card.content = values.content;
        // A Card only saves tag strings, so prepare data for that (essentially storing 'IDs')
        card.tags = values.tags ? values.tags.map(function(tag) {
            // Convert objects into strings
            return tag.value;
        }) : [];

        // Card Back
        card.back = values.cardBack;

        // Update card.belongs_to_collections
        // If collection supplied
        if (formikBag.props.collection) {
            // Index of collection ID in card.belongs_to_collections
            let collectionIDIndex = card.belongs_to_collections.indexOf(formikBag.props.collection.ID);

            // If card belongs and isn't listed as such
            if (values.belongsToCollection && collectionIDIndex === -1) {
                // Add ID
                card.belongs_to_collections.push(formikBag.props.collection.ID);
            }

            // If doesn't belong but listed as such
            if (!values.belongsToCollection && collectionIDIndex !== -1) {
                // Remove ID
                card.belongs_to_collections.splice(collectionIDIndex, 1);
            }
        }

        card.is_template = values.isTemplate;

        // Save updates to Tags
        updateTags(values.updatedTagsData);

        card.save().then(() => {
            formikBag.resetForm();
            formikBag.setSubmitting(false);

            // Reset unsavedChanges state in parent to prevent confirm dialogue on save
            formikBag.props.setUnsavedChanges(false);

            // Close the form using supplied function
            formikBag.props.closeDialog(); // Maps to handleClose() in NoteDialog
            notify(update ? 'Card updated' : 'Card created');

            // Execute passed through onSuccess function
            if (update) {
                card.updated_at = new Date(); // Set updated_at
                formikBag.props.onSubmit(card, true); // Maps to update (in Card.tsx)
            } else {
                formikBag.props.onSubmit(); // Maps to loadCards from App context
            }

        }).catch(function (err) {
            console.log(err);
        });
    },

    displayName: 'NoteForm'
});

// Use Formik on some pre-defined Formik HTML
const NoteForm = NoteFormHandling(NoteFormHtml);
// </editor-fold>

// Determine value for belongsToCollection
//
// @param collection
// @param card
//
// @returns bool
function belongsToCollection(collection, card) {
    // No collection, can't belong to a collection that doesn't exist
    if (null === collection) {return false;}

    // Collection and new card (card null)
    if (null !== collection && null === card) {return true;}

    // Does card list this collection as one it belongs to?
    return card.belongs_to_collections.reduce(function(belongs, collectionID) {
        return belongs || collectionID === collection.ID;
    }, false);
}

export default NoteFormDialog;