import React, {useContext} from 'react';

import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import {Droppable, Draggable} from "react-beautiful-dnd";
import {VariableSizeList as List} from 'react-window';
import Card from './Card';
import AutoSizer from "react-virtualized-auto-sizer";
import ColumnFormContext from "./Forms/Contexts/ColumnForm.context";

// Card initial height + margin
const margin = 12;
const cardDefaultHeight = 400;
export const defaultHeight = cardDefaultHeight + margin;

export default function BoardColumn(props) {
    const {popColumnFormDialog} = useContext(ColumnFormContext);
    // Return height of Card at index
    const getItemSize = () => {
        return defaultHeight;
        // NOTE: Size checking is, for the moment, removed.
        // - In the future there may be a 'folding' feature, where Card size may return.
        // - Size or 'folds' are expected to be stored with Card data and will therefore
        // allow calculation of size based on the data.
    };

    // Array mappable draggable Card wrapper
    // Basic code example: https://react-window.vercel.app/#/examples/list/fixed-size
    //
    // @param bool {expanded} Bool determining if the Card renders opened/closed
    // @param array {tags} Global tagsData
    // @param int {index} Index of position in Column
    // @param {style} Styles required by drag'n'drop library
    // @param {data} Card data
    const draggableCard = ({index, style, data}) => {
        // Assign data from react-window List
        let card = data[index];

        // Check Card object
        if (!card.ID) {return false}

        return (
            <Draggable draggableId={card.ID + ''} index={index} key={'draggableCard' + card.ID}>
                {(provided, snapshot) => (
                    <div className={"draggableCard"}
                         style={style}
                         ref={provided.innerRef}
                    >
                        <Card
                            key={card.ID}
                            card={card}
                            tags={props.tags}
                            isDragging={snapshot.isDragging}
                            draggableProps={provided.draggableProps}
                            dragHandleProps={provided.dragHandleProps}
                            // expanded={expanded} // This used to be calculated by checking if the Card's reported size was bigger than it's default size,
                            // now that we've removed this over-complicated size reporting, 'expanded' is left unaccounted for meaning that on drag the expanded
                            // state won't be retained
                            collection={null}
                            grid={false}
                        />
                    </div>
                )}
            </Draggable>
        );
    };

    return (
        <Draggable
            draggableId={'draggableColumn' + props.column.ID}
            index={props.columnIndex}
            key={'draggableColumn' + props.column.ID}
        >
            {(provided, snapshot) => (
                <Grid
                    className={"BoardColumnWrapper" + (snapshot.isDragging ? ' dragging' : '' )}
                    key={props.index}
                    item
                >
                    <div className={"BoardColumn"} ref={provided.innerRef} {...provided.draggableProps}>
                        <ListItem className={"ColumnTop"}>
                            <ListItemText primary={props.column.title} {...provided.dragHandleProps}/>
                            <IconButton
                                aria-label="Delete"
                                onClick={() => {
                                    props.deleteColumn(props.column)
                                }}
                                size="large">
                                <DeleteIcon/>
                            </IconButton>
                            <IconButton
                                aria-label="Edit"
                                onClick={() => {
                                    popColumnFormDialog(() => props.saveColumn, props.column, props.board)
                                }}
                                size="large">
                                <EditIcon/>
                            </IconButton>
                        </ListItem>

                        <div className={"BoardColumnCardsWrapper"}>
                            <AutoSizer>
                                {({ height, width }) => (
                                    <Droppable
                                        droppableId={'ColumnDroppable' + props.column.ID}
                                        type='CARD'
                                        mode="virtual"
                                        renderClone={(provided, snapshot, rubric) => (
                                            <div {...provided.draggableProps} >
                                                <Card
                                                    key={props.orderedColumnCards[rubric.source.index].ID}
                                                    card={props.orderedColumnCards[rubric.source.index]}
                                                    tags={props.tags}
                                                    isDragging={snapshot.isDragging}
                                                    dragHandleProps={provided.dragHandleProps}
                                                    // expanded={???} -> See line: 63 in draggableCard()
                                                    collection={null}
                                                    grid={false}
                                                />
                                            </div>
                                        )}
                                    >
                                        {(provided) => (
                                            <>
                                                <List
                                                    className={"BoardColumnCards"}
                                                    height={height}
                                                    itemCount={props.orderedColumnCards.length}
                                                    itemData={props.orderedColumnCards}
                                                    itemSize={getItemSize}
                                                    width={width}
                                                    outerRef={provided.innerRef}
                                                    {...provided.droppableProps}
                                                >
                                                    { draggableCard }
                                                </List>
                                            </>
                                        )}
                                    </Droppable>
                                )}
                            </AutoSizer>
                        </div>
                    </div>
                </Grid>
            )}
        </Draggable>
    );
}