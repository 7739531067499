import React from 'react';

import IconButton from '@mui/material/IconButton';
import BackIcon from '@mui/icons-material/ArrowBack';
import TopBar from '../../Universal/TopBar';

function JourneyTopBar({journey, searchCards, close}) {
    return (
        <TopBar
            title={journey.title}
            className='CollectionTopBar'
            menuButton={(
                <IconButton onClick={close} size="large">
                    <BackIcon />
                </IconButton>
            )}
            searchFunction={searchCards}
            disableGutters
        />
    );
}

export default JourneyTopBar;