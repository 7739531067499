// Export className for merit value
// @param {int} merit
//
// @returns string
export function cardValueLabel(merit) {
    if (merit === 0) {return 'tier_0'}
    if (merit <= 5) {return 'tier_1'}
    if (merit <= 10) {return 'tier_2'}
    if (merit <= 15) {return 'tier_3'}
    if (merit <= 25) {return 'tier_4'}
    if (merit <= 35) {return 'tier_5'}
    if (merit <= 50) {return 'tier_6'}
    if (merit <= 75) {return 'tier_7'}
    if (merit <= 100) {return 'tier_8'}
    if (merit <= 125) {return 'tier_9'}
    if (merit <= 150) {return 'tier_10'}
    if (merit > 150) {return 'tier_11'}
}