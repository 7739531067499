import React from 'react';
import { ListItemButton, Tooltip } from '@mui/material';
import { dateFormat, dateDistanceFormat, sameDay } from '../../../Helpers/dateHelper';
import {cardValueLabel} from "../../../Components/CardValue";
import { randomMessage } from '../../../Helpers/tools';

export function JourneyListItem ({name, onClick, dates, merit}) {
    if (dates.length > 0) {
        const start = dates[0]
        const end = dates.slice(-1)[0]
        const startDate = new Date(start)
        const endDate = new Date(end)
        const dateEqual = sameDay(startDate, endDate)

        return (
            <ListItemButton className={'JourneyListItem ' + cardValueLabel(merit)} onClick={() => {onClick()}}>
                <div className='JourneyHead'>
                    <div className='JourneyTitle'>{name}</div>
                    { merit > 0 &&
                        <div className='JourneyMerit'>
                            <Tooltip title="Merit" placement="left" enterDelay={500} leaveDelay={200}>
                                <div className="meritBanner">{merit}</div>
                            </Tooltip>
                        </div>
                    }
                </div>
                <div className='Timeline'>
                    <div className='StartEnd'>{dateFormat(startDate)}</div>
                    { !dateEqual &&
                        <div className='JourneyLength'>{dateDistanceFormat(startDate, endDate)}</div>
                    }
                    <div className='StartEnd'>{dateFormat(endDate)}</div>
                </div>
            </ListItemButton>
        )
    }

    return (
        <ListItemButton className='JourneyListItem' onClick={() => {onClick()}}>
            <div className='JourneyHead'>
                <div className='JourneyTitle'>{name}</div>
            </div>
            <div className='Timeline empty'>
            <div className='JourneyLength'>{randomMessage('empty_journey')}</div>
            </div>
        </ListItemButton>
    )
}