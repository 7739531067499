import { createContext, useState } from 'react';

const CollectionFormContext = createContext();

const CollectionFormProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [collection, setCollection] = useState(null);
    const [tagsData, setTagsData] = useState([]);
    const [onSuccess, setOnSuccess] = useState(undefined);

    const popCollectionFormDialog = (onSuccessFn = function() {}, collection = null, tags = []) => {
        if (!(onSuccessFn instanceof Function)) {
            throw new Error('Parameter "onSuccessFn" is not a function');
        }

        if (collection !== null && !(typeof collection === 'object')) {
            throw new Error('Parameter "collection" is not an Object OR null');
        }

        setCollection(collection);
        setTagsData(tags);
        setOnSuccess(onSuccessFn);
        setOpen(true);
    };

    const closeDialogue = () => {
        setOpen(false);
        setCollection(null);
        setTagsData([]);
        setOnSuccess(undefined);
    };

    return (
        <CollectionFormContext.Provider value={{
            open,
            collection,
            tagsData,
            closeDialogue,
            onSuccess,
            popCollectionFormDialog
        }}>
            {children}
        </CollectionFormContext.Provider>
    );
};

export {CollectionFormProvider}
export default CollectionFormContext