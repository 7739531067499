import { useContext } from 'react';
import ConfigContext from "../Contexts/Config.context";
import MetaDataContext from "../Contexts/MetaData.context";
import ConfirmContext from '../Contexts/Confirm.context';
import {exportAppData} from '../Structures/Export';

export function useBackupPrompt() {
    const {config} = useContext(ConfigContext);
    const {metaData, setLastPromptedToExportAt} = useContext(MetaDataContext);
    const {confirm} = useContext(ConfirmContext);

    return () => {
        // Only runs if config enabled
        if (config.exportPrompts) {
            const now = new Date().getTime();
            const lastPromptedToExportAt = metaData.lastPromptedToExportAt.getTime();
            const oneDay = 86400000;
            const sevenDays = 7 * oneDay;

            const lastPromptedOneWeekAgo = now > (lastPromptedToExportAt + sevenDays);

            if (lastPromptedOneWeekAgo) {
                confirm(() => {
                    exportAppData();
                }, "You haven't backed up your data in a while, would you like to export it?", "Let's back up your data");

                setLastPromptedToExportAt(new Date());
            }
        }
    };
}