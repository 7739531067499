import React from 'react';

import {CollectionListItem} from "./CollectionListItem";

// Abstracted as recommended by (https://github.com/atlassian/react-beautiful-dnd#recommended-droppable-performance-optimisation)
// for performance optimization
export function CollectionList(props) {
    return (
        props.collections.map((collection, index) => (
            <CollectionListItem
                key={index}
                collection={collection}
                tags={props.tags}
                index={index}
                toggleDisplay={props.toggleCollectionDisplay}
                saveCollection={props.saveCollection}
                deleteCollection={props.deleteCollection}
            />
        ))
    );
}