import React, {Component} from 'react';

import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Select from 'react-select';

class FilterField extends Component {
    constructor(props) {
        super(props);

        // Load options
        this.options = [
            {value: null, label: 'Retired', property: 'retired_at', evaluation: '!='},
            {value: null, label: 'Not retired', property: 'retired_at', evaluation: '='},
        ];
    }

    handleChange = value => {
        // Calls setFieldValue and manually updates values.tags
        this.props.onChange(this.props.id, value);
    };

    render() {
        return (
            <React.Fragment>
                <InputLabel className="selectLabel" htmlFor={this.props.id}>{this.props.label}</InputLabel>
                <Select
                    id={this.props.id}
                    placeholder="Select..."
                    options={this.options}
                    menuPosition={'fixed'}
                    isMulti
                    isSearchable
                    onChange={this.handleChange}
                    value={this.props.value}
                />
                <FormHelperText>
                    {this.props.description}
                </FormHelperText>
            </React.Fragment>
        );
    }
}

export default FilterField;