import React, { useContext } from 'react'

import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'

import * as Yup from "yup"
import {withFormik} from "formik/dist/index"

import FilterField from "./FilterField"
import {newFilterData} from "../../Structures/Collection"
import {JourneyClass as Journey} from '../../database/models/Journey'
import TagField, {updateTags, tagStructure, updateTagsArray} from "./TagField"
import {tagDataForFilter} from "../../Structures/Tag"
import JourneyFormContext from "./Contexts/JourneyForm.context"

function JourneyFormDialog() {
    const {
        open,
        closeDialogue,
        journey,
        tagsData,
        onSuccess
    } = useContext(JourneyFormContext)

    // Close dialog box
    const handleClose = () => {
        closeDialogue()
    }

    const title = journey ? 'Edit Journey' : 'New Journey'

    return (
        <Dialog
            className="CollectionDialog DialogOverride"
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <JourneyForm
                    closeDialog={handleClose}
                    onSubmit={onSuccess}
                    journey={journey}
                    tagsData={tagsData}
                />
            </DialogContent>
        </Dialog>
    )
}

// Formik HTML structure
const JourneyFormHtml = props => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isValid,
        isSubmitting,
    } = props

    let buttonText = props.journey ? 'Update' : 'Create'

    let tagsData = props.tagsData.map(tag => tagStructure(tag))

    // Update or add Tags to be updated/saved
    let updateTagsData = (tag) => {
        setFieldValue('updatedTagsData', updateTagsArray(values.updatedTagsData, tag))
    }

    return (
        <form onSubmit={handleSubmit}>
            <FormControl className="fieldContainer" fullWidth error={errors.title && touched.title && true}>
                <InputLabel variant="standard" htmlFor="content">Label</InputLabel>
                <Input
                    id="title"
                    type="text"
                    value={values.title}
                    placeholder="Type something..."
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                />
                <FormHelperText>
                    {errors.title && touched.title ? errors.title : 'Label shown on journey'}
                </FormHelperText>
            </FormControl>

            <FormControl className="fieldContainer" fullWidth>
                <TagField
                    id="tagFilterOptions"
                    label="Tags"
                    description="Select or add tags to filter by"
                    onChange={setFieldValue}
                    tagsData={tagsData}
                    updateTagsData={updateTagsData}
                    value={values.tagFilterOptions}
                />
            </FormControl>
            <FormControl className="fieldContainer" fullWidth>
                <FilterField
                    id="filterOptions"
                    label="Filters"
                    description="Additional filtering options"
                    onChange={setFieldValue}
                    value={values.filterOptions}
                />
            </FormControl>
            <FormControl className="fieldContainer" fullWidth>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="inclusiveFilter"
                            checked={values.inclusiveFilter}
                            value="inclusiveFilter"
                            onChange={handleChange}
                            color='secondary'
                        />
                    }
                    label={values.inclusiveFilter ?
                        "Inclusive filtering (has ANY option)" :
                        "Exclusive filtering (must have ALL options)"}
                />
            </FormControl>

            <DialogActions>
                <Button disabled={isSubmitting} onClick={props.closeDialog} color='inherit'>
                    Cancel
                </Button>
                <Button type="submit" color="primary" variant="outlined" disabled={isSubmitting || !isValid}>
                    {buttonText}
                </Button>
            </DialogActions>
        </form>
    )
}

// Use Formik on some pre-defined Formik HTML
const JourneyFormHandling = withFormik({
    // Initial form values (handles field population when updating Journey)
    mapPropsToValues: props => ({
        title: props.journey ? props.journey.title : '',

        tagFilterOptions: props.journey ? tagDataForFilter(props.tagsData, props.journey.filters)
            .filter(filter => filter.property === 'tags') // Limit to tag related filters
            .map(filter => tagStructure(filter)) : [],

        filterOptions: props?.journey?.filters ? props.journey.filters
            .filter(filter => filter.property !== 'tags') // Limit to filters that aren't tag related
            .map(filter => tagStructure(filter)) : [],

        updatedTagsData: [],
        inclusiveFilter: props.journey ? props.journey.inclusiveFilter : true,
    }),
    validationSchema: Yup.object().shape({
        title: Yup.string()
            .required('Label is required!')
    }),
    handleSubmit: (values, formikBag) => {
        // Convert nulls to empty arrays (prevents error during mapping)
        if (values.tagFilterOptions === null) { values.tagFilterOptions = [] }
        if (values.filterOptions === null) { values.filterOptions = [] }

        // Map selected tags to new filters
        let tagFilters = values.tagFilterOptions.map((tag) => {
            // Add missing fields to new tags
            // - will add property, evaluation
            tag = tagStructure(tag)

            return newFilterData(tag.property, tag.value, tag.evaluation, tag.label)
        })

        // Map selected filter options to new filters
        let filters = values.filterOptions.map((opt) => {
            return newFilterData(opt.property, opt.value, opt.evaluation, opt.label)
        })

        // Merge filter options into one array (to be processed as Filters later on)
        // - Originally, all filters were managed together, for better UI and consistency, tags and other filters were separated
        // Note: Confirming values are arrays isn't required because we ensure this a few lines up
        filters = tagFilters.concat(filters)

        // Supplied journey or new one
        const journey = formikBag.props.journey || new Journey(
            values.title,
            filters
        )

        // Save updates to Tags
        updateTags(values.updatedTagsData)

        // Update data
        journey.title = values.title
        journey.filters = filters
        journey.inclusiveFilter = values.inclusiveFilter

        // Execute passed through onSuccess function
        formikBag.props.onSubmit(journey).then(() => {
            formikBag.resetForm()
            formikBag.setSubmitting(false)

            // Close the form using supplied function
            formikBag.props.closeDialog()
        }).catch(function (err) {
            console.log(err)
        })
    },
    displayName: 'JourneyForm'
})

// Use Formik on some pre-defined Formik HTML
const JourneyForm = JourneyFormHandling(JourneyFormHtml)

export default JourneyFormDialog