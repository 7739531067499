import React, {useContext} from 'react';
import AppContext from '../../Contexts/App.context.js';
import {cardValueLabel} from '../CardValue.js';

export default function CardLink({card_id, content}) {
    const context = useContext(AppContext);
    const linkedCard = context.cards.find(card => card.ID == card_id);
    // Prevent errors if Card doesn't exist; like when it's been deleted
    if (!linkedCard) return <button className={'CardLink deadLink'}>{ content }</button>;

    const action = () => {
        context.updateLinkedCard(linkedCard);
    };

    const classes = [
        'CardLink',
        !context.linkedCard.cleared && context.linkedCard.card && card_id == context.linkedCard.card.ID ? 'active' : null,
        !content ? 'empty' : null,
        cardValueLabel(linkedCard.merit),
        linkedCard.back
    ];
    
    return <button className={classes.join(' ')} onClick={action}>{ content }</button>;
}