import localforage from "localforage";


// A Noto User's custom configuration, settings and preferences
export class Config {
    stealthMode: boolean; // When enabled, presence does not function
    cardWear: boolean; // When enabled, cards accumulate and show visable wear
    smoothOperator: boolean; // When enabled, Noto Smooth Theme is active
    exportPrompts: boolean; // When enabled, Noto will prompt the user to export their data regularly to promote backup keeping

    constructor() {
        this.stealthMode = false
        this.cardWear = true
        this.smoothOperator = true
        this.exportPrompts = true
    }
}


export function saveConfig(config: Config) {
    return localforage.setItem('config', config).catch(function(err) {
        throw new Error(err);
    });
}


export function loadConfig(): Promise<Config> {
    return localforage.getItem('config').then((result) => {
        if (!result) {
            return new Config();
        }
        return Object.assign(new Config(), result);

    }).catch(function(err) {
        console.log(err);
        return new Config();
    });
}