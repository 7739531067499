import React, {createContext, useContext} from 'react';
import { createJourney, updateJourney, deleteJourney } from '../database/controllers/Journey'
import { updateListOrder } from '../database/controllers/ListOrder'
import {notify} from "../Components/Notice"
import ConfirmContext from './Confirm.context'

const JourneyContext = createContext();

const JourneyProvider = ({ children }) => {
    const {confirm} = useContext(ConfirmContext)

    const handleCreateJourney = (journey) => {
        return createJourney(journey).then((success) => {
            if (success) {
                notify('Journey created')
            } else {
                notify('Error creating Journey')
            }
        })
    }

    const handleUpdateJourney = (journey) => {
        return updateJourney(journey).then((success) => {
            if (success) {
                notify('Journey updated')
            } else {
                notify('Error updating Journey')
            }
        })
    }
    
    const handleDeleteJourney = (journey) => {
        confirm(() => deleteJourney(journey.id).then((success) => {
            if (success) {
                notify('Journey deleted')
            } else {
                notify('Error deleting Journey')
            }
        }), 'Do you want to delete this Journey?', 'Delete Journey')
    }

    const handleToggleJourneyDisplay = (journey) => {
        journey.display = !journey.display
        updateJourney(journey)
    }

    const handleReorderJourneys = (orderedJourneyIds) => {
        updateListOrder({listName: 'journeys', order: orderedJourneyIds})
    }

    return (
        <JourneyContext.Provider value={{
            handleCreateJourney,
            handleUpdateJourney,
            handleDeleteJourney,
            handleToggleJourneyDisplay,
            handleReorderJourneys
        }}>
            {children}
        </JourneyContext.Provider>
    );
};

export {JourneyProvider}
export default JourneyContext