import localforage from "localforage";

// <editor-fold desc="Column">

// A Column is an arrangement of Cards
//
// @param title {string}
export class Column {
    constructor(title = null) {
        this.ID = null;

        this.title = title;
        // ID of parent Board that this Column belongs to
        this.boardID = null;
        // IDs of Cards that belong to this Column
        this.cards = [];
    }
}

//<editor-fold desc="Column Management Helpers">

// Handle auto incrementing IDs
//
// @return {Promise -> Int} Evaluates to incremented ID
function generateID() {
    return localforage.getItem('columnIdIncrementer').then(function (ID) {
        // Increment cardIdIncrementer and return
        return localforage.setItem('columnIdIncrementer', ID + 1)
            .then((result) => {return result});

    }).catch(function(error) {
        console.log(error);
    });
}
//</editor-fold>

//<editor-fold desc="Columns">

// Recursively generate and set IDs where not set
//
// @param columns {Array}
//
// @returns Promise -> Array
function generateIDs(columns, i = 0) {
    // Return on last collection
    if (i === columns.length) { return Promise.resolve(columns); }

    // If no ID
    if (columns[i].ID === null) {
        // generate ID
        return generateID().then((id) => {
            columns[i].ID = id;
            // Move on to next iteration
            return generateIDs(columns, i + 1);
        });
    }
    // Move on to next iteration
    return generateIDs(columns, i + 1);
}

// Save Columns to storage with name
//
// @param columns {array} Array of Column class objects to save
//
// @returns Promise
export function saveColumns(columns) {
    if (!Array.isArray(columns)) {
        throw new Error('Supplied parameter "columns" is not an array.');
    }

    return generateIDs(columns).then((columns) => {
        // Save
        return localforage.setItem('columns', columns).catch(function(err) {
            throw new Error(err);
        });
    });
}

// Get Columns from storage by name
//
// @returns Promise -> Array
export function loadColumns() {
    return localforage.getItem('columns').then((result) => {
        // Make sure an array is returned
        if (Array.isArray(result)) {
            return result.map(function(columns) {
                return Object.assign(new Column(), columns)
            });
        }
        return [];
    }).catch(function(err) {
        console.log(err);
        return [];
    });
}

// Delete
//
// @param id {int} ID of the columns to delete
//
// @returns {Promise} Promise to delete the columns, returns columns
export function deleteColumn(id) {
    // Get existing collections
    return loadColumns().then(columns => {
        // Target index
        let targetColumn = columns.indexOf(
            // Find by ID
            columns.find((column) => {
                return column.ID === id;
            })
        );

        // If index found
        if (targetColumn > -1) {
            // Remove
            columns.splice(targetColumn, 1);

            // Set columns data to storage
            return saveColumns(columns);
        }

        throw new Error('Couldn\'t find Column by ID to delete.');
    });
}

// Delete multiple
//
// @param id {array} Array of IDs of the columns to delete
//
// @returns {Promise} Promise to delete the columns, returns columns
export function deleteColumns(ids) {
    if (!Array.isArray(ids)) {return false;}

    // Get existing collections
    return loadColumns().then(columns => {
        // Filter out columns for deletion
        columns = columns.filter((column) => {
            // Return only columns whose ID is not in the ids array
            return !ids.includes(column.ID);
        });

        // Set columns data to storage
        return saveColumns(columns);
    });
}
//</editor-fold>