import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import Input from '@mui/material/Input';
import Tooltip from '@mui/material/Tooltip';
import {faIconRefs} from "../../Helpers/faIconRefs";
import {searchIconsArray} from "../../Helpers/search";
import { TwitterPicker } from 'react-color'
import {colorSwatches} from "../../Helpers/colorSwatches";
import {EmptyMessage} from "../../Helpers/tools";
import Select from 'react-select';
import { FixedSizeGrid as Grid } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import FormHelperText from '@mui/material/FormHelperText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useSearchTerm } from '../../hooks/useSearchTerm';

const MemoizedIconSelectField = React.memo(IconSelectField, propsAreEqual);

function propsAreEqual(prev, next) {
    return prev.open === next.open;
}

function IconSelectField({open, setOpen, insertIcon}) {
    const defaultColor = '#4a4a4a';
    const [searchTerm, updateSearchTerm, setSearchTerm] = useSearchTerm();
    const [icon, setIcon] = useState(null);
    const [color, setColor] = useState(defaultColor);
    const [animation, setAnimation] = useState(null);


    const handleClose = () => {
        setOpen(false);
        setIcon(null);
        setSearchTerm('');
        setColor(defaultColor); // reset color
        setAnimation(null); // reset animation
    };

    const renderIcons = () => {
        const filteredIcons = searchIconsArray(faIconRefs, searchTerm);

        if (filteredIcons.length === 0) {
            return <EmptyMessage/>
        }

        // Output in virtualized Grid
        return (
            <AutoSizer>
                {({height, width}) => (
                    <IconGrid
                        height={height}
                        width={width}
                        icons={filteredIcons}
                        handleClick={setIcon}
                    />
                )}
            </AutoSizer>
        )
    };

    const handleSubmit = () => {
        insertIcon(icon, color, animation ? animation.value : null);
        handleClose();
    };

    const animationOptions = [
        { value: '', label: 'None' },
        { value: 'beat', label: 'Beat' },
        { value: 'fade', label: 'Fade' },
        { value: 'beat-fade', label: 'Beat Fade' },
        { value: 'bounce', label: 'Bounce' },
        { value: 'shake', label: 'Shake' },
        { value: 'spin', label: 'Spin' },
        { value: 'spin-pulse', label: 'Spin Staggered' },
    ];

    return (
        <Dialog open={open} onClose={handleClose} className={'IconSelectFieldDialog'}>
            <div className={'IconSelectField'} style={{color: color}}>
                { renderIcons() }
            </div>
            <Input
                className={"IconSelectSearch"}
                type="text"
                placeholder="Search..."
                onChange={(event) => updateSearchTerm(event.target.value)}
                autoFocus
            />
            <TwitterPicker
                triangle="hide"
                color={color}
                colors={colorSwatches}
                onChangeComplete={(c) => { setColor(c.hex) }}
                width="initial"
            />
            <div className="IconPreview">
                <span className={`fas fa-${icon} fa-${animation ? animation.value : ''}`} style={{color: color}}></span>
                <FormHelperText className="fieldDesc">Preview</FormHelperText>
            </div>
            <Select
                className="icon-animation-select"
                classNamePrefix="select"
                isSearchable={false}
                isClearable={false}
                menuPlacement={'top'}
                menuPosition={'fixed'}
                placeholder={'Select animation...'}
                value={animation}
                onChange={(anim) => setAnimation(anim)}
                name="animation"
                defaultValue={null}
                options={animationOptions}
            />
            <DialogActions>
                <Button onClick={handleClose} color='inherit'>
                    Cancel
                </Button>
                <Button color="primary" variant="outlined" onClick={handleSubmit} disabled={!icon}>
                    Insert
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const IconGrid = ({height, width, icons, handleClick}) => {
    const iconDimensions = 60; // Defined in css
    const columnCount = Math.floor(width / iconDimensions);
    const rowCount = Math.ceil(icons.length / columnCount);
    const margin = (width % iconDimensions);

    return (
        <Grid
            columnCount={columnCount}
            rowCount={rowCount}
            overscanCount={15}

            columnWidth={iconDimensions}
            rowHeight={iconDimensions}
            height={height}
            width={width - margin}
            itemData={{
                icons,
                columnCount,
                handleClick
            }}
            style={{
                margin: `0 ${margin / 2}px`
            }}
        >
            { IconCell }
        </Grid>
    )
};

const IconCell = ({ columnIndex, rowIndex, style, data }) => {
    const {icons, columnCount, handleClick} = data;
    const trueIndex = (rowIndex * columnCount) + columnIndex;
    const icon = icons[trueIndex];

    if (!icon) return null;

    return (
        <div style={style}>
            <Tooltip title={icon} placement="top" enterDelay={400} leaveDelay={0} key={trueIndex}>
                <div
                    className={'select-icon'}
                    onClick={() => { handleClick(icon) }}
                >
                    <span className={`fas fa-${icon}`}/>
                </div>
            </Tooltip>
        </div>
    )
};

export default MemoizedIconSelectField;