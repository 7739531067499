import React, {useContext} from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import ConfirmContext from '../Contexts/Confirm.context';

const ConfirmDialog = () => {
    const {open, handleClose, title, message, doAction} = useContext(ConfirmContext);

    const dialogTitle = title || 'Just to be sure...'
    
    return (
        <Dialog
            className="ConfirmationDialog DialogOverride"
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                {message}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color='inherit'>
                    Actually... no
                </Button>
                <Button
                    onClick={doAction}
                    color="primary"
                    variant="outlined"
                >
                    Do it!
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDialog;