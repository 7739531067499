

export interface OrderType {
    property?: string
    ascending: boolean
    comparison: string
    customComparison: (a, b) => number
}

// Order cards by supplied order specifications
//
// @param cards {array} Array of Card objects
// @param orders {array} Array of orders objects to apply
// example of order objects:
// {
//     property: 'created_at',
//     ascending: false,
//     comparison: 'date' // OPTIONAL (specify comparison function by string)
//     customComparison: function(a, b) { // OPTIONAL (custom comparison function)
//          // After
//          if (a > b) return 1;
//          // Before
//          if (a < b) return -1;
//          // Equal
//          return 0;
//      }
// },
// {
//     property: 'merit',
//     ascending: false
// }
//
// @returns {array} Array of cards ordered by supplied order specifications
export function orderCards(cards, orders) {
    // Order cards
    cards = cards.sort(function(cardA, cardB) {
        // Equal by default
        let position = 0;

        // Apply each supplied order specification, prioritised by array index
        orders.forEach((order) => {
            // Skip lower order specs if position already set
            // (prevents last order spec from overwriting all others)
            if (position !== 0) return;

            // Define vars for less repetition/length
            let a = cardA[order.property],
                b = cardB[order.property],
                asc = order.ascending;

            // Use custom comparison if provided
            if (order.hasOwnProperty('customComparison') && order.customComparison !== null) {
                position = order.customComparison(a, b);

            } else if (order.hasOwnProperty('comparison') && order.comparison === 'date') {
                position = compareDate(a, b);
            } else {
                // Determine position
                position = orderPosition(a, b);
            }

            // Apply direction (ascending/descending)
            position = (position * (asc ? 1 : -1));
        });

        // Return sorting position
        return position;
    });

    return cards;
}

export class Order {
    property?: string
    ascending: boolean
    comparison: string
    customComparison: (a, b) => number

    constructor(property, ascending = false, comparison = null, customComparison = null) {
        this.property = property;
        this.ascending = ascending;
        this.comparison = comparison;
        this.customComparison = customComparison;
    }
}


// Comparison functions for sorting/ordering
// <editor-fold desc="Ordering Comparison Functions">

// Default comparison to determine position in Array.sort()
//
// @returns {-1 || 0 || 1}
function orderPosition(a, b) {
    // After
    if (a > b) return 1;
    // Before
    if (a < b) return -1;
    // Equal
    return 0;
}

// Comparison for sorting by Days
//
// @params a/b {string} Time string interpretable by new Date()
//
// @returns {-1 || 0 || 1}
export function compareDate(a, b) {
    a = new Date(a);
    b = new Date(b);

    if (
        a.getFullYear() > b.getFullYear() ||
        a.getMonth() > b.getMonth() ||
        a.getDate() > b.getDate()
    ) return 1;

    if (
        a.getFullYear() < b.getFullYear() ||
        a.getMonth() < b.getMonth() ||
        a.getDate() < b.getDate()
    ) return -1;

    return 0;
}

export const orderSelectOptions = [
    {value: ['created_at', false, 'date'], label:'Date (DESC)'},
    {value: ['created_at', true, 'date'], label:'Date (ASC)'},
    {value: ['merit', false, null], label:'Merit (DESC)'},
    {value: ['merit', true, null], label:'Merit (ASC)'},
    {value: ['updated_at', false, 'date'], label:'Last updated (DESC)'},
    {value: ['updated_at', true, 'date'], label:'Last updated (ASC)'},
    {value: ['retired_at', false, 'date'], label:'Retired (DESC)'},
    {value: ['retired_at', true, 'date'], label:'Retired (ASC)'}
];