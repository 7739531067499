import React, {useContext} from 'react'

import Grid from '@mui/material/Grid'
import Fab from '@mui/material/Fab'
import Tooltip from '@mui/material/Tooltip'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import AddPromptCard from './AddPromptCard'
import CardsDrawer from './CardsDrawer'
import BoardColumn from './BoardColumn'
import {DragDropContext, Droppable} from "react-beautiful-dnd"
import {searchCards} from "../Helpers/cardSearch"
import ColumnFormContext from "./Forms/Contexts/ColumnForm.context"
import { useSearchTerm } from '../hooks/useSearchTerm'
import {Dashboard} from '@mui/icons-material'
import { EmptyStatePrompt } from './Universal/EmptyStatePrompt'


export default function Board({cards, board, deleteColumn, saveColumn, tags, cardDrawerOpen, toggleCardDrawer, columns, rearrangeColumnOrCard}) {
    const {popColumnFormDialog} = useContext(ColumnFormContext)
    const [searchTerm, updateSearchTerm] = useSearchTerm()

    const newColumnPrompt = () => {popColumnFormDialog(() => saveColumn, null, board)}

    const emptyStatePrompt = columns.length === 0

    const renderColumn = (column, index) => {
        if (!column) {
            console.error('Error with column in renderColumns, column rendering cancelled. column:', column)
            return
        }

        // Get Card objects by IDs stored in Column object
        let columnCards = cards.filter((card) => {
            // Card ID in Column cards array
            return column.cards.indexOf(card.ID) > -1
        })

        // Order the cards
        let orderedColumnCards = column.cards.slice(0)
        columnCards.map((card) => {
            // Replace Card ID with the Card
            return orderedColumnCards.splice(orderedColumnCards.indexOf(card.ID), 1, card)
        })

        // Strip remaining IDs (could be left over from deleted cards),
        // they should all be Card objects now
        orderedColumnCards = orderedColumnCards.filter((i) => {return !Number.isInteger(i)})

        return <BoardColumn
            key={index}
            columnIndex={index}
            board={board}
            column={column}
            deleteColumn={deleteColumn}
            saveColumn={saveColumn}
            orderedColumnCards={orderedColumnCards}
            tags={tags}
        />
    }

    const cardDrawerButton = () => {
        return (
            <Tooltip title="Card Drawer" placement="right" enterDelay={500} leaveDelay={200}>
                <Fab
                    variant="circular"
                    color="default"
                    aria-label="Card Drawer"
                    className={"ToggleCardDrawerButton" + (cardDrawerOpen ? ' open' : '')}
                    onClick={toggleCardDrawer}
                >
                    <ExpandLessIcon className={"ToggleCardDrawerButtonIcon"}/>
                </Fab>
            </Tooltip>
        )
    }

    const allColumnCardIDs = () => {
        let cardIDs = []
        columns.forEach(col => cardIDs.push(...col.cards))
        return cardIDs
    }

    // Remove cards that are in a Column
    const drawerCards = () => {
        const columnCardIds = allColumnCardIDs()

        return cards.filter((card) => {
            return columnCardIds.indexOf(card.ID) < 0
        })
    }

    const renderColumns = () => {
        if (emptyStatePrompt) return <EmptyStatePrompt
            icon={<Dashboard />}
            message="Let's make your first Column!"
            onClick={newColumnPrompt}
        />

        return <Droppable droppableId='board' direction='horizontal' type='COLUMN'>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    <Grid container
                        className={"Board" + (cardDrawerOpen ? ' cardDrawerOpen' : '')}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        wrap="nowrap"
                    >
                        {columns.map(renderColumn)}

                        {provided.placeholder}

                        <AddPromptCard
                            function={newColumnPrompt}
                            className='BoardColumnWrapper'
                        />
                    </Grid>
                </div>
            )}
        </Droppable>
    }

    // Set CSS variable to be used to calculate Board width to ensure Column dragging works
    document.documentElement.style.setProperty("--num-columns", columns.length)

    return (
        <React.Fragment>
            <DragDropContext onDragEnd={rearrangeColumnOrCard}>
                {renderColumns()}

                {cardDrawerButton()}

                <CardsDrawer
                    cards={searchCards(drawerCards().reverse(), searchTerm)}
                    open={cardDrawerOpen}
                    searchCards={updateSearchTerm}
                    tags={tags}
                />
            </DragDropContext>
        </React.Fragment>
    )
}