import React, {useState, useContext, useEffect, useRef, useCallback, useMemo} from 'react';
import {debounce} from "lodash";

// Material Design Components
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ThumbUpIcon from '@mui/icons-material/ThumbUpRounded';
import ThumbDownIcon from '@mui/icons-material/ThumbDownRounded';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import FlipIcon from '@mui/icons-material/Flip';
import CheckIcon from '@mui/icons-material/Check';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import MDCCard from '@mui/material/Card';

// Custom Components
import {tagDataForCard} from "../Structures/Tag";
import AppContext from "../Contexts/App.context.js";
import {cardValueLabel} from "./CardValue";
import {notify} from "./Notice";
import {dateFormat} from "../Helpers/dateHelper";
import {cardWearClass, cardWearVariantClass} from "../Helpers/cardWear";
import {Content, Tags} from "./Cards";
import ConfigContext from "../Contexts/Config.context";
import NoteFormContext from "./Forms/Contexts/NoteForm.context";
import ConfirmContext from '../Contexts/Confirm.context';


function Card(props) {
    const {card} = props;
    const {popNoteFormDialog} = useContext(NoteFormContext);
    const {confirm} = useContext(ConfirmContext);

    // Register AppContext
    const {loadCards, linkedCard, updateLinkedCard, focusCard, updateFocusCard} = useContext(AppContext);
    // Register config context
    const {config} = useContext(ConfigContext);

    const [expanded, setExpanded] = useState(props.expanded || false);
    const [privateAnimating, setPrivateAnimating] = useState(props.expanded || false);
    const [merit, setMerit] = useState(card.merit);

    const selector = useRef(null);

    // How long opening animation takes.
    // $default_transition from styles.scss
    const expandDuration = 245;

    // Handle show/hide of additional Card options
    //
    // @param open {bool} Set to open? (defaults to null then and uses expanded state)
    const expandToggle = (open = null) => {
        // Handle case that open is the event from onClick
        if (open !== null && typeof(open) !== "boolean") {
            open = null;
        }

        setExpanded(null !== open ? open : !expanded);
    };

    const setFocus = (event = null) => {
        // Rules for allowing focus
        if (event && event.target) {
            // Prevent CardLink from triggering setFocus/Clear linked Card
            // if inDialog;
            // else we want it to focus a Card AND open a linkedCard
            if (event.target.closest('.CardLink') && props.inDialog) return;
            // - Disallow focus/clear linked Card if this is the LinkedCard
            // Todo: This also disallows closing a self linked Card
            if (linkedCard.card && linkedCard.card.ID === card.ID) return;
            // - No focus if click link
            if (event.target.nodeName === 'A') return;
            // - No focus if click inside actions
            if (event.target.closest('.CardActions')) return;
            // - No focus if selecting
            if (!!window.getSelection().toString()) return;
        }

        // When linked Card, clear it when clicking on the current focus Card
        if (linkedCard.card) {
            // Clear linked Card
            updateLinkedCard(linkedCard.card, true);
            // Todo: timeout function to clear the card? (duration of animation)
        }

        // Check context and catch Board drag'n'drop events and prevent them from activating a flip
        if (focusCard || props.isDragging) return;

        updateFocusCard(card);
    };

    // Retire the Card
    const toggleRetired = () => {
        card.toggleRetired();

        update(card).then(() => {
            let message = card.retired_at ? 'Card retired' : 'Card un-retired';
            notify(message);
        });
    };

    // Handle merit button on Card
    const incrementMerit = (n: number) => {
        // Do nothing if goes below 0
        if (merit + n < 0) return;

        setMerit(merit + n);
        debouncedUpdateMerit(merit + n);
    };

    // How does this work? See this article: https://www.developerway.com/posts/debouncing-in-react
    const updateMerit = useCallback((card) => update(card), [card]);

    const debouncedUpdateMerit = useMemo(() => {
        return debounce((newMerit) => {
            card.merit = newMerit;
            updateMerit(card)
        }, 500)
    }, [updateMerit]);

    // Keep state informed of merit changes that may have happened in a 'focusCard'
    useEffect(() => {
        setMerit(card.merit);
    }, [card.merit]);

    // Toggle privacy of Card
    const togglePrivate = () => {
        // Catch Board drag'n'drop events and prevent them from activating a flip
        if (props.isDragging) return;

        card.togglePrivate();

        // Handle animation of lifting the card
        setPrivateAnimating(true);
        setTimeout(() => {
            setPrivateAnimating(false);
        }, (expandDuration));


        update(card);
    };

    // Delete the card
    const deleteCard = () => {
        confirm(() => {
            updateFocusCard(null);
            updateLinkedCard(null);

            card.delete().then(() => {
                notify('Card deleted');
                loadCards();
            });
        }, 'Do you want to delete this card?', 'Delete Card');
    };

    // Updates; and optionally saves, changes to Card
    //
    // @param card {Object} Card object to update and re-render
    //
    // @returns Promise
    const update = (card) => {
        // Saving
        return card.save().then(() => {
            loadCards();
        });
    };

    // Output with grid wrapper or without
    //
    // @param markup {JSX} The markup
    // @param grid {bool} Whether or not to wrap with a grid
    const cardOutputWrapper = (markup, grid = false) => {
        if (grid) {
            return (
                <Grid item>
                    <Tooltip
                        title={!props.inDialog ? "Click to Focus" : ''}
                        placement="top"
                        enterDelay={3000}
                        leaveDelay={200}
                        enterNextDelay={6000}
                    >
                        {markup}
                    </Tooltip>
                </Grid>
            )
        }

        return (
            <Tooltip
                title={!props.inDialog ? "Click to Focus" : ''}
                placement="top"
                enterDelay={3000}
                leaveDelay={200}
                enterNextDelay={6000}
            >
                {markup}
            </Tooltip>
        )
    };

    const date = new Date(card.created_at);
    const content = card.content;
    const tags = tagDataForCard(props.tags, card.tags);
    const classes = [
        'Card',
        cardValueLabel(merit),
        props.isDragging ? 'dragging' : null,
        card.private ? 'private' : null,
        card.retired_at ? 'retired' : null,
        expanded ? 'expanded' : null,
        card.back ? 'cardBack_' + card.back : null,
        config.cardWear ? cardWearClass(card.wear) : null,
        config.cardWear ? cardWearVariantClass(card.wear_variant) : null,
        // If this Card is focused, placeholder is output
        (!props.inDialog && focusCard && focusCard.ID === card.ID) ? 'hidden' : null
    ];
    const flipperClasses = [
        "CardFlipper",
        card.private ? 'private' : null,
        privateAnimating ? 'private_animating' : null
    ];

    const cardMarkup = <div
        className={flipperClasses.join(' ')}
        {...props.draggableProps}
        {...props.dragHandleProps}
    >
        <div className="CardFlipperInner">
            <MDCCard
                id={"Card_ID_" + card.ID }
                className={classes.join(' ') + ' face'}
                ref={selector}
                onClick={setFocus}
            >
                { null !== card.retired_at &&
                <Tooltip title="Retired" placement="top" enterDelay={500} leaveDelay={200}>
                    <div className="retiredBadge"><CheckIcon/></div>
                </Tooltip>}

                { merit > 0 &&
                <Tooltip title="Merit" placement="top" enterDelay={500} leaveDelay={200}>
                    <div className="meritBanner">{merit}</div>
                </Tooltip>
                }
                <Content content={content}/>

                <div className="CardActions">
                    <CardActions>
                        <Tags cardExpanded={expanded} tags={tags}/>

                        <Tooltip title={expanded ? 'Less' : 'More'} placement="top" enterDelay={500} leaveDelay={200}>
                            <IconButton
                                className={'icon-button expand-icon-' + expanded}
                                onClick={expandToggle}
                                aria-expanded={expanded}
                                aria-label="Show more"
                                size='small'
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </Tooltip>
                    </CardActions>

                    <Collapse in={expanded} timeout={expandDuration} unmountOnExit>
                        <div className="Date">{dateFormat(date)}</div>
                        <CardActions>
                            <Grid container
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                            >
                                <Grid item xs={2}>
                                    <Tooltip
                                        title="Delete"
                                        placement="top"
                                        enterDelay={500}
                                        leaveDelay={200}
                                    >
                                        <span>
                                        <Button
                                            onClick={deleteCard}
                                            fullWidth
                                            className={'cardActionButton'}
                                            color='inherit'
                                        >
                                            <DeleteIcon />
                                        </Button>
                                        </span>
                                    </Tooltip>
                                </Grid>

                                <Grid item xs={2}>
                                    <Tooltip
                                        title="Edit"
                                        placement="top"
                                        enterDelay={500}
                                        leaveDelay={200}
                                    >
                                        <span>
                                        <Button
                                            onClick={() => popNoteFormDialog(
                                                () => update,
                                                card,
                                                props.collection,
                                                props.tags
                                            )}
                                            fullWidth
                                            className={'cardActionButton'}
                                            color='inherit'
                                        >
                                            <EditIcon />
                                        </Button>
                                        </span>
                                    </Tooltip>
                                </Grid>

                                <Grid item xs={2}>
                                    <Tooltip
                                        title={card.retired_at ? "Un-retire" : "Retire"}
                                        placement="top"
                                        enterDelay={500}
                                        leaveDelay={200}
                                    >
                                        <span>
                                        <Button
                                            onClick={toggleRetired}
                                            fullWidth
                                            className={'cardActionButton'}
                                            color='inherit'
                                        >
                                            { card.retired_at ?
                                                <UnarchiveIcon /> :
                                                <ArchiveIcon />
                                            }
                                        </Button>
                                        </span>
                                    </Tooltip>
                                </Grid>

                                <Grid item xs={2}>
                                    <Tooltip
                                        title={'Flip'}
                                        placement="top"
                                        enterDelay={500}
                                        leaveDelay={200}
                                    >
                                        <span>
                                        <Button
                                            onClick={togglePrivate}
                                            fullWidth
                                            className={'cardActionButton'}
                                            color='inherit'
                                        >
                                            <FlipIcon/>
                                        </Button>
                                        </span>
                                    </Tooltip>
                                </Grid>

                                <Grid item xs={2} className={'meritButton'}>
                                    <Tooltip title="Add Merit" placement="top" enterDelay={500} leaveDelay={200}>
                                        <span>
                                            <Button
                                                onClick={() => incrementMerit(1)}
                                                fullWidth
                                                className={'cardActionButton'}
                                                color='inherit'
                                            >
                                                <ThumbUpIcon />
                                            </Button>
                                        </span>
                                    </Tooltip>
                                    <Tooltip title="Subtract Merit" placement="bottom" enterDelay={500} leaveDelay={200}>
                                        <span className={'subtractMerit'}>
                                            <IconButton
                                                onClick={() => incrementMerit(-1)}
                                                className={'cardActionButton'}
                                                size="large">
                                                <ThumbDownIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Collapse>
                </div>
            </MDCCard>
            <MDCCard
                id={"Card_ID_" + card.ID }
                className={classes.join(' ') + ' back'}
                ref={selector}
                onClick={togglePrivate}
            />
        </div>
    </div>;

    return cardOutputWrapper(
        cardMarkup,
        props.grid
    );
}

export default Card;