import React, {useState} from 'react';

import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/MoreVert';

import CollectionOverviewDrawer from "./CollectionOverviewDrawer";
import CollectionFormDialog from "../../Forms/CollectionForm";
import TopBar from '../../Universal/TopBar';

function CollectionOverviewTopBar({searchCards, collections, tags, saveCollection, deleteCollection, toggleCollectionDisplay, rearrangeCollection}) {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    };

    return (
        <>
            <TopBar
                title='Collections'
                className='CardOverviewTopBar'
                menuButton={(
                    <IconButton onClick={toggleMenu} size="large">
                        <MoreIcon />
                    </IconButton>
                )}
                searchFunction={searchCards}
                disableGutters
            />
            <CollectionOverviewDrawer
                open={menuOpen}
                toggle={toggleMenu}
                collections={collections}
                tags={tags}
                saveCollection={saveCollection}
                deleteCollection={deleteCollection}
                toggleCollectionDisplay={toggleCollectionDisplay}
                onCollectionDragEnd={rearrangeCollection}
            />
            <CollectionFormDialog/>
        </>
    )
}

export default CollectionOverviewTopBar;