// Detect if the element is scrolled all the way to the bottom
// Useful for say, loading more cards after reaching the bottom of the list
//
// May be used for an elements scroll or window scroll
export function isBottom(e, useWindow = false) {
    // Trigger within 10 pixels of bottom
    // - Note: The consequences of widening this gap are insignificant because we expand the height when we hit this, meaning it won't be triggered again.
    //      Further, if it is multi-triggered, the only consequence is loading more cards
    if (useWindow) {
        return window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 10;
    }

    return e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 10;
}