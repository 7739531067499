import React, {useState} from 'react';

import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/MoreVert';

import BoardOverviewDrawer from './BoardOverviewDrawer';
import BoardFormDialog from "../../Forms/BoardForm";
import TopBar from '../../Universal/TopBar';

function BoardOverviewTopBar({searchCards, boards, saveBoard, deleteBoard, toggleBoardDisplay, rearrangeBoard}) {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    };

    return (
        <>
            <TopBar
                title='Boards'
                className='CardOverviewTopBar'
                menuButton={(
                    <IconButton onClick={toggleMenu} size="large">
                        <MoreIcon />
                    </IconButton>
                )}
                searchFunction={searchCards}
                disableGutters
            />
            <BoardOverviewDrawer
                open={menuOpen}
                toggle={toggleMenu}

                boards={boards}
                saveBoard={saveBoard}
                deleteBoard={deleteBoard}
                toggleBoardDisplay={toggleBoardDisplay}
                onBoardDragEnd={rearrangeBoard}
            />
            <BoardFormDialog/>
        </>
    );
}

export default BoardOverviewTopBar;