import { createContext, useState } from 'react';

const BoardFormContext = createContext();

const BoardFormProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [board, setBoard] = useState(null);
    const [onSuccess, setOnSuccess] = useState(undefined);

    const popBoardFormDialog = (onSuccessFn, board = null) => {
        if (!(onSuccessFn instanceof Function)) {
            console.error('Parameter "onSuccessFn" is not a function');
            throw new Error('Parameter "onSuccessFn" is not a function');
        }

        if (board !== null && !(typeof board === 'object')) {
            throw new Error('Parameter "board" is not an Object OR null');
        }

        setBoard(board);
        setOnSuccess(onSuccessFn);
        setOpen(true);
    };

    const closeDialogue = () => {
        setOpen(false);
        setBoard(null);
        setOnSuccess(undefined);
    };

    return (
        <BoardFormContext.Provider value={{
            open,
            board,
            closeDialogue,
            onSuccess,
            popBoardFormDialog
        }}>
            {children}
        </BoardFormContext.Provider>
    );
};

export {BoardFormProvider}
export default BoardFormContext