import React, {useContext} from 'react';
import InfoDialogContext from "../Contexts/InfoDialog.context";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const InfoDialog = () => {
    const {open, handleClose, content, button} = useContext(InfoDialogContext);

    return (
        <Dialog
            className="InfoDialog"
            open={open}
            onClose={handleClose}
        >
            <DialogContent>
                {content}
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={handleClose}
                    size={"large"}
                    fullWidth
                >{button}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default InfoDialog