import localforage from "localforage";
import {getCards} from "./Card";
import {loadCollections} from "./Collection";

// <editor-fold desc="Tag">
// A data structure for tags so that tags stored with Cards may act as a reference/ID
// to a Tag and additional data can be added to a Tag
//
// @param tag {string} Tag name, acts as the name and like an ID
// @param color {string} A Color String (Hex, RGB, RGBA)
export class Tag {
    constructor(tag, color = null) {
        this.tag = tag;
        this.color = color ? color : '#A9A9A9';
    }
}

// Save array of Tags to storage
//
// @param tags {array} of Tag objects
//
// @returns Promise
export function saveTags(tags) {
    if (!Array.isArray(tags)) {
        throw new Error('Supplied parameter "tags" is not an array.');
    }

    // Loads existing tags
    // By using getActiveTags instead of loadTags we will delete inactive tags on save
    return getActiveTags().then((storedTags) => {
        let updatedTags = storedTags;

        tags.forEach(function(tag) {
            // Only consider saving Tags with a tag
            if (tag.tag) {
                // If tag not already present
                const index = storedTags.findIndex((t) => {
                    return t.tag === tag.tag
                });
                // If tag exists
                if (index > -1) {
                    // Update tag
                    updatedTags.splice(index, 1, tag);
                } else { // Tag doesn't exist
                    // Add tag
                    updatedTags.push(tag);
                }
            }

            // Save updatedTags
            return localforage.setItem('tags', updatedTags).catch(function(err) {
                throw new Error(err);
            });
        });
    });
}

// Get array of Tags from storage
//
// @returns Promise {array} of Tag objects
export function loadTags() {
    return localforage.getItem('tags').then((result) => {
        // Make sure an array is returned
        if (Array.isArray(result)) {
            return result;
        }
        return [];
    }).catch(function(err) {
        console.log(err);
        return [];
    });
}

// Get all currently used tags
//
// @returns {Promise -> Array} Promise to getCards and evaluate to Array of active Tags
export function getActiveTags() {
    // Await resolution of promises to get Cards and Collections
    return Promise.all([
        getCards(),
        loadCollections()
    ])
    .then((values) => {
        let cards = values[0];
        let collections = values[1];

        return tagData(cards, collections);
    });
}

// Get Tag data for provided Cards (save fetching Cards)
//
// @param cards {array} of Cards
// @param collections (optional) {array} of Collections
//
// @returns {Promise -> Array} Promise to loadTags and evaluate to Array of active Tags
export function tagData(cards, collections = null) {
    let activeTags = [];

    // Get tags
    return loadTags().then(tags => {
        // Add to activeTags if not already there
        const checkThenAdd = (tagString) => {
            // If tag not already present
            if (!activeTags.filter(t => t.tag === tagString).length) {
                // Find saved Tag object if exists in storage
                let savedTag = tags.filter(item => {return item.tag === tagString})[0];
                const color = savedTag && savedTag.color ? savedTag.color : null;
                // Add tag
                activeTags.push(new Tag(tagString, color));
            }
        };

        // Loop cards
        cards.forEach(card => {
            // Loop tags
            card.tags.forEach(tag => {
                checkThenAdd(tag);
            });
        });

        if (collections) {
            // Loop collections
            collections.forEach(collection => {
                // Loop filters
                collection.filters.forEach(filter => {
                    checkThenAdd(filter.label);
                });
            });
        }

        return activeTags;
    });
}

// Attach data from Tag to the Tag reference on the Card
// - Tags are stored as their own data type, whereas a Card just references Tags
//  by name. This function merges them together for easy processing and output
//
// @param tagDatas {Array} of Tags
// @param cardTags {Array} of tag names from a Card
//
// @return {Array} of Tag classes
export function tagDataForCard(tagDatas, cardTags) {
    tagDatas = tagDatas.slice();
    cardTags = cardTags.slice();

    return cardTags.map((cardTag) => {
        // Match tagDatas to card tag string
        let tagData = tagDatas.filter((tg) => {return tg.tag === cardTag;})[0];
        const color = tagData && tagData.color ? tagData.color : null;

        return new Tag(cardTag, color);
    });
}

// Attach data from Tag to Filter (of a Collection)
// - This function is very similar to tagDataForCard()
//
// @param tagDatas {Array} of Tags
// @param filters {Array} of filters (of a Collection)
//
// @return {Array} of Filters with color appended
export function tagDataForFilter(tagDatas = [], filters = []) {
    tagDatas = tagDatas.slice();
    filters = filters.slice();

    return filters.map((filter) => {
        // Match tagDatas to filter tag string
        let tagData = tagDatas.filter(tg => tg.tag === filter.label)[0];
        // Set color
        filter.color = tagData && tagData.color ? tagData.color : null;

        return filter;
    })
}