import React, {useContext, useState, useEffect} from 'react'

import JourneyOverviewTopBar from './Journeys/JourneyOverviewTopBar'
import JourneyTopBar from './Journeys/JourneyTopBar'
import {collectCards} from '../../Structures/Collection'
import {Journeys} from "./Journeys/Journeys"
import {searchCards, searchCollections} from "../../Helpers/cardSearch"
import AppContext from "../../Contexts/App.context.js"
import {JourneyFormProvider} from "../Forms/Contexts/JourneyForm.context"
import NewCardButton from '../Universal/NewCardButton'
import { useSearchTerm } from '../../hooks/useSearchTerm'
import Journey from './Journeys/Journey'
import {db} from '../../database/db'
import {useLiveQuery} from 'dexie-react-hooks'
import JourneyContext from '../../Contexts/Journey.context'

function JourneyScreen() {
    const {screen, updateScreen, cards} = useContext(AppContext)
    const {handleReorderJourneys} = useContext(JourneyContext)

    const journeys = useLiveQuery(() => db.journeys.toArray())
    const journeyOrder = useLiveQuery(() => db.listOrders.get({'listName': 'journeys'}), [], 'loading')

    const isLoading = journeys === undefined || journeyOrder === 'loading'

    const [orderedJourneys, setOrderedJourneys] = useState([])
    const [currentJourney, setCurrentJourney] = useState(null)
    const [searchTerm, updateSearchTerm, setSearchTerm] = useSearchTerm()

    useEffect(() => {
        setOrderedJourneys(sortObjectsByListOfIds(journeys, journeyOrder?.order))
    }, [journeys, journeyOrder?.order])

    // react-beautiful-dnd Reorder function
    const reorder = (list, startIndex, endIndex) => {
        const removed = list.splice(startIndex, 1)
        list.splice(endIndex, 0, removed[0])
        return list
    }

    const rearrangeJourney = (result) => {
        // Dropped outside the list OR not moved from original position
        if (!result.destination || result.source.index === result.destination.index) {
            return
        }

        const reorderedJourneys = reorder(
            orderedJourneys.slice(),
            result.source.index,
            result.destination.index
        )

        handleReorderJourneys(reorderedJourneys.map(j => j.id))
        setOrderedJourneys(reorderedJourneys)
    }

    const openJourney = (id) => {
        let journey = orderedJourneys.find((j) => j.id === id)

        setCurrentJourney(journey)
        updateScreen('journey')
    }

    const closeJourney = () => {
        setCurrentJourney(null)
        setSearchTerm('')
        updateScreen('journeys')
    }

    const journeyView = () => {
        const journey = Object.assign(currentJourney)

        return (
            <React.Fragment>
                <JourneyTopBar
                    journey={journey}
                    close={closeJourney}
                    searchCards={updateSearchTerm}
                />
                <Journey
                    cards={searchCards(collectCards(cards, journey), searchTerm)}
                    searchTerm={searchTerm}
                />
            </React.Fragment>
        )
    }

    const journeyOverview = () => {
        if (currentJourney === null || screen === 'journeys') {

            return (
                <React.Fragment>
                    <JourneyOverviewTopBar
                        journeys={orderedJourneys?.slice() ?? []}
                        rearrangeJourney={rearrangeJourney}
                        searchCards={updateSearchTerm}
                    />
                    <Journeys
                        journeys={searchCollections(orderedJourneys?.slice() ?? [], searchTerm)}
                        cards={cards.slice()}
                        collectCards={collectCards}
                        open={openJourney}
                        searchTerm={searchTerm}
                        isLoading={isLoading}
                    />
                </React.Fragment>
            )
        }
    }

    let output = journeyOverview()

    let journey = null
    if (currentJourney !== null && screen === 'journey') {
        output = journeyView()
        journey = Object.assign(currentJourney)
    }

    return (
        <div className="Screen">
            <JourneyFormProvider>
                {output}
                <NewCardButton/>
            </JourneyFormProvider>
        </div>
    )
}

function sortObjectsByListOfIds(objects, orderedIds) {
    if (!objects) return []
    if (!orderedIds) return objects
    
    const objectsOrderedByIds = objects.slice()

    // Order by indexed position of ids in array
    objectsOrderedByIds.sort((a, b) => {
        const aIndex = orderedIds.indexOf(a.id)
        const bIndex = orderedIds.indexOf(b.id)
        if (aIndex === -1 || bIndex === -1) return 0
        
        return aIndex < bIndex ? -1 : 1
    })

    return objectsOrderedByIds
}

export default JourneyScreen