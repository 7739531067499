import React, {createContext, useState, useEffect} from 'react';
import {Config, loadConfig, saveConfig} from "../Structures/Config";

const ConfigContext = createContext();

const ConfigProvider = ({children}) => {
    const [config, setConfig] = useState(new Config());

    // Provided as prop to allow setting screen deeper in application
    const updateConfig = (c) => {
        setConfig(c);
        saveConfig(c);
    };

    const toggleConfigValue = (name) => {
        let c = Object.assign(new Config(), config); // Make immutable

        c[name] = !c[name];
        updateConfig(c);
    };

    const toggleConfigStealthMode = () => {
        toggleConfigValue('stealthMode');
    };

    const toggleConfigCardWear = () => {
        toggleConfigValue('cardWear');
    };

    const toggleConfigSmoothOperator = () => {
        toggleConfigValue('smoothOperator');
    };

    const toggleConfigExportPrompts = () => {
        toggleConfigValue('exportPrompts');
    };

    useEffect(() => {
        // Load app Config
        loadConfig().then((loadedConfig) => {
            setConfig(loadedConfig);
        });
    }, []);

    return (
        <ConfigContext.Provider value={{
            config,
            toggleConfigStealthMode,
            toggleConfigCardWear,
            toggleConfigSmoothOperator,
            toggleConfigExportPrompts
        }}>
            {children}
        </ConfigContext.Provider>
    );
};

export { ConfigProvider };
export default ConfigContext