import React from 'react';
import Grid from '@mui/material/Grid';
import MDCCard from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import AddIcon from '@mui/icons-material/Add';

function AddPromptCard(props) {
    return (
        <Grid className={'new-card-prompt-wrapper ' + props.className} item>
            <MDCCard className="new-card-prompt">
                <CardActionArea onClick={props.function}>
                    <AddIcon/>
                </CardActionArea>
            </MDCCard>
        </Grid>
    );
}

export default AddPromptCard;