import React, {createContext, useState} from 'react';

const InfoDialogContext = createContext();

const InfoDialogProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState(null);
    const [button, setButton] = useState(null);
    const [onClose, setOnClose] = useState(null);

    // Close dialog box
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
        setOpen(false);
        setContent(null);
        setButton(null);
        setOnClose(null);
    };

    // Show info dialog
    //
    // @param content {JSX} Content to put in the dialog box
    // @param button {String} Button string
    // @param onClose {Function} Function to execute on close
    const infoDialog = (content = null, button = null, onClose = null) => {
        // Check onClose is function if provided
        if (onClose !== null && !(onClose instanceof Function)) {
            throw new Error('Parameter "action" is not a function');
        }
        
        setContent(content);
        setButton(button);
        setOnClose(onClose);
        setOpen(true);
    };

    return (
        <InfoDialogContext.Provider value={{
            open,
            content,
            button,
            handleClose,
            infoDialog
        }}>
            {children}
        </InfoDialogContext.Provider>
    );
};

export {InfoDialogProvider}
export default InfoDialogContext