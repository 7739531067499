import React from 'react';

// Functions
export const formatFileSize = (fileSize, showUnit = true, decimals = 2) => {
    if (fileSize === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(fileSize) / Math.log(k));
    return parseFloat((fileSize / Math.pow(k, i)).toFixed(dm)) + (showUnit ? ' ' + sizes[i] : '')
};

const arrayRandom = (array) => {
    if (array.length === 0) { throw Error; }

    return array[Math.floor(Math.random()*array.length)];
};

export const randomMessage = (context) => {
    // Keys to the array are the context, the array are the
    // messages that will be randomly picked from
    const messages = {
        'not_found': [
            'Nuffin\'ere boss...',
            'No results found.',
            'I double checked. Trust me, there\'s nothing here.',
            'Gaze upon this field in which I grow my search results, and see, that it is barren...',
        ],
        'empty_journey': [
            `Let's go on a Journey`,
            'Looks like this Journey has just begun!',
            'First steps on a new Journey',
            'How exciting! A new Journey'
        ],
        'error': [
            'Oh deary me, something went quite wrong.'
        ]
    };

    try {
        return arrayRandom(messages[context]);
    } catch {
        return arrayRandom(messages['error']);
    }
};


export function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}


// Components
export function EmptyMessage() {
    return <div className={'messageBox EmptyMessage'}>
        <p>{ randomMessage('not_found') }</p>
    </div>
};