import React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemIcon from '@mui/material/ListItemIcon';

import NewReleasesIcon from '@mui/icons-material/NewReleases';
import WrenchIcon from '@mui/icons-material/Build';
import SpeedIcon from '@mui/icons-material/Speed';
import BugIcon from '@mui/icons-material/BugReport';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import LabelIcon from '@mui/icons-material/Label';
import EmojiSymbolsIcon from '@mui/icons-material/EmojiSymbols';
// Note: date in YYYY-MM-DD
// Types: feat, tweak, fix, opti, notice, secret
// Sample:
// {
//     'name': 'Release Name',
//     'version': 'unreleased',
//     'date': 'TBD',
//     'changes': [
//         {'desc': 'Add game-changing feature!', 'type': 'feat'},
//         {'desc': 'Fix: Stop bug from buggin', 'type': 'fix'},
//         {'desc': 'A new Card back', 'type': 'feat', card: 'card_back_name'},
//     ]
// },
const changelog = [
    // {
    //     'name': 'Feeling it now?!',
    //     'version': 'unreleased',
    //     'date': '???',
    //     'changes': [
    //         {'desc': 'Introduce magic Card windows!', 'type': 'feat'},
    //         {'desc': 'Introduce real Card sizing!', 'type': 'feat'},
    //         {'desc': 'Board optimizations', 'type': 'opti'}
    //     ]
    // },
    {
        'name': 'Journeys!',
        'version': '1.33.0',
        'date': '2024-05-24',
        'changes': [
            {'desc': 'Introducing Journeys! View your Cards in the context of time and see the story they tell', 'type': 'feat'},
            {'desc': 'Learning a new app is also a journey - Helpful prompts on empty pages will now aid you along that journey', 'type': 'feat'},
            {'desc': 'New Card back for you to take on your journeys: Sakura', 'type': 'feat', card: 'sakura'},
            {'desc': 'Storage and Import/Export upgrades', 'type': 'tweak'},
            {'desc': 'A multitude of code improvements', 'type': 'opti'},
            {'desc': 'Private cards no longer display in Template select field', 'type': 'fix'},
            {'desc': 'Changing Template and Collection checkbox on Card activates submit button', 'type': 'fix'},
        ]
    },
    {
        'name': 'New Card flare',
        'version': '1.32.0',
        'date': '2024-04-07',
        'changes': [
            {'desc': 'Added cute animation to New Card button', 'type': 'feat'},
            {'desc': 'Upgrades to app top bar and search box', 'type': 'opti'},
            {'desc': 'Upgrade search fields', 'type': 'opti'},
            {'desc': 'Card form fix for checking when update has been made', 'type': 'fix'},
            {'desc': 'Add missing search clear button to Collection screen', 'type': 'fix'},
        ]
    },
    {
        'name': 'Donoto',
        'version': '1.31.0',
        'date': '2024-04-01',
        'changes': [
            {'desc': 'New: Donation button - under Settings', 'type': 'feat'},
            {'desc': 'Add fun banner to changelog', 'type': 'feat'},
            {'desc': 'Upgrade info dialogs', 'type': 'tweak'},
        ]
    },
    {
        'name': 'Persistence is key!',
        'version': '1.30.0',
        'date': '2024-03-30',
        'changes': [
            {'desc': 'Configure explicit persisting local data, prompt if necessary', 'type': 'opti'},
            {'desc': 'Upgrade confirmation dialog', 'type': 'tweak'},
            {'desc': 'More uniform prompting', 'type': 'tweak'},
        ]
    },
    {
        'name': 'Meta fix up',
        'version': '1.29.1',
        'date': '2024-03-29',
        'changes': [
            {'desc': 'Fix minor meta data load/save issue', 'type': 'fix'},
        ]
    },
    {
        'name': 'Back it up',
        'version': '1.29.0',
        'date': '2024-03-19',
        'changes': [
            {'desc': "Backup Prompts: You'll be prompted to Export your data regularly, change this in Settings", 'type': 'feat'},
            {'desc': 'Ongoing modernisation maintenance upgrades', 'type': 'opti'},
            {'desc': 'Add metadata to app data', 'type': 'tweak'},
            {'desc': 'Better editor and Card header sizing', 'type': 'fix'},
        ]
    },
    {
        'name': 'Long Term-inal Gain',
        'version': '1.28.0',
        'date': '2024-03-17',
        'changes': [
            {'desc': 'Big, behind the scenes, maintenance upgrades', 'type': 'tweak'},
            {'desc': 'New Card back for all the hackers out there: Terminal', 'type': 'feat', card: 'terminal'},
        ]
    },
    {
        'name': 'Icon/Badge Parity',
        'version': '1.27.0',
        'date': '2023-08-01',
        'changes': [
            {'desc': 'Improve Icon insert dialogue to include preview', 'type': 'opti'},
            {'desc': 'Fix some button spacing inconsistencies', 'type': 'fix'},
        ]
    },
    {
        'name': 'Heartbeat',
        'version': '1.26.1',
        'date': '2023-07-07',
        'changes': [
            {'desc': 'Upgraded Presence connection so it reconnects after a disconnect', 'type': 'feat'},
            {'desc': 'Smoothing out a fix for Smooth Operator', 'type': 'fix'},
            {'desc': 'Reduced Card Drawer height for more Board visibility', 'type': 'feat'},
            {'desc': 'Smoothing out Card Links for Smooth Operator', 'type': 'fix'},
        ]
    },
    {
        'name': 'Smooth Operator',
        'version': '1.25.2',
        'date': '2023-06-11',
        'changes': [
            {'desc': 'Introducing a smooth new style - Is it just me or did everything just get a whole lot smoother?', 'type': 'feat'},
            {'desc': 'New chocolately smooth card back: Choco!', 'type': 'feat', card: 'choco'},
            {'desc': 'Added toggle for Smooth Operator to Settings', 'type': 'feat'},
            {'desc': 'Core app performance improvements', 'type': 'opti'},
            {'desc': 'Fix minor theme color, spacing discrepancies', 'type': 'fix'},
        ]
    },
    {
        'name': 'Demerit!',
        'version': '1.24.0',
        'date': '2023-06-07',
        'changes': [
            {'desc': 'You can now subtract Merit from a Card', 'type': 'feat'},
            {'desc': 'Adding and subtracting Merit is now faster', 'type': 'opti'},
        ]
    },
    {
        'name': 'Badge of Honour',
        'version': '1.23.0',
        'date': '2023-04-13',
        'changes': [
            {'desc': 'New: Badges! Celebrate your achievements or bring attention to special info. Find them in the Insert menu', 'type': 'feat'},
        ]
    },
    {
        'name': 'It\'s Never Temp-late',
        'version': '1.22.0',
        'date': '2023-03-08',
        'changes': [
            {'desc': 'Introducing... Templates! Check the "Template" box when editing a Card to add it the Insert menu (Edit -> Insert -> Template).', 'type': 'feat'},
        ]
    },
    {
        'name': 'Packin\' brand new Card Wear!',
        'version': '1.21.0',
        'date': '2023-01-23',
        'changes': [
            {'desc': 'Introducing... Card wear! Cards optionally accumulate wear as they are handled', 'type': 'feat'},
            {'desc': 'New Card back: Festive Melon!', 'type': 'feat', card: 'festive-melon'},
            {'desc': 'Behind the scenes code improvements', 'type': 'opti'},
        ]
    },
    {
        'name': 'Bored of Bland Boards',
        'version': '1.20.0',
        'date': '2022-11-27',
        'changes': [
            {'desc': 'Boards represented as decks!', 'type': 'feat'},
        ]
    },
    {
        'name': 'Quiet... Too quiet...',
        'version': '1.19.0',
        'date': '2022-11-23',
        'changes': [
            {'desc': 'Introducing Presence: Feel the presence of your fellow Noto enjoyers!', 'type': 'feat'},
            {'desc': 'New Card back: Autumn!', 'type': 'feat', card: 'autumn'},
            {'desc': 'Add Stealth Mode configuration to Settings', 'type': 'feat'},
        ]
    },
    {
        'name': 'A little more than you bargained for...',
        'version': '1.18.1',
        'date': '2022-09-20',
        'changes': [
            {'desc': 'Fix bug where some icons in the new Icon picker displayed multiple times', 'type': 'fix'},
        ]
    },
    {
        'name': 'Iconic.',
        'version': '1.18.0',
        'date': '2022-09-19',
        'changes': [
            {'desc': 'Icon picker added to Card edit Insert menu: No more guessing short-codes!', 'type': 'feat'},
            {'desc': 'New Card back: Retro Stripe!', 'type': 'feat', card: 'retro-stripe'},
            {'desc': 'Update to Font-Awesome 6.2.0: New icons!', 'type': 'tweak'},
            {'desc': 'A familiar face appears in the colour swatches? 🤔', 'type': 'secret'},
            {'desc': 'Removed font-awesome reference from Settings', 'type': 'notice'},

        ]
    },
    {
        'name': 'Back-to-Back Stacks',
        'version': '1.17.0',
        'date': '2022-08-28',
        'changes': [
            {'desc': 'Collections represented as decks! See how your Card backs stack!', 'type': 'feat'},
            {'desc': 'Card Links show Card Back of linked Card', 'type': 'feat'},
            {'desc': 'New Card back: Cargo!', 'type': 'feat', 'card': 'cargo'},
            {'desc': 'Storage calculation outputs units', 'type': 'fix'},
            {'desc': '"I need a weapon"', 'type': 'secret'},
        ]
    },
    {
        'name': 'Feel the Card-nection',
        'version': '1.16.0',
        'date': '2022-07-11',
        'changes': [
            {'desc': 'Card Links! - Reference and view Cards, from Cards, with Card Links! Insert one today... edit a Card!', 'type': 'feat'},
            {'desc': 'New Card back: Chains of Noto!', 'type': 'feat', 'card': 'chains'},
            {'desc': 'Additional sass when searching for something that ain\'t there.', 'type': 'feat'},
            {'desc': 'Auto-focus Card content for lightning fast edits!', 'type': 'tweak'},
            {'desc': 'Optimized Card content storage', 'type': 'opti'},
            {'desc': 'Putting it all on the table - tables lookin\' fly', 'type': 'feat'},
            {'desc': 'Got lots of tags? They\'re gonna scroll in the edit field now, \'kay, bud?', 'type': 'tweak'},
            {'desc': 'Better storage calculation read-out', 'type': 'tweak'},
            {'desc': 'Update introduction dialogue content', 'type': 'tweak'},
        ]
    },
    {
        'name': 'Baby Got Back',
        'version': '1.15.0',
        'date': '2022-06-01',
        'changes': [
            {'desc': 'You can choose\'n\'change your Card backs now! Cool!', 'type': 'feat'},
            {'desc': 'New Card back: Rainy Cafe!', 'type': 'feat', 'card': 'rainy_cafe'},
            {'desc': 'New Card back: Sunset!', 'type': 'feat', 'card': 'sunset'},
            {'desc': 'New Card back: Ancient Tome!', 'type': 'feat', 'card': 'tome'},
            {'desc': 'New Card back: Casette!', 'type': 'feat', 'card': 'vhs'},
            {'desc': 'Fix non-clickable deck', 'type': 'fix'},
        ]
    },
    {
        'name': 'Mis-click',
        'version': '1.14.1',
        'date': '2022-03-22',
        'changes': [
            {'desc': 'Fix non-clickable area', 'type': 'fix'},
        ]
    },
    {
        'name': 'Draw!',
        'version': '1.14.0',
        'date': '2022-03-22',
        'changes': [
            {'desc': 'Add Card draw animation on new Card', 'type': 'feat'},
        ]
    },
    {
        'name': 'Reset. Focus.',
        'version': '1.13.3',
        'date': '2022-03-14',
        'changes': [
            {'desc': 'Reset focus on Card deletion', 'type': 'fix'},
        ]
    },
    {
        'name': 'Capture the drag',
        'version': '1.13.2',
        'date': '2022-02-21',
        'changes': [
            {'desc': 'Prevent Card focus on drag\'n\'drop', 'type': 'fix'},
        ]
    },
    {
        'name': 'Keep scrollin\', scrollin\', scrollin\', scrollin\'',
        'version': '1.13.1',
        'date': '2022-02-21',
        'changes': [
            {'desc': 'Fix loading more Cards on scroll', 'type': 'fix'},
        ]
    },
    {
        'name': 'Do a flip!',
        'version': '1.13.0',
        'date': '2022-02-21',
        'changes': [
            {'desc': 'You can flip over your Cards now; like a pancake!', 'type': 'feat'},
            {'desc': 'Card hide/show is replaced by flipping', 'type': 'notice'},
        ]
    },
    {
        'name': 'Loco-motion? No-to-motion...',
        'version': '1.12.1',
        'date': '2022-02-07',
        'changes': [
            {'desc': 'Remove global Card animations', 'type': 'fix'},
            {'desc': 'Fix broken auto-scroll on typing', 'type': 'fix'},
        ]
    },
    {
        'name': 'Focus-Pocus',
        'version': '1.12.0',
        'date': '2022-01-30',
        'changes': [
            {'desc': 'Added Card focus; inspect and scroll your Cards!', 'type': 'feat'},
            {'desc': 'Added Card Merit tier glow~', 'type': 'feat'},
            {'desc': 'Bespoke scroll zone and magic Card scroll window removed in favour of Card focus', 'type': 'notice'},
        ]
    },
    {
        'name': 'Flippity-flop, scrollbar stop!',
        'version': '1.11.2',
        'date': '2022-01-19',
        'changes': [
            {'desc': 'Expand icons on Cards flipped the right way', 'type': 'fix'},
            {'desc': 'Scrollbars on Cards removed', 'type': 'fix'},
        ]
    },
    {
        'name': 'Top that!',
        'version': '1.11.1',
        'date': '2021-12-12',
        'changes': [
            {'desc': 'Navigating between screens no longer has you show up partway scrolled down', 'type': 'fix'},
            {'desc': 'Bonus: Go to top by clicking the navigation', 'type': 'feat'},
        ]
    },
    {
        'name': 'Could it be magic?',
        'version': '1.11.0',
        'date': '2021-12-12',
        'changes': [
            {'desc': 'Scroll zones added to deal with multiple scroll containers', 'type': 'notice'},
            {'desc': 'Introduce magic Card windows!', 'type': 'feat'},
            {'desc': 'Introduce real Card sizing!', 'type': 'feat'},
            {'desc': 'Board optimizations', 'type': 'opti'}
        ]
    },
    {
        'name': 'Chump Change(log)',
        'version': '1.10.0',
        'date': '2021-05-19',
        'changes': [
            {'desc': 'Update dependencies', 'type': 'tweak'},
            {'desc': 'Add changelog to Settings', 'type': 'feat'},
            {'desc': 'Add storage calculation to Settings', 'type': 'feat'},
            {'desc': 'Increase Card incremental loading amount', 'type': 'fix'},
            {'desc': 'Improve style includes', 'type': 'opti'},
            {'desc': 'Remove some mobile jank from tag select fields', 'type': 'fix'},
        ]
    },
    {
        'name': 'Long-lost history',
        'version': '?.?.?',
        'date': 'the before times... those dark ages before the changelog was introduced',
        'changes': [
            {'desc': 'Rest assured, there were changes and updates. They just were not formally broadcast...'},
        ]
    },
];

export default function ChangelogContent() {
    return (
        <div className='changelog-wrapper'>
            <div className='changelog-banner'></div>
            <div className='changelog-content'>
                <h2>Changelog</h2>
                {changelog.map(log => {return (
                    <List
                        dense={true}
                        subheader={<ListSubheader disableSticky><strong>{log.name}</strong> - {log.version} <small>({log.date})</small></ListSubheader>}
                        className={'changelog-list'}
                        key={log.version}
                    >
                        { log.changes.map((change, index) => {
                            let icon;
                            switch (change.type) {
                                case 'feat':
                                    icon = <NewReleasesIcon/>;
                                    break;
                                case 'tweak':
                                    icon = <WrenchIcon/>;
                                    break;
                                case 'fix':
                                    icon = <BugIcon/>;
                                    break;
                                case 'opti':
                                    icon = <SpeedIcon/>;
                                    break;
                                case 'notice':
                                    icon = <AnnouncementIcon/>;
                                    break;
                                case 'secret':
                                    icon = <EmojiSymbolsIcon/>;
                                    break;
                                default:
                                    icon = <LabelIcon/>;
                            }

                            const cardBack = change.card ? <div className={'changelog-list-card ' + change.card}></div> : null;

                            return (
                                <ListItem key={index}>
                                    <ListItemIcon>{ cardBack ? cardBack : icon }</ListItemIcon>
                                    <ListItemText primary={change.desc}/>
                                </ListItem>
                            )
                        })}
                    </List>
                )})}
            </div>
        </div>
    )
}