import React, {useContext} from 'react';
import AppContext from '../../Contexts/App.context.js';
import {CardsOrnate} from "../Cards"
import {searchCards} from "../../Helpers/cardSearch";
import Dialog from '@mui/material/Dialog';
import Input from '@mui/material/Input';
import { useSearchTerm } from '../../hooks/useSearchTerm';

const MemoizedCardSelectField = React.memo(CardSelectField, propsAreEqual);

function propsAreEqual(prev, next) {
    return prev.open === next.open;
}

function CardSelectField({open, setOpen, insertCardLink}) {
    const context = useContext(AppContext);
    const [searchTerm, updateSearchTerm, setSearchTerm] = useSearchTerm();

    const handleClose = () => {
        setOpen(false);
        setSearchTerm('');
    };
    
    return (
        <Dialog open={open} onClose={handleClose} className={'CardSelectFieldDialog'}>
            <div className={'CardSelectField'}>
                <CardsOrnate
                    cards={searchCards(context.cards, searchTerm)}
                    onClick={(card_id) => {
                        insertCardLink(card_id);
                        handleClose();
                    }}
                />
            </div>
            <Input
                className={"CardSelectSearch"}
                type="text"
                placeholder="Search..."
                onChange={(event) => updateSearchTerm(event.target.value)}
                autoFocus
            />
        </Dialog>
    )
}

export default MemoizedCardSelectField;