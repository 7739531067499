import React from 'react';

import {BoardListItem} from "./BoardListItem";

// Abstracted as recommended by (https://github.com/atlassian/react-beautiful-dnd#recommended-droppable-performance-optimisation)
// for performance optimization
export function BoardList(props) {
    return (
        props.boards.map((board, index) => (
            <BoardListItem
                key={index}
                board={board}
                index={index}
                toggleDisplay={props.toggleBoardDisplay}
                saveBoard={props.saveBoard}
                deleteBoard={props.deleteBoard}
            />
        ))
    );
}