import React, {createContext, useState, useEffect} from 'react';
import {MetaData, loadMetaData, saveMetaData} from "../Structures/MetaData";
import {app_version} from "../App"

const MetaDataContext = createContext();

const MetaDataProvider = ({children}) => {
    const [metaData, setMetaData] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);

    // Provided as prop to allow setting screen deeper in application
    const updateMetaData = (m) => {
        setMetaData(m);
        saveMetaData(m);
    };

    const updateMetaDataValue = (name, value) => {
        let updatedMetaData = Object.assign(new MetaData(), metaData); // Make immutable

        updatedMetaData[name] = value;
        updateMetaData(updatedMetaData);
    };

    const setAppVersion = (string) => {
        updateMetaDataValue('appVersion', string);
    };

    const setLastPromptedToExportAt = (string) => {
        updateMetaDataValue('lastPromptedToExportAt', string);
    };

    useEffect(() => {
        setLoading(true);

        // Load app MetaData
        loadMetaData().then((result) => {
            // Set state to loaded result with defaults (in case not existing)
            const m = Object.assign(new MetaData(), result);
            m.lastPromptedToExportAt = new Date(m.lastPromptedToExportAt); // Parse data string
            setMetaData(m);
            setLoading(false);
            setLoaded(true);
        });
    }, []);

    useEffect(() => {
        if (!loading && loaded) {
            // Update current version - will also defaults (MetaData.tsx) if not existing
            setAppVersion(app_version);
        }
    }, [loading, loaded]);

    return (
        <MetaDataContext.Provider value={{
            metaData,
            setAppVersion,
            setLastPromptedToExportAt
        }}>
            {children}
        </MetaDataContext.Provider>
    );
};

export { MetaDataProvider };
export default MetaDataContext