import React, {useState, useEffect, useContext} from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListSubheader,
    Switch
} from '@mui/material';
import Logo from '../Logo';
import {formatFileSize} from '../../Helpers/tools'

import {exportAppData} from '../../Structures/Export';
import {importAppData} from '../../Structures/Import';

import {triggerPwaInstall} from "../../Helpers/pwaInstall";

import {app_version} from "../../App";
import ConfigContext from "../../Contexts/Config.context";
import AppContext from "../../Contexts/App.context";
import InfoDialogContext from "../../Contexts/InfoDialog.context";
import ConfirmContext from "../../Contexts/Confirm.context";
import heart from '../../images/logo/noto_heart.png';

// Content
import PwaInstallContent from '../Content/PwaInstallContent';
import ChangelogContent from '../Content/ChangelogContent';
import DonateContent from '../Content/DonateContent';

export default function SettingsScreen() {
    const [storageUsage, setStorageUsage] = useState(null); // Local browser storage/quota
    const [storageQuota, setStorageQuota] = useState(null);
    // Stealth Mode Cooldown, to prevent spamming button and thus, connection requests
    const [stealthModeCooldown, setStealthModeCooldown] = useState(false);

    const {config, toggleConfigStealthMode, toggleConfigCardWear, toggleConfigSmoothOperator, toggleConfigExportPrompts} = useContext(ConfigContext);
    const {loadCards} = useContext(AppContext);
    const {infoDialog} = useContext(InfoDialogContext);
    const {confirm} = useContext(ConfirmContext);

    const toggleStealthMode = () => {
        setStealthModeCooldown(true);
    };

    // ToggleStealthMode is handled here to cleanup setTimeout
    //
    // So the way this works is:
    // - toggleStealthMode sets the cooldown ON
    // - useEffect is triggered because it listens to stealthModeCooldown
    // - Only proceed if cooldown is ON; indicating that toggle was clicked
    // - setTimeout begins for resetting stealthModeCooldown
    // - stealth mode is toggled in config
    // - useEffect triggered again because setTimeout updates stealthModeCooldown
    useEffect(
        () => {
            let stealthModeCooldownTimer;

            if (stealthModeCooldown) {

                stealthModeCooldownTimer = setTimeout(() => {
                    setStealthModeCooldown(false)
                }, 3000);

                toggleConfigStealthMode();
            }

            // Clear timeout on unmount
            return () => {
                clearTimeout(stealthModeCooldownTimer);
            };
        },
        [stealthModeCooldown]
    );

    const installHandle = () => {
        // Attempt PWA install
        if (!triggerPwaInstall()) {
            // Show dialog on failure
            infoDialog(<PwaInstallContent/>, 'Got it!');
        }
    };

    // Output storage string
    const storageCalc = () => {
        if (storageUsage && storageQuota) {
            let usage = storageUsage;
            let quota = storageQuota;

            return formatFileSize(usage) + '/' + formatFileSize(quota) + ' (%' + (usage / quota * 100).toFixed(2) + ')';
        }

        return "(Sorry, storage calculation isn't supported on this browser; try using a modern web browser)";
    };

    const handleImportAppData = (event) => {
        const file = event.target.files[0]

        confirm(() => {
            importAppData(file).then(result => {
                if (result === true) {
                    loadCards();
                }
            })
        }, 'Warning! - Importing Data will overwrite any existing data, back up your current data first by Exporting it to be safe. Are you ready to Import?', 'Import Data')

        event.target.value = null
    }

    useEffect(() => {
        // Initiate Promise to get storage info
        try {
            navigator.storage.estimate().then(estimate => {
                setStorageQuota(estimate.quota);
                setStorageUsage(estimate.usage);
            });
        } catch (error) {
            console.warn('navigator.storage.estimate is not supported by this browser', error);
        }
    }, []);

    return (
        <div className="Screen">
            <AppBar className="CardOverviewTopBar" position="static" color="secondary">
                <Toolbar variant="dense" disableGutters={false}>
                    <Typography variant="body2" color="inherit" noWrap>
                        Settings
                    </Typography>
                    <Logo/>
                </Toolbar>
            </AppBar>

            <List>
                <ListItemButton className='donate-button' onClick={() => infoDialog(<DonateContent/>, 'Thanks from noto!')}>
                    <ListItemText primary="Show Love" secondary="Noto is built entirely out of love, passion and ... admittedly quite a lot of coffee. Buy a coffee for noto!"/>
                    <img className='noto-heart' src={heart} alt='noto heart'/>
                </ListItemButton>
            </List>
            
            <List subheader={<ListSubheader disableSticky>Configuration</ListSubheader>}>
                <ListItemButton onClick={toggleStealthMode} disabled={stealthModeCooldown}>
                    <ListItemText primary="Stealth Mode" secondary={"Opt out of Presence; you won't see or contribute to Noto Presence"}/>
                    <Switch
                        color="primary"
                        checked={config.stealthMode}
                    />
                </ListItemButton>
                <ListItemButton onClick={toggleConfigCardWear}>
                    <ListItemText primary="Card Wear" secondary={"Cards gradually accumulate and display wear as they are handled"}/>
                    <Switch
                        color="primary"
                        checked={config.cardWear}
                    />
                </ListItemButton>
                <ListItemButton onClick={toggleConfigSmoothOperator}>
                    <ListItemText primary="Smooth Operator" secondary={"You're smooth, you're cool, you adopt the Noto Smooth Theme"}/>
                    <Switch
                        color="primary"
                        checked={config.smoothOperator}
                    />
                </ListItemButton>
                <ListItemButton onClick={toggleConfigExportPrompts}>
                    <ListItemText primary="Backup Prompts" secondary={"Backing up your data is a good idea, with this enabled you'll be regularly prompted to do so"}/>
                    <Switch
                        color="primary"
                        checked={config.exportPrompts}
                    />
                </ListItemButton>
            </List>

            <List subheader={<ListSubheader disableSticky>App</ListSubheader>}>
                <ListItemButton onClick={installHandle}>
                    <ListItemText
                        primary="Install to device"
                        secondary={"Install Noto to your device and use it just like an app from your home screen or desktop."}
                    />
                </ListItemButton>
                <ListItemButton onClick={() => { infoDialog(<ChangelogContent/>, 'I\'ve seen enough') }}>
                    <ListItemText primary="Version" secondary={app_version + " - Read the changelog for information about versions and changes."}/>
                </ListItemButton>
            </List>

            <List subheader={<ListSubheader disableSticky>Editor</ListSubheader>}>
                <ListItemButton component="a" href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet" target='_blank'>
                    <ListItemText primary="Markdown" secondary="Markdown can be used to format content in the editor. Click to open documentation."/>
                </ListItemButton>
                <ListItemButton component="a" href="https://github.com/showdownjs/showdown/wiki/Emojis" target='_blank'>
                    <ListItemText primary="Emojis" secondary="Emojis can be used in the editor too. Click for a list of them."/>
                </ListItemButton>
                <ListItem>
                    <ListItemText primary="Shortcuts" secondary="The following shortcuts can be entered in the editor to quickly output some preset icons ([:!!], [:!!!], [:$$], [:XX], [:@@], [:NN], [:BB])."/>
                </ListItem>
            </List>

            <List subheader={<ListSubheader disableSticky>Data Management</ListSubheader>}>
                <ListItem>
                    <ListItemText
                        primary={"Storage Usage"}
                        secondary={storageCalc() + " - Data is saved in local browser storage, this means it only exists on the specific device and specific browser you open the app with."}
                    />
                </ListItem>
                <ListItemButton onClick={exportAppData}>
                    <ListItemText primary="Export App Data" secondary="Save your App Data to a file for backups or for importing to another device or browser."/>
                </ListItemButton>
                <input
                    accept=".json"
                    id="app_data_import_file"
                    type="file"
                    hidden
                    onChange={handleImportAppData}
                />
                <label htmlFor="app_data_import_file">
                    <ListItemButton>
                        <ListItemText primary="Import App Data" secondary="WARNING: Importing App Data will overwrite current App Data and cannot be undone. To be safe, it's best to export your data first."/>
                    </ListItemButton>
                </label>
            </List>
        </div>
    );
}