import React, { useContext } from 'react'

import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import IconButton from '@mui/material/IconButton'

import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'

import {DragDropContext, Droppable} from "react-beautiful-dnd"
import {JourneyList} from "./JourneyList"
import JourneyFormContext from "../../Forms/Contexts/JourneyForm.context"
import JourneyContext from '../../../Contexts/Journey.context'
import AppContext from '../../../Contexts/App.context'

export function JourneyOverviewDrawer(props) {
    const {popJourneyFormDialog} = useContext(JourneyFormContext)    
    const {handleCreateJourney} = useContext(JourneyContext)    
    const {tags} = useContext(AppContext)    

    return (
        <Drawer className="CardCollectionMenu" open={props.open} onClose={props.toggle}>
            <DragDropContext
                onDragEnd={props.onJourneyDragEnd}
            >
                <Droppable droppableId="journeysList" isDropDisabled={false}>
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            <ListSubheader className="ListSubheader">
                                Journeys
                                <IconButton onClick={props.toggle} size="large">
                                    <CloseIcon/>
                                </IconButton>
                            </ListSubheader>
                            <List
                                className={"CollectionMenuList dragging" + (snapshot.isDraggingOver ? 'True' : 'False')}
                            >
                                <JourneyList journeys={props.journeys}/>
                                {provided.placeholder}
                                <ListItem button onClick={() => popJourneyFormDialog(() => handleCreateJourney, null, tags)}>
                                    <ListItemText primary="New Journey"/>
                                    <AddIcon/>
                                </ListItem>
                            </List>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </Drawer>
    )
}

export default JourneyOverviewDrawer