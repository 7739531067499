import React, {useContext} from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';

import EditIcon from '@mui/icons-material/Edit';
import DragIcon from '@mui/icons-material/DragHandle';
import DeleteIcon from '@mui/icons-material/DeleteRounded';

import {Draggable} from "react-beautiful-dnd";
import BoardFormContext from "../../Forms/Contexts/BoardForm.context";

export function BoardListItem(props) {
    const board = props.board;
    const {popBoardFormDialog} = useContext(BoardFormContext);

    return (
        <Draggable draggableId={board.ID + ''} index={props.index} key={'boardListItem' + board.ID}>
            {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.draggableProps}>
                    <ListItem className={"CollectionMenuListItem dragging" + (snapshot.isDragging ? 'True' : 'False')}>
                        <ListItemText primary={board.title}/>

                        {!board.preset &&
                        <React.Fragment>
                            <IconButton
                                aria-label="Delete"
                                onClick={() => {
                                    props.deleteBoard(board)
                                }}
                                size="large">
                                <DeleteIcon/>
                            </IconButton>
                            <IconButton
                                aria-label="Edit"
                                onClick={() => {
                                    popBoardFormDialog(props.saveBoard, board)
                                }}
                                size="large">
                                <EditIcon/>
                            </IconButton>
                        </React.Fragment>
                        }

                        <div {...provided.dragHandleProps}>
                            <IconButton disabled size="large">
                                <DragIcon/>
                            </IconButton>
                        </div>
                        <Switch
                            color='primary'
                            checked={board.display}
                            onChange={() => {
                                props.toggleDisplay(props.index)
                            }}
                        />
                    </ListItem>
                </div>
            )}
        </Draggable>
    );
}