import React, {useRef} from 'react';

// Material Design Components
import MDCCard from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Custom Components
import {cardValueLabel} from "./CardValue";
import {Content} from "./Cards";

function CardOrnate({card, onClick, grid, hidden}) {
    const selector = useRef(null);

    // Output with grid wrapper or without
    //
    // @param markup {JSX} The markup
    // @param grid {bool} Whether or not to wrap with a grid
    const cardOutputWrapper = (markup, grid = false) => {
        if (grid) {
            return (
                <Grid item>
                    {markup}
                </Grid>
            )
        }

        return markup
    };


    const flipped = card.private;
    const content = card.content;
    const classes = [
        'Card',
        cardValueLabel(card.merit),
        flipped ? 'private' : null,
        card.retired_at ? 'retired' : null,
        card.back ? 'cardBack_' + card.back : null,
        hidden ? 'hidden' : null
    ];
    const flipperClasses = [
        "CardFlipper",
        'CardOrnate',
        flipped ? 'private' : null,
    ];

    const handleClick = () => {
        if (!onClick) return
        onClick(card.ID)
    }

    const cardMarkup = <div className={flipperClasses.join(' ')}>
        <div className="CardFlipperInner">
            { !flipped &&
                <MDCCard
                    id={"Card_ID_" + card.ID }
                    className={classes.join(' ') + ' face'}
                    ref={selector}
                    onClick={handleClick}
                >
                    { null !== card.retired_at && <div className="retiredBadge"/>}

                    { card.merit > 0 && <div className="meritBanner">{card.merit}</div>}
                    <Content content={content}/>
                </MDCCard>
            }
            { flipped &&
                <MDCCard
                    id={"Card_ID_" + card.ID }
                    className={classes.join(' ') + ' back'}
                    ref={selector}
                    onClick={handleClick}
                />
            }
        </div>
    </div>;

    return cardOutputWrapper(
        cardMarkup,
        grid
    );
}

export default CardOrnate;