import { FilterType } from "../../Structures/Filter"

export interface Journey {
    id?: number
    display: boolean
    title: string
    filters: FilterType[]
    inclusiveFilter: boolean
}

export class JourneyClass implements Journey {
    id?: number
    display: boolean
    title: string
    filters: FilterType[]
    inclusiveFilter: boolean
    
    constructor(title = null, filters = []) {
        this.display = true;

        this.title = title;
        this.filters = filters;

        this.inclusiveFilter = true;
    }
}